import React, { useRef }  from 'react';
import { useHistory, Redirect, useLocation, matchPath } from "react-router-dom";
 


export const RouteGroup = (props) => {

    let isLogin=true;
    const location = useLocation()
    const path = "/?redirect_url="+location.pathname+"&login=true";
    let allRoutes=[];
    for(let childs of props.children){
        allRoutes.push(childs.props.path);
    }

    if(props.middlewares){
        for(let middleware of props.middlewares){
            if(!middleware() && matchPath(location.pathname,allRoutes) ){
                isLogin =  false
                break;
            }
        }
    }
   
   return <> 
        {isLogin && props.children}
        {!isLogin && < Redirect  to = {path} replace />}
      </>;
}