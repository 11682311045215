export const loadState = (type, defaultState) => {

    try {

        const serializedState = localStorage.getItem('state');

        if (serializedState === null) {

            return defaultState;

        }

        return JSON.parse(serializedState)[type];

    } catch (err) {

        return undefined;

    }

};




export const saveState = (state) => {

    try {

        const serializedState = JSON.stringify(state);

        localStorage.setItem('state', serializedState);

    } catch {

        // ignore write errors

    }

};