import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { ApiService } from '../../services/ApiService';
import { ToastContainer, toast } from 'react-toastify';
const ApprovalResendOtp = (props) => {
    const [seconds, setSeconds] = useState(30)
     useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
        }, 1000);
        return () => {
            clearInterval(interval);
        };

    }, [seconds]);

    const resendOtp = () => {
        ApiService.fetch({
            url: "post-approval-request-action",
            options: {
                body: {

                }
            }
        }).then((data) => {
            if (data.status == true) {
                setSeconds(30)
                props.setOtp(data.otp)
                props.setToken(data.token)
                toast.success(data.message, {
                    position: toast.POSITION.TOP_LEFT
                });
            }
            else {
                toast.error(data.message, {
                    position: toast.POSITION.TOP_LEFT
                });
            }
        });

    }

    return(
        <div className='text-grey-dark font-14px line-height-140'>
            <ToastContainer />
        {(seconds == 0) ? 
            <div className='ResendOTP_Link'>
                <a className='link-primary text-decoration-underline' href='#!'
                    onClick={()=>{
                        resendOtp()
                        props.setOtpError(false)
                    }}
                >
                    Resend OTP
            </a>
            </div>
              :
            <span>Resend otp in 00:{seconds > 9 ? seconds : "0" +seconds}</span>
           }
    </div>
    )
}
export default ApprovalResendOtp;