import { configureStore } from '@reduxjs/toolkit';
import { saveState } from '../services/LocalStorage';
import  rootReducer  from './rootReducer';
const store = configureStore({ reducer: rootReducer });

store.subscribe(() => {
    
    saveState({
        branchData: store.getState().branchData,
        user:store.getState().user,
    });

});
export default store;