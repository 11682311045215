import React from 'react';
const Terms = (props) => {
    let terms = props.terms
    // console.log("terms and conditions", terms)
    return(
        <>
        <div id='Terms_Conditions' className={`app-popup app-popup-header-h-105 app-popup-offcanvas-end ${props.showPopup}`}>
                    <div className='app-popup-container'>
                        <div className="app-popup-content app-popup-980px">
                            <div className="app-popup-header border-bottom py-0 d-flex align-items-center">
                                <div>
                                    <h2 className='text-primary font-22px mb-1'>Terms and Conditions</h2>
                                    <div className='font-18px-imp font-primary-bold'>{terms?.name}</div>
                                </div>
                                <button className='app-popup-close'
                                    onClick={() => {
                                        props.setShowPopup("app-popup-hide")
                                    }}
                                ><svg width="12.191" height="12.191" viewBox="0 0 12.191 12.191">
                                        <path id="Icon_material-close" data-name="Icon material-close" d="M19.691,8.728,18.464,7.5,13.6,12.368,8.728,7.5,7.5,8.728,12.368,13.6,7.5,18.464l1.228,1.228L13.6,14.824l4.868,4.868,1.228-1.228L14.824,13.6Z" transform="translate(-7.5 -7.5)" />
                                    </svg>
                                </button>
                            </div>
                            <div className="app-popup-body pb-0 font-14px app-popup-body-scroll">
                                {/* <h3 className='font-20px line-height-150 mb-1'>DAY & TIMING CONDITIONS</h3>
                                <div className='font-18px-imp font-primary-semibold line-height-150 mb-1'>Days & hours to redeem benefits</div>
                                <div className='font-18px-imp line-height-170 mb-4'>
                                    <div>Monday - 8:00 AM - 10:00 PM </div>
                                    <div>Tuesday - 8:00 AM - 10:00 PM </div>
                                    <div>Wednesday - 8:00 AM - 10:00 PM </div>
                                    <div>Thursday - 8:00 AM - 10:00 PM </div>
                                    <div>Friday - 8:00 AM - 10:00 AM </div>
                                    <div>Saturday - 8:00 AM - 10:00 AM </div>
                                    <div>Sunday - Benefits cannot be redeemed</div>
                                </div> */}

                                <div className='mb-4 terms_container'>
                                    <ul className='list-style-bullet font-18px line-height-160'
                                     dangerouslySetInnerHTML={{__html : terms?.terms_and_condition}}>
                                        {/* <li> Lorem ipsum dolor sit amet, consectetur adipiscing elit. </li>
                                        <li>Suspendisse varius urna vestibulum, condimentum metus vitae,  gravida ligula. </li>
                                        <li> Integer ut elit vehicula quam tempus vulputate. </li>
                                        <li>Praesent vel metus sed tortor pharetra pellentesque. </li> */}
                                    </ul>
                                </div>

                                {/* <div className='mb-4'>
                                    <h3 className='font-20px line-height-150 mb-1'>Dining CONDITIONS</h3>

                                    <ul className='list-style-bullet font-18px-imp line-height-160'>
                                        <li> Lorem ipsum dolor sit amet, consectetur adipiscing elit Suspendisse varius urna vestibulum, condimentum metus vitae, gravida ligula.
                                        </li>
                                        <li>Suspendisse varius urna vestibulum, condimentum metus vitae,  gravida ligula. </li>
                                        <li> Integer ut elit vehicula quam tempus vulputate. </li>
                                        <li>Praesent vel metus sed tortor pharetra pellentesque. </li>
                                    </ul>
                                </div> */}

                            </div>

                        </div>
                    </div>
                </div>
        </>
    )
}
export default Terms;