import { loadState } from "../services/LocalStorage";
import { STORE_CUURENT_BRANCH_DATA, STORE_MARKETPLACE_SETTINGS, STORE_USER_DATA } from "./constant";


const intialState = loadState("branchData", []) || [];
const intialStateSetting = {categories:[], is_mobile_view:false}
let user ={access_token:"",user:{id:"",name:"",email:""}};
const intialStateUser =  loadState("user", user) || user;;
 


export const branchData = (brancheData = intialState,action) =>{
    
    switch (action.type){
        case STORE_CUURENT_BRANCH_DATA:
            const { data } = action;
            const branches = [...brancheData];
           
            if(data.translations){
                for(let tr in data.translations){
                    data.translations[tr]=data.translations[tr].replace("Desktop app ","");
                    data.translations[tr]=data.translations[tr].charAt(0).toUpperCase() + data.translations[tr].slice(1);;

                }
            }
            else{
                data.translations={cusine:"Cusines",facilities_highlights:"Facilities & Highlights",type:"Type"}
            }

            let found = branches.findIndex(bs => bs.slug == action.data.slug);
            if (found>-1){
                branches[found] = data;
            }    
            else{
                branches.push(action.data)
            }

          
            return branches;
            break;
        default:
            return brancheData;
    }
}

export const getSettings = (state = intialStateSetting,action) =>{
    console.log('action.type cate',action.type)
    switch (action.type){
        case STORE_MARKETPLACE_SETTINGS:
            const { data } = action;
            // state.categories = data.categories;
            return {...state,categories:data.categories,settings:data.settings, is_mobile_view : data.is_mobile_view}
            break;
        default:
            return state;
    }
}

export const saveUser = (state = intialStateUser,action) =>{
    // console.log('action.type',action.type,user)
    switch (action.type){
        case STORE_USER_DATA:
            const { data } = action;

            console.log('action.type USER',action.type,data,state)
            return {
                ...state,user:data.user,access_token:data.access_token
            }
            break;
        default:
            return state;
    }
}

// const intialStateRestroList = loadState("restroList", []);

// export const restroList = (restroList = intialStateRestroList, action) => {

//     switch (action.type) {
//         case STORE_RESTRO_LIST:
//             const { data } = action;
//             restroList = data
//             return restroList;
//             break;
//         default:
//             return restroList;
//     }
// }



 