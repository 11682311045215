import React, { useEffect } from 'react';
import Webheader from '../../library/WebHeader';
import Tabs from './Tabs';
import { Footer } from '../../Components/Footer';
import { useState } from 'react';
import ApprovalReqPopup from './ApprovalReqPopup';
import ApprovalFilter from './ApprovalFilter';
import { ApiService } from '../../services/ApiService';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Helper from '../../utils/helper';
import Loader from '../../library/loader';
import Search from '../../library/Search';
const ApprovalRequest = () => {
    const history = useHistory()
    const search = useLocation();
    const [requestStatus, setRequestStatus] = useState("pending")
    const queryParams = new URLSearchParams(window.location.search)
    const from_date = queryParams.get("from_date")
    const to_date = queryParams.get("to_date")
    const touchpoint = queryParams.get("touchpoint")
    const submitby = queryParams.get("submitby")

    const [appliedFilters, setAppliedFilters] = useState({});

    const [showPopup, setShowPopup] = useState("app-popup-hide")
    const [screen, setScreen] = useState("")

    const [requestList, setRequestList] = useState(false)
    const [selectedItem, setSelectedItem] = useState("")

    const [searchKey, setSearchKey] = useState("")

    const params = useParams()

    const [headerCount, setHeadercount] = useState({
        pending: 0,
        approved: 0,
        rejected: 0,
        hold: 0
    })

    const applyFilters = (filters) => {
        let query = ""
        let path = "/approvalrequest/" +(!params?.status ? 'pending' : params?.status)
        for (let filter of filters) {
            query += query == '' ? "" : "&"
            query += filter.search
        }
        history.push(path + "?" + query)
        // window.location.reload(false)
    }
    const removeFilter = (item, index) => {
        let filters = [...appliedFilters];
        filters.splice(index, 1)
        applyFilters(filters)
    }

    const [touchPointList, setTouchPointList] = useState("")
    useEffect(() => {
        ApiService.fetch({
            url: "get-my-touchpoint-and-submit-by-list",
            options: { method: "GET" }
        }).then((data) => {
            if (data.status == true) {
                setTouchPointList(data.data)
                console.log("get touch points", data)
            }
        });
    }, [])

    useEffect(() => {
        ApiService.fetch({
            url: "my-approval-request",
            options: {
                body: {
                    status: params.status || requestStatus,
                    from: from_date ? from_date : "",
                    to: to_date ? to_date : "",
                    touchpoint_id: touchpoint,
                    staff_id: submitby,
                    search: searchKey
                }
            }
        }).then((data) => {
            if (data.status == true) {
                setRequestList(data)
                setHeadercount({
                    pending: data.status_counts.pending ? data.status_counts.pending : "0",
                    approved: data.status_counts.approved ? data.status_counts.approved : "0",
                    rejected: data.status_counts.rejected ? data.status_counts.rejected : "0",
                    hold: data.status_counts.hold ? data.status_counts.hold : "0"
                })

                let query = search.search.replace("?", "")
                const queryParams = new URLSearchParams(window.location.search)
                let params = [];
                let amountParam = { key: "amount", value: "", label: "Amount ", search: "" };
                for (let param of queryParams) {
                    if (param[0] == "auth_token") {
                        continue;
                    }
                    console.log(param, 'param');
                    // if (param[0] == "from_amount" || param[0] == "to_amount") {
                    //     amountParam.value += amountParam.value == '' ? param[1] : " - " + param[1];
                    //     amountParam.search += amountParam.search == '' ? param[0] + "=" + param[1] : "&" + param[0] + "=" + param[1];
                    // }
                    // else
                    params.push({ key: param[0], value: param[1], label: param[0], search: param[0] + "=" + param[1] })
                }
                if (amountParam.value)
                    params.push(amountParam)
                setAppliedFilters(params)
            }
        });
        if (params.status) {
            setRequestStatus(params.status)
        }
    }, [params.status, from_date, to_date, touchpoint, submitby, searchKey])

    const searchKeys = {
        submitby: "Submit By"

    }
    const titleCase = (item, value) => {

        let newData
        if (searchKeys[item])
            newData = searchKeys[item];

        else
            newData = (item.charAt(0).toUpperCase() + item.slice(1)).replace("_d", " D")

        let values = value
        if (item == 'touchpoint') {
            touchPointList?.touchPoints?.forEach((touchpoint) => {
                if (touchpoint.id == value) {
                    values = touchpoint?.title;
                }

            });
        }
        else if (item == 'submitby') {
            touchPointList?.submitBy?.forEach((user) => {
                if (user.id == value) {
                    values = user?.name;
                }

            });
        }


        return newData + " : " + values
    }

    function handleClick(el) {
        var elems = document.querySelector(".dropdown .active");
        if (elems !== null) {
            elems?.classList.remove("active");
        }
        el?.classList.toggle('active');

        document.addEventListener('click', event => {
            //  const todoInputContainer = document.querySelector('.dropdown_Btn');
            if (event.target != elems) {
                elems?.classList.remove('active');
            }
        });
    }

    document.addEventListener('click', event => {
        const todoInputContainer = document.querySelector('.dropdown .active');
        if (event.target != todoInputContainer) {
            todoInputContainer?.classList.remove('active');
        }
    });

    if (!requestList) {
        return <div className="page-wrapper page-wrapper-footer1 page-wrapper-headerSearch overflow-hidden">
            <Webheader />
            <Tabs active={"approvalrequest"} />
            <div className='container pt-1 pt-xl-3 pb-4 mt-3 mb-xl-5'>
                <div className="d-flex align-items-center justify-content-between mb-3 mb-md-3">
                    <div className='myinvesthead'>
                        <div className="row">
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'>
                                <h2 className="font-30px mb-md-2">Approval Requests</h2>
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 bg-white FiltersButton-wrap FilterFixBar-fixed-not-no'>
                                <div className='filterrights'>
                                    <Search
                                        debounceTime={500}
                                        callback={setSearchKey}
                                        placeholder='Search by Ref. No. / Subject'
                                        className="searchinputfilter"
                                    />

                                    <button className="flex-0-0-auto FiltersButton position-static bg-transparent border rounded-5px font-primary-semibold">
                                        <svg className="me-md-2" xmlns="http://www.w3.org/2000/svg" width="23.188" height="14.489" viewBox="0 0 23.188 14.489">
                                            <g id="setting-lines" transform="translate(0 -4.503)">
                                                <path id="Path_1423" data-name="Path 1423" d="M4.955,53.951H.682a.682.682,0,0,1,0-1.364H4.955a.682.682,0,0,1,0,1.364Z" transform="translate(0 -45.642)" fill="#393939"></path>
                                                <path id="Path_1424" data-name="Path 1424" d="M87.26,10.751a3.124,3.124,0,1,1,3.124-3.124A3.127,3.127,0,0,1,87.26,10.751Zm0-4.884a1.76,1.76,0,1,0,1.76,1.76A1.762,1.762,0,0,0,87.26,5.867Z" transform="translate(-79.863)" fill="#393939"></path>
                                                <path id="Path_1425" data-name="Path 1425" d="M239.88,53.951H229.687a.682.682,0,0,1,0-1.364H239.88a.682.682,0,0,1,0,1.364Z" transform="translate(-217.374 -45.642)" fill="#393939"></path>
                                                <path id="Path_1426" data-name="Path 1426" d="M267.553,173.021a3.124,3.124,0,1,1,3.124-3.124A3.127,3.127,0,0,1,267.553,173.021Zm0-4.883a1.76,1.76,0,1,0,1.76,1.76A1.762,1.762,0,0,0,267.553,168.138Z" transform="translate(-250.999 -154.03)" fill="#393939"></path>
                                                <path id="Path_1427" data-name="Path 1427" d="M11.638,216.214H.682a.682.682,0,1,1,0-1.364H11.638a.682.682,0,1,1,0,1.364Z" transform="translate(0 -199.664)" fill="#393939"></path><path id="Path_1428" data-name="Path 1428" d="M364.779,216.214h-3.51a.682.682,0,1,1,0-1.364h3.51a.682.682,0,0,1,0,1.364Z" transform="translate(-342.274 -199.664)" fill="#393939">
                                                </path>
                                            </g>
                                        </svg><span className="Filters_Text"> Filters</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Loader />
            </div>
            <div className='Footer_border'><Footer /></div>
        </div>
    }

    return (
        <>
            <div className="page-wrapper page-wrapper-footer1 page-wrapper-headerSearch overflow-hidden">
                <Webheader />
                <div className='MobileSearch hide-element'>
                    {/* Search section start */}
                    <div className='topbar-1 topbar-n1 border bg-white d-flex mx-auto flex-grow-1 ms-xl-4'>
                        <div className='flex-0-0-auto ps-2 d-flex align-items-center rounded-0 pe-2 area-filter area-filter-n1 border-end'>
                            <div className=''> <svg className='position-relative top--2 ms-2' width="13.451" height="15.904" viewBox="0 0 12.451 15.904"><use xlinkHref="#map-icon1"></use></svg></div>
                            <div className='flex-grow-1'>

                                <select className="form-select h-52px border-0">
                                    <option defaultValue={"Nairobi"}>Nairobi</option>
                                    <option value="1">Nairobi</option>
                                    <option value="2">Nairobi</option>
                                    <option value="3">Nairobi</option>
                                </select>
                            </div>
                        </div>
                        <div className="input-group input-group-search1">
                            <input
                                type="text"
                                placeholder='Search business by name or type'
                                className="form-control rounded-0 form-control-lg border-0 font-16px h-54px"
                            />
                            <button className="input-group-text bg-transparent border-0"><svg width="17.022" height="17.022" viewBox="0 0 17.022 17.022"><use xlinkHref="#search-icon"></use></svg></button>
                        </div>
                    </div>
                    {/* Search section end */}
                </div>

                {/* Heading tabs start  */}
                <Tabs active={"approvalrequest"} />
                {/* Heading tabs end  */}

                <div className="container mb-2 mb-md-5">
                    <div className="d-flex align-items-center justify-content-between mb-3 mb-md-3">
                        <div>

                        </div>
                    </div>
                    <div className='myinvesthead'>
                        <div className="row">
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'>
                                <h2 className="font-30px mb-md-2">Approval Requests </h2>
                                {appliedFilters?.length > 0 &&
                                    <div className="d-flex align-items-center flex-wrap">
                                        <span className="font-16px font-primary-semibold me-1 me-md-2 mb-2">
                                            Applied Filter(s)
                                        </span>
                                        <div className="d-flex align-items-center">
                                            <div className="AppliedFilter_col">
                                                {appliedFilters?.map((item, index) => {
                                                    return <span className="badge_Filter me-1 me-md-2 mb-1 mb-md-2" key={item?.id}>
                                                        {/* {item?.label} {item?.value} */}
                                                        {titleCase(item.label, item.value)}
                                                        <a className="AppliedFilter_close" onClick={() => {
                                                            setRequestList(false)
                                                            removeFilter(item, index)
                                                        }}>
                                                            <svg
                                                                className="ms-2"
                                                                width="10.352"
                                                                height="10.352"
                                                                viewBox="0 0 10.352 10.352"
                                                            >
                                                                <path
                                                                    id="Icon_material-close"
                                                                    data-name="Icon material-close"
                                                                    d="M17.852,8.543,16.809,7.5l-4.133,4.133L8.543,7.5,7.5,8.543l4.133,4.133L7.5,16.809l1.043,1.043,4.133-4.133,4.133,4.133,1.043-1.043-4.133-4.133Z"
                                                                    transform="translate(-7.5 -7.5)"
                                                                />
                                                            </svg>
                                                        </a>
                                                    </span>
                                                })}

                                                {appliedFilters?.length > 1 &&
                                                    <span className='flex-0-0-auto'>
                                                        <a className='font-14px font-primary-semibold link-default'
                                                            onClick={() => {
                                                                setRequestList(false)
                                                                history.push("/approvalrequest")
                                                            }}>
                                                            Clear All
                                                            <svg className='position-relative top--1' width="11.2" height="11.2" viewBox="0 0 10.863 10.863">
                                                                <path id="Icon_ionic-ios-close-circle" data-name="Icon ionic-ios-close-circle" d="M8.807,3.375a5.432,5.432,0,1,0,5.432,5.432A5.431,5.431,0,0,0,8.807,3.375Zm1.376,7.4L8.807,9.4,7.43,10.773a.417.417,0,1,1-.59-.59L8.216,8.807,6.84,7.43a.417.417,0,0,1,.59-.59L8.807,8.216,10.183,6.84a.417.417,0,1,1,.59.59L9.4,8.807l1.376,1.376a.419.419,0,0,1,0,.59A.415.415,0,0,1,10.183,10.773Z" transform="translate(-3.375 -3.375)" />
                                                            </svg>
                                                        </a></span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 bg-white FiltersButton-wrap FilterFixBar-fixed-not-no'>
                                <div className='filterrights'>
                                    <Search
                                        debounceTime={500}
                                        callback={setSearchKey}
                                        placeholder='Search by Ref. No. / Subject'
                                        className="searchinputfilter"
                                    />

                                    <button className="flex-0-0-auto FiltersButton position-static bg-transparent border rounded-5px font-primary-semibold"
                                        onClick={() => {
                                            setShowPopup("")
                                            setScreen("filterpopup")
                                        }}>
                                        <svg className="me-md-2" xmlns="http://www.w3.org/2000/svg" width="23.188" height="14.489" viewBox="0 0 23.188 14.489">
                                            <g id="setting-lines" transform="translate(0 -4.503)">
                                                <path id="Path_1423" data-name="Path 1423" d="M4.955,53.951H.682a.682.682,0,0,1,0-1.364H4.955a.682.682,0,0,1,0,1.364Z" transform="translate(0 -45.642)" fill="#393939"></path>
                                                <path id="Path_1424" data-name="Path 1424" d="M87.26,10.751a3.124,3.124,0,1,1,3.124-3.124A3.127,3.127,0,0,1,87.26,10.751Zm0-4.884a1.76,1.76,0,1,0,1.76,1.76A1.762,1.762,0,0,0,87.26,5.867Z" transform="translate(-79.863)" fill="#393939"></path>
                                                <path id="Path_1425" data-name="Path 1425" d="M239.88,53.951H229.687a.682.682,0,0,1,0-1.364H239.88a.682.682,0,0,1,0,1.364Z" transform="translate(-217.374 -45.642)" fill="#393939"></path>
                                                <path id="Path_1426" data-name="Path 1426" d="M267.553,173.021a3.124,3.124,0,1,1,3.124-3.124A3.127,3.127,0,0,1,267.553,173.021Zm0-4.883a1.76,1.76,0,1,0,1.76,1.76A1.762,1.762,0,0,0,267.553,168.138Z" transform="translate(-250.999 -154.03)" fill="#393939"></path>
                                                <path id="Path_1427" data-name="Path 1427" d="M11.638,216.214H.682a.682.682,0,1,1,0-1.364H11.638a.682.682,0,1,1,0,1.364Z" transform="translate(0 -199.664)" fill="#393939"></path><path id="Path_1428" data-name="Path 1428" d="M364.779,216.214h-3.51a.682.682,0,1,1,0-1.364h3.51a.682.682,0,0,1,0,1.364Z" transform="translate(-342.274 -199.664)" fill="#393939">
                                                </path>
                                            </g>
                                        </svg><span className="Filters_Text"> Filters</span>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='approval_list mt-4'>
                        <ul>
                            <li onClick={() => {
                                setRequestList(false)
                                setSearchKey("")
                                history.push("/approvalrequest/pending")
                            }}>
                                <a className={`${(params.status == "pending" || requestStatus == "pending") && "active1"}`}>Pending - {headerCount.pending}</a>
                            </li>
                            <li onClick={() => {
                                setRequestList(false)
                                setSearchKey("")
                                history.push("/approvalrequest/approved")
                            }}>
                                <a className={`${params.status == "approved" && "active2"}`}>Approved - {headerCount.approved}</a>
                            </li>
                            <li onClick={() => {
                                setRequestList(false)
                                setSearchKey("")
                                history.push("/approvalrequest/rejected")
                            }}>
                                <a className={`${params.status == "rejected" && "active3"}`}>Rejected - {headerCount.rejected}</a>
                            </li>
                            <li onClick={() => {
                                setRequestList(false)
                                setSearchKey("")
                                history.push("/approvalrequest/hold")
                            }}>
                                <a className={`${params.status == "hold" && "active4"}`}>Hold - {headerCount.hold}</a>
                            </li>
                        </ul>
                    </div>

                    {requestList?.data?.length ?
                        <div className='salestablerow'>
                            <div className='tablesaleinfo'>
                                <div className="table-responsive border rounded-3 table-app1 webtable">
                                    <table className="table table-bordered table-bordered-left-less rounded-3 mb-0 data_scroll_table1">
                                        <thead>
                                            <tr>
                                                <th scope="col">Date</th>
                                                <th scope="col">Reference No.</th>
                                                <th scope="col">Subject</th>
                                                <th scope="col">Touch Point</th>
                                                <th scope="col">Submitted By</th>
                                                <th scope="col">Approval Status</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {requestList?.data?.map((item) => {
                                                return <tr key={item?.id}>
                                                    <td>{Helper.dateFormat(item?.created_at).slice(0, -8)}</td>
                                                    <td>{item?.reference_number}</td>
                                                    <td>{item?.subject} <br />
                                                        {/* Reimbursement */}
                                                    </td>
                                                    <td>{item?.get_touchpoint_detail?.title}</td>
                                                    <td>
                                                        <div className='ticketinfo'>
                                                            <img src={item?.get_staff_detail?.profile} className='userimg' />
                                                            <span className="usertxt">{item?.get_staff_detail?.name}</span>
                                                        </div>
                                                    </td>
                                                    <td className={`${(params.status == "pending" || requestStatus == "pending") ? "pendingColor"
                                                        : (params.status == "approved") ? "approveColor"
                                                            : (params.status == "rejected") ? "rejectColor"
                                                                : (params.status == "hold") ? "holdColor"
                                                                    : ""}`}>
                                                        {(item?.request_status?.charAt(0).toUpperCase() + item?.request_status?.slice(1))}
                                                    </td>
                                                    <td onClick={({ target }) => handleClick(target)}>
                                                        <div className="dropdown">
                                                            <img className='bullet_horz_icon' src={require("../../assets/img/doticon.svg").default} />
                                                            <a className='bg-transparent border-0 dropdown_Btn'>
                                                            </a>
                                                            <ul className='dropdown-menu shadow-sm end-0 font-14px z-index0 py-2'>
                                                                <li>
                                                                    <a className='dropdown-item d-flex align-items-center' onClick={() => {
                                                                        setScreen("approvalrequest")
                                                                        setShowPopup("")
                                                                        setSelectedItem(item)
                                                                        // history.push("/approvalrequest/approvalstatus")
                                                                    }}>
                                                                        <span className='dropdown-menu-icon'>
                                                                            <svg id="view" xmlns="http://www.w3.org/2000/svg" width="18.068" height="13.25" viewBox="0 0 18.068 13.25">
                                                                                <path id="Path_1741" data-name="Path 1741" d="M19.158,12.045a10.052,10.052,0,0,0-9-6.42,10.052,10.052,0,0,0-9,6.42.6.6,0,0,0,0,.41,10.052,10.052,0,0,0,9,6.42,10.052,10.052,0,0,0,9-6.42.6.6,0,0,0,0-.41Zm-9,5.625a8.943,8.943,0,0,1-7.787-5.42A8.943,8.943,0,0,1,10.16,6.83a8.943,8.943,0,0,1,7.787,5.42A8.943,8.943,0,0,1,10.16,17.67Z" transform="translate(-1.126 -5.625)" fill="rgba(0,0,0,0.8)" />
                                                                                <path id="Path_1742" data-name="Path 1742" d="M14.864,11.25a3.614,3.614,0,1,0,3.614,3.614A3.614,3.614,0,0,0,14.864,11.25Zm0,6.023a2.409,2.409,0,1,1,2.409-2.409A2.409,2.409,0,0,1,14.864,17.273Z" transform="translate(-5.83 -8.239)" fill="rgba(0,0,0,0.8)" />
                                                                            </svg></span>   View Request
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            <div className='clearfix'></div>
                            <div className='infoshow ms-3'>
                                No data found
                            </div>
                        </div>
                    }
                </div>

                <div className='clearfix'></div>
                <div className='Footer_border'><Footer /></div>
            </div>

            {/* Request Popup start */}
            {screen == "approvalrequest" &&
                <ApprovalReqPopup showPopup={showPopup} setShowPopup={setShowPopup} selectedItem={selectedItem} requestStatus={requestStatus} />
            }

            {screen == "filterpopup" &&
                <ApprovalFilter
                    showPopup={showPopup}
                    setShowPopup={setShowPopup}
                    setRequestList={setRequestList}
                    url={window.location.search}
                    touchPointList={touchPointList}
                />
            }

        </>
    )
}
export default ApprovalRequest;