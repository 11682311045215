import React, { useEffect, useState } from 'react';
import { ApiService } from '../../services/ApiService';
import { Loader } from '../../Components/SvgIcons';
const MemberShipProduct = (props) => {
    // console.log("products",props)
    const [products, setProducts] = useState(false)
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(10)
    const [categoryId, setCategoryId] = useState("")
    const pages = []
    for (let i = 1; i <= products?.data?.last_page; i++) {
        pages.push(i)
    }
    useEffect(() => {
        ApiService.fetch({
            url: "get-membership-item-list",
            options: {
                body:
                {
                    per_page: perPage,
                    membership_id: props.id,
                    type: "Product",
                    page: page,
                    category_id: categoryId
                }
            }
        }).then((data) => {
            if (data.status == true) {
                setProducts(data)
                if(!categoryId)
                setCategoryId(data?.cateRows[0]?.id)
            }
        }).catch((err) => {

        })
    }, [page, perPage, categoryId])
    return (
        <>
            <div id='Platinum_Membership' className={`app-popup app-popup-header-h-105 app-popup-offcanvas-end ${props.showPopup}`}>
                <div className='app-popup-container'>
                    <div className="app-popup-content app-popup-980px">
                        <div className="app-popup-header border-bottom py-0 d-flex align-items-center">
                            <div>
                                <h2 className='text-primary font-22px mb-1'>{props?.membership?.name}</h2>
                                <div className='font-18px-imp font-primary-bold'>Exclusive Discounts on a Wide Range of Products</div>
                            </div>
                            <button className='app-popup-close'
                                onClick={() => {
                                    props.setShowPopup("app-popup-hide")
                                }}
                            ><svg width="12.191" height="12.191" viewBox="0 0 12.191 12.191">
                                    <path id="Icon_material-close" data-name="Icon material-close" d="M19.691,8.728,18.464,7.5,13.6,12.368,8.728,7.5,7.5,8.728,12.368,13.6,7.5,18.464l1.228,1.228L13.6,14.824l4.868,4.868,1.228-1.228L14.824,13.6Z" transform="translate(-7.5 -7.5)" />
                                </svg>
                            </button>
                        </div>
                        <div className="app-popup-body pb-0 font-14px app-popup-body-scroll">
                            {!products ? <Loader /> :
                                <div className="">
                                    <div className="tabs-wrapper mb-4 tabs-wrapper-membership d-flex flex-column flex-md-row align-items-md-start">
                                        {/* sidebar Start */}
                                        <div className="tabs-left rounded-3 border border-light-grey h-auto font-16px pt-3 pb-2 tabs-vertical-design2">

                                            <div className='border-bottom px-3 pb-3 font-primary-bold mb-2'>Product Category</div>

                                            <ul>
                                            {products?.cateRows?.map((item) => {
                                                   return <li className={`${(item?.id == categoryId) && 'ui-tabs-active'}`}>
                                                        <a
                                                        onClick={()=>{
                                                           setCategoryId(item?.id)
                                                        }}>{item?.name}</a>
                                                    </li>
                                                })}
                                            </ul>
                                        </div>
                                        {/* sidebar End */}



                                        <div className="tabs-right flex-grow-1 ps-md-4 pe-0 mt-3 mt-md-0">

                                            {/* sidebar tab content start */}
                                            <div className="tabsContent_Div" />
                                            <div className="table-responsive border rounded-3 table-app1 prdouct-table-view">
                                                <table className="table table-bordered rounded-3 mb-0 data_scroll_table1 platinum-tabless">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Product</th>
                                                            <th scope="col">Price (KES)</th>
                                                            <th scope="col">Discount</th>
                                                            <th scope="col">Effective Price (KES)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {products?.data?.data?.map((item) => {
                                                            return <tr key={item?.id}>
                                                                <td>{item?.get_item_detail?.title}</td>
                                                                <td>{item?.get_item_detail?.price}</td>
                                                                <td>{item?.item_discount_percentage ? item?.item_discount_percentage : "0"}%</td>
                                                                <td>{(item?.get_item_detail?.price * (100 - item?.item_discount_percentage)) / 100}</td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>

                                            {/* Pagination Start */}
                                            <div className="d-flex flex-wrap align-items-center justify-content-end mb-5 mt-3 Pagination_container">
                                                <div className="mb-3 mb-sm-0 me-sm-2 me-md-4 paginationmobile">
                                                    <div className="d-flex selectpages">
                                                        <div className="h-36px">
                                                            <select className="select2 h-100 form-select line-height-100 form-select-arrow-sm shadow-none form-control form-control-sm"
                                                                onChange={(e) => {
                                                                    setPerPage(e.target.value)
                                                                }}>
                                                                {[10, 20, 30].map((item, index) => {
                                                                    return <option key={index} value={item}> {item} record per page </option>
                                                                })}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <a
                                                    // href="javascript:void(0)"
                                                    className="link-default font-14px font-primary-bold text-decoration-underline mb-3 mb-sm-0"
                                                    onClick={() => {
                                                        if (page > 1) {
                                                            setPage(Number(page) - 1)
                                                        }
                                                    }}
                                                >
                                                    PREVIOUS
                                                </a>
                                                <div className="pager-entries mb-3 mb-sm-0 ms-4">
                                                    <span className="ms-1 me-2">Page</span>
                                                    {/* <div className="d-flex">
                                                    <div className="h-36px">
                                                        <select className="select2 h-100 form-select line-height-100 form-select-arrow-sm shadow-none form-control form-control-sm"
                                                            onChange={(e) => {
                                                                setPage(e.target.value)
                                                            }}>
                                                            {pages?.map((item) => {
                                                                return <option>{item}</option>
                                                            })}
                                                        </select>
                                                    </div>
                                                </div> */}
                                                    <span className="ms-2">{page} of {products?.data?.last_page}</span>
                                                </div>
                                                <a
                                                    // href="javascript:void(0)"
                                                    className="link-default font-14px font-primary-bold text-decoration-underline mb-3 mb-sm-0"
                                                    onClick={() => {
                                                        if (page < products?.data?.last_page) {
                                                            setPage(Number(page) + 1)
                                                        }
                                                    }}
                                                >
                                                    NEXT
                                                </a>
                                            </div>
                                            {/* Pagination End */}

                                        </div>
                                        {/* sidebar tab content End */}

                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default MemberShipProduct;