import React from 'react';
import { useSelector } from 'react-redux';
import { ApiService } from '../../services/ApiService';
const Services = (props) => {
    const branchData = props.data
    const show = useSelector(state => state.showHeader);
    console.log("showHeadervalue", show)

    return (
        <div className='product-iframe'>
            {/* <iframe src={"http://localhost:3001/mealinity/" + 'salon?'+'token=' + branchData.get_booking_touchpoint.qr_code_token +'&show_header_footer=1'} width="100%" height="800"></iframe> */}
            <iframe src={ApiService.config.url + 'salon?'+'token=' + branchData.get_booking_touchpoint.qr_code_token +'&show_header_footer=1'} width="100%" height="800"></iframe>
        </div>
    )
}
export default Services;