import React, { useEffect } from 'react';
import { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Footer } from '../../Components/Footer';
import Webheader from '../../library/WebHeader';
import Tabs from './Tabs';
import { ApiService } from '../../services/ApiService';
import Helper from '../../utils/helper';
import MyDeliveryStatus from './MyDeliveryStatus';
import OrderDetail from './OrderDetail';
import Loader from '../../library/loader';
import { useSelector } from 'react-redux';
import Pagination from '../../library/Pagination';
import { toast } from 'react-toastify';
const MyDelivery = () => {
    const history = useHistory()
    const [showPopup, setShowPopup] = useState("app-popup-hide")
    const [screen, setScreen] = useState("")
    const [delivery, setDelivery] = useState(false)
    const [customer, setCustomer] = useState("")
    const [selectedItem, setSelectedItem] = useState("")
    const [page, setPage] = useState(1)
    const user = useSelector(state => state.user.user)

    const params = useParams()

    // var str = window.location.pathname
    // var output = str.split('/');
    // var lastElement = output[output.length - 1];
    // console.log("redirect url", lastElement)

    useEffect(() => {
        setDelivery(false)
        ApiService.fetch({
            url: "my-deliveries",
            options: {
                body: {
                    page: page
                }
            }
        }).then((data) => {
            if (data.status == true) {
                setDelivery(data.data)
                if (params?.slug) {
                    setShowPopup("")
                }
            }
            else{
                toast.error(data.message)
            }
        });

    }, [page])

    // dropdown close start   
    function handleClick(el) {
        var elems = document.querySelector(".dropdown .active");
        if (elems !== null) {
            elems?.classList.remove("active");
        }
        el?.classList.toggle('active');

        document.addEventListener('click', event => {
            //  const todoInputContainer = document.querySelector('.dropdown_Btn');
            if (event.target != elems) {
                elems?.classList.remove('active');
            }
        });
    }

    document.addEventListener('click', event => {
        const todoInputContainer = document.querySelector('.dropdown .active');
        if (event.target != todoInputContainer) {
            todoInputContainer?.classList.remove('active');
        }
    });
    // dropdown close end   

    // if (!token) {
    //     history.push("/?redirect_url=" + lastElement + "&login=true")
    // }

    if (!delivery) {
        return <div className="page-wrapper page-wrapper-footer1 page-wrapper-headerSearch overflow-hidden">
            <Webheader />

            <Tabs active={"delivery"} />
            <div className='container pt-1 pt-xl-3 pb-4 mt-3 mb-xl-5'>
                <div className="d-flex align-items-center justify-content-between mb-3 mb-md-3">
                    <div>
                        <h2 className="font-30px mb-md-2">My Deliveries </h2>
                    </div>
                </div>
                <Loader />
            </div>
            {showPopup == '' &&
                <MyDeliveryStatus showPopup={showPopup} setShowPopup={setShowPopup} selectedItem={selectedItem} slug={params?.slug} />
            }
            <div className='Footer_border'><Footer /></div>
        </div>
    }

    return (
        <>
            <div className="page-wrapper page-wrapper-footer1 page-wrapper-headerSearch overflow-hidden">
                <Webheader />

                <Tabs active={"delivery"} />

                <div className='container pt-1 pt-xl-3 pb-4 mt-3 mb-xl-5'>
                    <div className="d-flex align-items-center justify-content-between mb-3 mb-md-3">
                        <div>
                            <h2 className="font-30px mb-md-2">My Deliveries </h2>
                        </div>
                    </div>

                    <div className='deliverysection'>
                        <div className='deliverytable'>
                            {delivery?.data?.length > 0 ?
                                <div className="table-responsive border rounded-3 table-app1 webtable">
                                    <table className="table table-bordered table-bordered-left-less rounded-3 mb-0 data_scroll_table1">
                                        <thead>
                                            <tr>
                                                <th scope="col">Delivery Type</th>
                                                <th scope="col">Item QTY</th>
                                                <th scope="col">Delivery Booking <br /> Date & Time</th>
                                                <th scope="col">Sender</th>
                                                <th scope="col">Receiver</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {delivery?.data?.map((item, index) => {
                                                return <tr key={index}>
                                                    <td>{item?.delivery_type}</td>
                                                    <td>{item?.get_delivery_menu_items?.length}</td>
                                                    <td>{Helper.dateFormat(item?.created_at)}</td>
                                                    <td>
                                                        {item?.get_from_customer_detail?.id != user?.id ?
                                                            <a className="linkstab" onClick={() => {
                                                                setScreen("customerdetail")
                                                                setShowPopup("")
                                                                setCustomer(item?.get_from_customer_detail)
                                                            }}>{item?.get_from_customer_detail?.name}</a>
                                                            :
                                                            <div>{item?.get_from_customer_detail?.name}</div>}
                                                    </td>


                                                    <td>
                                                        {item?.get_to_customer_detail?.id != user?.id ?
                                                            <a className="linkstab" onClick={() => {
                                                                if (item?.get_to_customer_detail?.id != user?.id) {
                                                                    setScreen("customerdetail")
                                                                    setShowPopup("")
                                                                    setCustomer(item?.get_to_customer_detail)
                                                                }
                                                            }}>{item?.get_to_customer_detail?.name}</a>
                                                            :
                                                            <div>{item?.get_to_customer_detail?.name}</div>}
                                                    </td>
                                                    <td>{item?.get_delivery_status_detail?.type}</td>
                                                    <td onClick={({ target }) => handleClick(target)}>

                                                        <div className="dropdown">
                                                            <img className='bullet_horz_icon' src={require("../../assets/img/doticon.svg").default} />
                                                            <a className='bg-transparent border-0 dropdown_Btn'>
                                                            </a>

                                                            <ul className="dropdown-menu shadow-sm end-0 font-14px z-index0 py-2">
                                                                <li onClick={() => {
                                                                    // setScreen("deliverystatus")
                                                                    // setShowPopup("")
                                                                    // setSelectedItem(item)
                                                                    history.push("/mydelivery/detail/" + item?.slug)
                                                                }}>
                                                                    <a className="dropdown-item d-flex align-items-center" href="#">
                                                                        <span className='dropdown-menu-icon'>
                                                                            <svg id="view" xmlns="http://www.w3.org/2000/svg" width="15" height="13.25" viewBox="0 0 18.068 13.25">
                                                                                <path id="Path_1741" data-name="Path 1741" d="M19.158,12.045a10.052,10.052,0,0,0-9-6.42,10.052,10.052,0,0,0-9,6.42.6.6,0,0,0,0,.41,10.052,10.052,0,0,0,9,6.42,10.052,10.052,0,0,0,9-6.42.6.6,0,0,0,0-.41Zm-9,5.625a8.943,8.943,0,0,1-7.787-5.42A8.943,8.943,0,0,1,10.16,6.83a8.943,8.943,0,0,1,7.787,5.42A8.943,8.943,0,0,1,10.16,17.67Z" transform="translate(-1.126 -5.625)" fill="rgba(0,0,0,0.8)" />
                                                                                <path id="Path_1742" data-name="Path 1742" d="M14.864,11.25a3.614,3.614,0,1,0,3.614,3.614A3.614,3.614,0,0,0,14.864,11.25Zm0,6.023a2.409,2.409,0,1,1,2.409-2.409A2.409,2.409,0,0,1,14.864,17.273Z" transform="translate(-5.83 -8.239)" fill="rgba(0,0,0,0.8)" />
                                                                            </svg></span> View Status
                                                                    </a>
                                                                </li>
                                                                <li onClick={() => {
                                                                    setScreen("orderdetail")
                                                                    setShowPopup("")
                                                                    setSelectedItem(item)
                                                                }}>
                                                                    <a className="dropdown-item d-flex align-items-center" href="#">
                                                                        <span className='dropdown-menu-icon'>
                                                                            <svg id="view" xmlns="http://www.w3.org/2000/svg" width="15" height="13.25" viewBox="0 0 18.068 13.25">
                                                                                <path id="Path_1741" data-name="Path 1741" d="M19.158,12.045a10.052,10.052,0,0,0-9-6.42,10.052,10.052,0,0,0-9,6.42.6.6,0,0,0,0,.41,10.052,10.052,0,0,0,9,6.42,10.052,10.052,0,0,0,9-6.42.6.6,0,0,0,0-.41Zm-9,5.625a8.943,8.943,0,0,1-7.787-5.42A8.943,8.943,0,0,1,10.16,6.83a8.943,8.943,0,0,1,7.787,5.42A8.943,8.943,0,0,1,10.16,17.67Z" transform="translate(-1.126 -5.625)" fill="rgba(0,0,0,0.8)" />
                                                                                <path id="Path_1742" data-name="Path 1742" d="M14.864,11.25a3.614,3.614,0,1,0,3.614,3.614A3.614,3.614,0,0,0,14.864,11.25Zm0,6.023a2.409,2.409,0,1,1,2.409-2.409A2.409,2.409,0,0,1,14.864,17.273Z" transform="translate(-5.83 -8.239)" fill="rgba(0,0,0,0.8)" />
                                                                            </svg></span> View Order
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                : <div className='infoshow'>
                                    No data found
                                </div>
                            }
                        </div>
                    </div>
                    <div className='pagination-row'>
                        {/* Pagination start */}
                        {delivery?.data?.length > 0 &&
                            <Pagination data={delivery} page={page} setPage={setPage} />
                        }
                    </div>

                </div>

                <div className='clearfix'></div>
                <div className='Footer_border'><Footer /></div>
            </div>


            {/* Delivery status Popup start */}
            {(screen == "deliverystatus" || params?.slug) &&
                <MyDeliveryStatus showPopup={showPopup} setShowPopup={setShowPopup} selectedItem={selectedItem} slug={params?.slug} />
            }
            {/* Delivery status Popup End */}

            {/* Customer detail Popup Start */}
            {screen == "customerdetail" &&
                <div id='Filter_Popup' className={`app-popup ${showPopup}`}>
                    <div className='app-popup-container'>
                        <div className="app-popup-content app-popup-585px">
                            <div className="app-popup-header border-bottom">
                                <h2 className='text-primary font-20px-imp'>Customer Details</h2>

                                <button onClick={() => {
                                    setShowPopup("app-popup-hide")
                                }} className='app-popup-close'><svg width="12.191" height="12.191" viewBox="0 0 12.191 12.191">
                                        <path id="Icon_material-close" data-name="Icon material-close" d="M19.691,8.728,18.464,7.5,13.6,12.368,8.728,7.5,7.5,8.728,12.368,13.6,7.5,18.464l1.228,1.228L13.6,14.824l4.868,4.868,1.228-1.228L14.824,13.6Z" transform="translate(-7.5 -7.5)" />
                                    </svg>
                                </button>
                            </div>
                            <div className="app-popup-body app-popup-body-scroll">


                                <div className="filter-contentBox">
                                    <div>
                                        <ul className="row row-cols-sm-2 line-height-130">
                                            <li className="mt-2 col-lg-12">
                                                <div className="d-flex mb-3">
                                                    <span className="detail_iconSpan mt-2 d-flex"><svg width="12" height="14.4" viewBox="0 0 12 14.4">
                                                        <g id="Group_13094" data-name="Group 13094" transform="translate(-9 -6)">
                                                            <path id="Path_3233" data-name="Path 3233" d="M15,13.2a3.536,3.536,0,0,0,3.6-3.6,3.6,3.6,0,1,0-7.2,0A3.536,3.536,0,0,0,15,13.2Zm2.4,1.2H12.6A3.536,3.536,0,0,0,9,18v2.4H21V18A3.536,3.536,0,0,0,17.4,14.4Z" transform="translate(0 0)"></path>
                                                        </g>
                                                    </svg>
                                                    </span>
                                                    <div className='cut_detailstxt'>
                                                        <div className="font-12px">Name</div>
                                                        <div id="name_show" className="font-16px">{customer?.name}</div>
                                                    </div>
                                                </div>
                                            </li>

                                            <li className="mt-2 col-lg-12">
                                                <div className="d-flex mb-3">
                                                    <span className="detail_iconSpan mt-2 d-flex"><svg width="12" height="14.4" viewBox="0 0 12 14.4">
                                                        <svg className="position-relative top-2px" width="12" height="12" viewBox="0 0 12 12">
                                                            <path id="Icon_awesome-phone-alt" data-name="Icon awesome-phone-alt" d="M11.658,8.48,9.033,7.355a.563.563,0,0,0-.656.162L7.214,8.937A8.687,8.687,0,0,1,3.061,4.784l1.42-1.163a.561.561,0,0,0,.162-.656L3.518.34A.566.566,0,0,0,2.873.015L.436.577A.563.563,0,0,0,0,1.125,10.874,10.874,0,0,0,10.875,12a.563.563,0,0,0,.548-.436l.563-2.438a.569.569,0,0,0-.328-.647Z" transform="translate(0 0)" fill="#333"></path>
                                                        </svg>
                                                    </svg>
                                                    </span>
                                                    <div className='cut_detailstxt'>
                                                        <div className="font-12px">Phone</div>
                                                        <div><a href='tel:+254 9928533696' className="font-16px colorlinks">+254 {customer?.phone_no}</a></div>
                                                    </div>
                                                </div>
                                            </li>

                                            <li className="mt-2 col-lg-12">
                                                <div className="d-flex mb-3">
                                                    <span className="detail_iconSpan mt-2 d-flex"><svg width="12" height="14.4" viewBox="0 0 12 14.4">
                                                        <svg className="position-relative top-3px" width="12" height="9.6" viewBox="0 0 12 9.6">
                                                            <path id="Icon_material-email" data-name="Icon material-email" d="M13.8,6H4.2A1.2,1.2,0,0,0,3.006,7.2L3,14.4a1.2,1.2,0,0,0,1.2,1.2h9.6A1.2,1.2,0,0,0,15,14.4V7.2A1.2,1.2,0,0,0,13.8,6Zm0,2.4L9,11.4l-4.8-3V7.2l4.8,3,4.8-3Z" transform="translate(-3 -6)" fill="#333"></path>
                                                        </svg>
                                                    </svg>
                                                    </span>
                                                    <div className='cut_detailstxt'>
                                                        <div className="font-12px">Email</div>
                                                        <div><a href='mailto:aman@yopmail.com' className="font-16px colorlinks">{customer?.email}</a></div>
                                                    </div>
                                                </div>
                                            </li>
                                            {customer?.address &&
                                                <li className="mt-2 col-lg-12">
                                                    <div className="d-flex mb-3">
                                                        <span className="detail_iconSpan d-flex"><svg className="position-relative top-2px" width="12" height="14.434" viewBox="0 0 12 14.434">
                                                            <path id="Path_3232" data-name="Path 3232" d="M152.556,33.14a5.994,5.994,0,0,0-5.9-5.741,5.878,5.878,0,0,0-4.3,1.716,5.98,5.98,0,0,0-1.8,4.282v.176a5.946,5.946,0,0,0,1.8,4.057l4.218,4.2,4.314-4.266a6.049,6.049,0,0,0,1.668-4.426Zm-6,2.951a2.662,2.662,0,1,1,2.662-2.662A2.656,2.656,0,0,1,146.559,36.091Z" transform="translate(-140.561 -27.398)" fill="#333"></path>
                                                        </svg>

                                                        </span>
                                                        <div className='cut_detailstxt'>
                                                            <div className="font-12px">Address</div>
                                                            <div className="font-16px">{customer?.address}</div>
                                                        </div>
                                                    </div>
                                                </li>
                                            }


                                        </ul>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            }
            {/* Customer detail Popup End */}

            {/* order detail poup start */}
            {screen == "orderdetail" &&
                <OrderDetail showPopup={showPopup} setShowPopup={setShowPopup} selectedItem={selectedItem} />
            }
            {/* order detail popup end */}
        </>
    )
}
export default MyDelivery;