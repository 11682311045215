import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ApiService } from '../services/ApiService';
import { storeUser } from '../redux/action';
import { STORE_USER_DATA } from '../redux/constant';
import LoginPopup from './LoginPopup';
import LogoutPopup from './LogoutPopup';
import SignUp from './SignUp';
import LoginUser from './LoginUser';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
const Webheader = (offset) => {
    const [screen, setScreen] = useState('')
    const [openLogin, setOpenLogin] = useState("app-popup-hide");
    const [forgotPasswordToken, setForgotPasswordToken] = useState('')
    const categories = useSelector(state => state.settings.categories)
    const marketPlaceSetting = useSelector(state => state?.settings)
    const queryParams = new URLSearchParams(window.location.search)
    const auth_token = queryParams.get("auth_token")

    const history = useHistory();

    function setCategoryList(category_type, name) {
        history.push("/category/" + category_type);
    }
    const user = useSelector(state => state.user);
    if (marketPlaceSetting?.is_mobile_view || auth_token) {
        return <></>
    }

    return (<>
        <header id="app-header" className={"position-fixed d-flex align-items-center shadow-sm start-0 top-0 w-100 py-md-3 z-4 " + (offset > 10 ? "darkHeader" : "clearHeader")}
        >
            <div className="container">
                <div className="d-flex justify-content-between align-items-md-center flex-column flex-md-row app-header-row">
                    <div className="d-flex align-items-center Header_lt"  >
                        <img className='logo-app cursor-pointer' src={require("../assets/img/logo.png")} onClick={() => {
                            history.push("/")
                        }} />
                    </div>


                    {/* Search section start */}
                    {/*   <div className='topbar-1 topbar-n1 border bg-white d-flex mx-auto flex-grow-1 ms-xl-4'>
                        <div className='flex-0-0-auto ps-2 d-flex align-items-center rounded-0 pe-2 area-filter area-filter-n1 border-end'>
                            <div className=''> <svg className='position-relative top--2 ms-2' width="13.451" height="15.904" viewBox="0 0 12.451 15.904"><use xlinkHref="#map-icon1"></use></svg></div>
                            <div className='flex-grow-1'>

                                <select className="form-select h-52px border-0" defaultValue={''}>
                                    <option selected>Nairobi</option>
                                    <option value="1">Nairobi</option>
                                    <option value="2">Nairobi</option>
                                    <option value="3">Nairobi</option>
                                </select>
                            </div>
                        </div>
                        <div className="input-group input-group-search1">
                            <input
                                type="text"
                                placeholder='Search business by name or type'
                                className="form-control rounded-0 form-control-lg border-0 font-16px h-54px"
                            />
                            <button className="input-group-text bg-transparent border-0"><svg width="17.022" height="17.022" viewBox="0 0 17.022 17.022"><use xlinkHref="#search-icon"></use></svg></button>
                        </div>
                    </div> */}
                    {/* Search section end */}

                    {/* This HTML for mobile responsive start */}
                    {/* <div className='MobileSearch'>
            <div className='topbar-1 topbar-n1 border bg-white d-flex mx-auto flex-grow-1 ms-xl-4'>
                <div className='flex-0-0-auto ps-2 d-flex align-items-center rounded-0 pe-2 area-filter area-filter-n1 border-end'>
                    <div className=''> <svg className='position-relative top--2 ms-2' width="13.451" height="15.904" viewBox="0 0 12.451 15.904"><use xlinkHref="#map-icon1"></use></svg></div>
                    <div className='flex-grow-1'>

                        <select className="form-select h-52px border-0" defaultValue={''}>
                            <option selected>Nairobi</option>
                            <option value="1">Nairobi</option>
                            <option value="2">Nairobi</option>
                            <option value="3">Nairobi</option>
                        </select>
                    </div>
                </div>
                <div className="input-group input-group-search1">
                    <DebounceInput
                        debounceTime={500}
                        callback={setSearchKey}
                        placeholder="Search business by name or type"
                        className="form-control rounded-0 form-control-lg border-0 font-16px h-54px"
                    />
                    <button className="input-group-text bg-transparent border-0"><svg width="17.022" height="17.022" viewBox="0 0 17.022 17.022"><use xlinkHref="#search-icon"></use></svg></button>
                </div>
            </div>

        </div> */}
                    <div className="d-inline-flex align-items-center Header_rt mobile-header-nav">
                        <ul className='headerMenuu align-items-center d-inline-flex'>

                            {categories && categories.length > 0 && <li onClick={({ target }) => target.classList.toggle('active')} className='dropdown_app_menu'>
                                <span className='dropdown_toggle_btn cursor-pointer'></span>
                                <a>Businesses</a>

                                <div className='dropdown_app'>

                                    <span className='dropdown_app_arrow'><svg width="17" height="8.568" viewBox="0 0 17 8.568">
                                        <g id="Polygon_5" data-name="Polygon 5" fill="#fff">
                                            <path d="M 15.79971027374268 8.068483352661133 L 1.200289726257324 8.068483352661133 L 8.5 0.7099633812904358 L 15.79971027374268 8.068483352661133 Z" stroke="none" />
                                            <path d="M 8.5 1.419923305511475 L 2.400579452514648 7.568483352661133 L 14.59942054748535 7.568483352661133 L 8.5 1.419923305511475 M 8.5 3.814697265625e-06 L 17 8.568483352661133 L 0 8.568483352661133 L 8.5 3.814697265625e-06 Z" stroke="none" fill="#e3e3e3" />
                                        </g>
                                    </svg>
                                    </span>

                                    <ul className="list-group list-group-flush">
                                        {categories.map(cat => <li key={cat.id} className="list-group-item">
                                            <a href='#!'
                                                onClick={(e) => {
                                                    setCategoryList(cat?.slug);
                                                    e.preventDefault();
                                                    window.location.reload(false);
                                                }}>
                                                {cat.name}
                                            </a>
                                        </li>)}

                                    </ul>
                                </div>
                            </li>}

                            <li className={`${offset?.type == 'deals' && 'active'}`}>
                                <a href='#!'
                                    onClick={(e) => {
                                        history.push("/deals");
                                        e.preventDefault();

                                    }}>
                                    Deals
                                </a>
                            </li>

                            <li className={`${offset?.type == 'voucher' && 'active'}`}>
                                <a href='#!'
                                    onClick={(e) => {
                                        history.push("/vouchers");
                                        e.preventDefault();

                                    }}>
                                    Vouchers
                                </a>
                            </li>

                            {!user.access_token && <li className="dropdown_app_menu">
                                <span className="dropdown_toggle_btn cursor-pointer" />
                                <a >
                                    Login
                                </a>
                                <div className="dropdown_app mobile-login">
                                    <span className="dropdown_app_arrow">
                                        <svg width={17} height="8.568" viewBox="0 0 17 8.568">
                                            <g id="Polygon_5" data-name="Polygon 5" fill="#fff">
                                                <path
                                                    d="M 15.79971027374268 8.068483352661133 L 1.200289726257324 8.068483352661133 L 8.5 0.7099633812904358 L 15.79971027374268 8.068483352661133 Z"
                                                    stroke="none"
                                                />
                                                <path
                                                    d="M 8.5 1.419923305511475 L 2.400579452514648 7.568483352661133 L 14.59942054748535 7.568483352661133 L 8.5 1.419923305511475 M 8.5 3.814697265625e-06 L 17 8.568483352661133 L 0 8.568483352661133 L 8.5 3.814697265625e-06 Z"
                                                    stroke="none"
                                                    fill="#e3e3e3"
                                                />
                                            </g>
                                        </svg>
                                    </span>
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item"
                                            onClick={(e) => {
                                                setOpenLogin("")
                                                // e.preventDefault()
                                            }}>
                                            <a href="#!">Customer Login</a>
                                        </li>
                                        <li className="list-group-item"
                                            onClick={() => {
                                                window.open(ApiService.config.url + 'business/login', '_blank', 'noreferrer')
                                            }}>
                                            <a href="#!">Business Login</a>
                                        </li>
                                    </ul>
                                </div>
                            </li>}

                            {/* {!user.access_token && <li className="dropdown_app_menu" onClick={() => {
                                setScreen('signup')
                            }}>
                                <span className="dropdown_toggle_btn cursor-pointer" />
                                <a >
                                    Signup
                                </a>
                            </li>} */}
                        </ul>

                        {user.access_token &&
                            <LogoutPopup path={offset.path} />
                        }

                        <a className='btn btn-primary rounded-50px ListBusiness_CTA'
                            onClick={() => {
                                window.open(ApiService.config.url + 'business', '_blank', 'noreferrer')
                            }}
                        >+ List Your Business</a>
                    </div>
                </div>
            </div>
        </header>
        {/* Login Pop start */}
        {/* <LoginPopup openClass={openLogin} onClassSet={setOpenLogin} /> */}

        <LoginUser setScreen={setScreen} openLogin={openLogin} setOpenLogin={setOpenLogin} />

        {screen == 'signup' ?
            <SignUp setScreen={setScreen} openLogin={openLogin} setOpenLogin={setOpenLogin} />
            :screen == 'forgot-password' ?
            <ForgotPassword setScreen={setScreen} openLogin={openLogin} setOpenLogin={setOpenLogin} token={setForgotPasswordToken} />
            :screen == 'reset-password' ?
            <ResetPassword setScreen={setScreen} openLogin={openLogin} setOpenLogin={setOpenLogin} token={forgotPasswordToken} />
            :""}
        {/* Login Pop end */}


        {/* Confirm Your Number Pop start */}

        {/* Confirm Your Number Pop end */}

    </>

    )


}
export default Webheader;