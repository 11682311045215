import React, { useState } from 'react';
import Helper from '../../utils/helper';
import { ShowMap } from '../../Components/ShowMap';
const Popup = React.lazy(() => import('../Deals/Popup')); 


export default (props) => {
    let branchData = props.data;
    
    const [showPopup, setShowPopup] = useState(false);
    const [currentDealDetail, setCurrentDealDetail] = useState(false);

    return <div className="container mb-2 mb-xl-5">
            <div className="row Overview-row">
                <div className="col pe-xl-5">
                    <div className="mb-3 mb-md-4 pb-2 pt-xl-3">
                        <div className="mb-3 mb-md-4">
                            <h3 className="font-26px">Contact</h3>
                        </div>
                        <div className="d-flex justify-content-between contact-wrap">
                            <div className="contact-wrap-col flex-0-0-auto contact-wrap-col1 border-end">
                                <div>
                                    <a href={'tel:' + branchData.country_code + "" + branchData.phone_no} className="d-inline-flex mb-2 mb-xl-4 pb-2 link-default">
                                        <span className="me-2 me-md-3">
                                            <svg
                                                className="fill-default contact-colIcon"
                                                width="26.531"
                                                height="26.531"
                                                viewBox="0 0 26.531 26.531"
                                            >
                                                <use xlinkHref="#icon-call" />
                                            </svg>
                                        </span>
                                        <div>
                                            <div className="font-primary-semibold font-20px mb-1">
                                                + {branchData.country_code} {branchData.phone_no}
                                            </div>
                                            <div className="font-primary-light font-16px today_open_time0">
                                                {
                                                    branchData.today_time_status != "Closed" && <>({Helper.timeFormat(branchData.today_open_time)} - {Helper.timeFormat(branchData.today_close_time)})</>
                                                }
                                                {
                                                    branchData.today_time_status == "Closed" && <>{branchData.today_time_status}</>
                                                }
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div>
                                    <a target="_blank" href="mailto:customercare@artcaﬀe.co.ke" className="d-inline-flex align-items-center mb-4 pb-2 link-default">
                                        <span className="me-2 me-md-3">
                                            <svg
                                                className="fill-default contact-colIcon"
                                                width={27}
                                                height={27}
                                                viewBox="0 0 27 27"
                                            >
                                                <use xlinkHref="#icon-mail" />
                                            </svg>
                                        </span>
                                        <div className="font-20px">{branchData.email}</div>
                                    </a>
                                </div>
                            </div>
                            <div className="contact-wrap-col d-flex justify-content-start flex-grow-1">
                                <div className='contact-wrap-col2_inner'>
                                    <div className="map-wrap">
                                        <ShowMap data={branchData}/>
                                    </div>
                                    <div className="d-flex justify-content-between pt-2 map-wrap-bottom">
                                        <div className="font-20px me-4 line-height-170">
                                            {branchData?.physical_location ? branchData?.physical_location : branchData?.location}
                                        </div>
                                        <div className="pt-2 flex-0-0-auto">
                                            <a target="_blank" href={'https://www.google.com/maps?daddr=' + branchData.location}
                                                className="btn btn-outline-dark font-14px">
                                                <svg className='me-2' width="16.043" height="16.043" viewBox="0 0 16.043 16.043">
  <g id="noun-navigate-225681" transform="translate(-73.79 -4.58)">
    <path id="Path_2482" data-name="Path 2482" d="M81.812,20.623A8.022,8.022,0,1,1,89.833,12.6,8.031,8.031,0,0,1,81.812,20.623Zm0-14.942A6.921,6.921,0,1,0,88.733,12.6a6.929,6.929,0,0,0-6.921-6.921Z" fill-rule="evenodd"/>
    <path id="Path_2483" data-name="Path 2483" d="M240,206.189l2.319.8a.855.855,0,0,1,.53.53l.8,2.319,1.912-5.56Z" transform="translate(-161.403 -193.921)" fill-rule="evenodd"/>
  </g>
</svg>

                                                Get Direction
                                            </a>
                                        </div>
                                    </div>
                                </div>
                             </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
}