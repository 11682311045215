import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
const Tabs = (props) => {
    const history = useHistory()
    const location = useLocation()

    const marketPlaceSetting = useSelector(state => state?.settings)
    const queryParams = new URLSearchParams(window.location.search)
    const auth_token = queryParams.get("auth_token")

    useEffect(() => {
        if (!auth_token && !marketPlaceSetting?.is_mobile_view) {
            document.getElementById(props?.active).scrollIntoView({
                behavior: "smooth", block: "end", inline: "nearest"
              });
        }
    }, [])

    if (marketPlaceSetting?.is_mobile_view || auth_token) {
        return <></>
    }
    return (
        <>
            <div className='border-bottom bg-light-grey pt-0 pt-md-4 mb-4 mt-md-0'>
                <div className='container'>
                    <div className="d-flex flex-column-reverse flex-md-row justify-content-between nav-wrapper-inner" >

                        <nav className="navbar navbar-expand-sm navbar-header py-0 SiteNavBar1 SiteNav_order">

                            <ul className="navbar-nav me-auto mb-lg-0" id='scroll' >
                                <li id='current' className={`nav-item ${props.active == "current" && "active"}`}
                                    onClick={() => {
                                        if (props.active != "current") {
                                            history.push("/my-order/current")
                                            if (props?.setPage && props?.setOrderList) {
                                                props?.setPage(1)
                                                props?.setOrderList(false)
                                            }
                                        }
                                    }}
                                >
                                    <a className="nav-link hamburgerAction cursor-pointer">Open Orders & Bookings</a>
                                </li>
                                <li id='past' className={`nav-item ${props.active == "past" && "active"}`}
                                    onClick={() => {
                                        if (props.active != "past") {
                                            history.push("/my-order/past")
                                            if (props?.setPage && props?.setOrderList) {
                                                props?.setPage(1)
                                                props?.setOrderList(false)
                                            }
                                        }
                                    }}
                                >
                                    <a className="nav-link hamburgerAction cursor-pointer">Past Orders & Bookings </a>
                                </li>

                                <li id='membership' className={`nav-item ${props.active == "membership" && "active"}`}
                                    onClick={() => {
                                        history.push("/mymembership")
                                    }}
                                >
                                    <a className="nav-link hamburgerAction cursor-pointer">My Membership</a>
                                </li>

                                <li id='voucher' className={`nav-item ${props.active == "voucher" && "active"}`}
                                    onClick={() => {
                                        history.push("/myvoucher")
                                    }}
                                >
                                    <a className="nav-link hamburgerAction cursor-pointer">My Voucher</a>
                                </li>

                                {/* <li className={`nav-item ${props.active == "membershipdetail" && "active"}`}
                                        onClick={() => {
                                            history.push("/mymembershipdetail")
                                        }}
                                    >
                                        <a className="nav-link hamburgerAction cursor-pointer">My Membership Detail</a>
                                    </li> */}
                                <li id='profile' className={`nav-item ${props.active == "profile" && "active"}`}
                                    onClick={() => {
                                        history.push("/myprofile")
                                    }}
                                >
                                    <a className="nav-link hamburgerAction cursor-pointer">My Profile</a>
                                </li>
                                <li id='delivery' className={`nav-item ${props.active == "delivery" && "active"}`}
                                    onClick={() => {
                                        history.push("/mydelivery")
                                    }}
                                >
                                    <a className="nav-link hamburgerAction cursor-pointer">My Deliveries</a>
                                </li>
                                <li id='invoice' className={`nav-item ${props.active == "invoice" && "active"}`}
                                    onClick={() => {
                                        history.push("/myinvoices")
                                    }}
                                >
                                    <a className="nav-link hamburgerAction cursor-pointer">My Invoices</a>
                                </li>
                                <li id='investment' className={`nav-item ${props.active == "investment" && "active"}`}
                                    onClick={() => {
                                        history.push("/myinvestment_dashboard")
                                    }}
                                >
                                    <a className="nav-link hamburgerAction cursor-pointer">My Investment</a>
                                </li>
                                <li id='approvalrequest' className={`nav-item ${props.active == "approvalrequest" && "active"}`}
                                    onClick={() => {
                                        history.push("/approvalrequest")
                                    }}
                                >
                                    <a className="nav-link hamburgerAction cursor-pointer">Approval Request</a>
                                </li>
                            </ul>

                        </nav>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Tabs;