import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { forwardRef } from 'react';
const ApprovalFilter = (props) => {
    const history = useHistory()
    const params = useParams()
    console.log("filter params", params)
    const queryParams = new URLSearchParams(window.location.search)
    const from_date = queryParams.get("from_date")
    const to_date = queryParams.get("to_date")
    const touchpoint = queryParams.get("touchpoint")
    const submitby = queryParams.get("submitby")

    // const [fromDate, setFromDate] = useState(null)
    // var date = fromDate?.getFullYear() + '-' + (fromDate?.getMonth() + 1) + '-' + fromDate?.getDate();
    // console.log("from date", date)

    const [filters, setFilters] = useState(
        {
            from_date: from_date ? from_date : "",
            to_date: to_date ? to_date : "",
            touchpoint : touchpoint ? touchpoint : "",
            submitby : submitby ? submitby : ""
        }
    )

    // console.log("from_date and to date", filters.from_date, filters.to_date)

    const [fromDate, setFromDate] = useState("")
    const [toDate, setToDate] = useState("")

    let dateChange = filters.from_date.toString()

    useEffect(() => {
        setFilters({
            from_date: from_date ? from_date : "",
            to_date: to_date ? to_date : "",
            touchpoint : touchpoint ? touchpoint : "",
            submitby : submitby ? submitby : ""
        })
    }, [from_date, to_date, touchpoint, submitby])

    // console.log("Approval filters", filters.status)

    function applyFilters() {
        let query = ""
        let path = "/approvalrequest/" + (!params?.status ? 'pending' : params?.status)
        let filterr = []
        for (let filter in filters) {
            console.log("filterssss", filter)
            if (filters[filter]) {
                query += query == "" ? "" : "&"
                query += filter + "=" + filters[filter]
                filterr.push(filter)
            }
            history.push(path + "?" + query)
        }
    }

    function fmt(date, format = 'YYYY-MM-DDThh:mm:ss') {
        const pad2 = (n) => n.toString().padStart(2, '0');
      
        const map = {
          YYYY: date.getFullYear(),
          MM: pad2(date.getMonth() + 1),
          DD: pad2(date.getDate())
        };
      
        return Object.entries(map).reduce((prev, entry) => prev.replace(...entry), format);
      }

      const DateCustomPicker = forwardRef(({ value, onClick }, ref) => (
        <>
            <input onClick={onClick}
                type="text"
                value={value}
                readOnly={true}
                style={{ backgroundColor: "white" }}
                className={`form-control font-16 text-primary-dark pe-0 h-44px input_DateRange input_DateRange-max-inherit mb-2 ${value ? '' : 'date-range-custom-input'}`}
                placeholder="Select date"
            />
        </>
    ));

    return (
        <>
            <div id='filterpopup' className={`app-popup ${props.showPopup}`}>
                <div className='app-popup-container'>
                    <div className="app-popup-content app-popup-585px">
                        <div className="app-popup-header border-bottom">
                            <h2 className='text-primary font-20px-imp'>Filters</h2>

                            <button onClick={() => {
                                props.setShowPopup("app-popup-hide")
                            }} className='app-popup-close'><svg width="12.191" height="12.191" viewBox="0 0 12.191 12.191">
                                    <path id="Icon_material-close" data-name="Icon material-close" d="M19.691,8.728,18.464,7.5,13.6,12.368,8.728,7.5,7.5,8.728,12.368,13.6,7.5,18.464l1.228,1.228L13.6,14.824l4.868,4.868,1.228-1.228L14.824,13.6Z" transform="translate(-7.5 -7.5)" />
                                </svg>
                            </button>
                        </div>
                        <div className="app-popup-body app-popup-body-scroll">
                            <div className="filter-contentBox filterwid">
                                <div className='row mb-2'>
                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6'>
                                        <label className='font-18px font-primary-semibold mb-2 mt-2 colorhead'>From Date</label>
                                        {/* <input type='date' className="mb-2 form-control h-44px"
                                            value={filters.from_date}
                                            onChange={(e) => {
                                                setFilters({
                                                    ...filters, from_date: e.target.value
                                                })
                                            }} /> */}
                                        <div className='date-row-full'>
                                            <DatePicker 
                                            selected={fromDate}
                                                className="mb-2 form-control h-44px"
                                                placeholderText="from date"
                                                // minDate={new Date()}
                                                maxDate={new Date()}
                                                dateFormat={"yyyy-MM-dd"}
                                                onChange={(date) => {
                                                    setFromDate(date)
                                                    setFilters({
                                                        ...filters,
                                                        from_date: fmt(date,"YYYY-MM-DD"),
                                                        to_date : ''                                                                                                                                                                                                                     
                                                    })
                                                }}
                                            customInput={<DateCustomPicker />} />
                                        </div>
                                    </div>
                                    <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6'>
                                        <label className='font-18px font-primary-semibold mb-2 mt-2 colorhead'>To Date</label>

                                        {/* <input type='date' className="mb-2 form-control h-44px"
                                            value={filters.to_date}
                                            onChange={(e) => {                                                                                                                                                                                                 
                                                setFilters({
                                                    ...filters, to_date: e.target.value
                                                })
                                            }} /> */}
                                        <div className='date-row-full'>                                        
                                         <DatePicker 
                                            selected={toDate}
                                            className="mb-2 form-control h-44px"
                                            placeholderText="to date"
                                            minDate={new Date(filters.from_date)}
                                            maxDate={new Date()}
                                            dateFormat={"yyyy-MM-dd"}
                                            onChange={(date) => {
                                                setToDate(date)
                                                setFilters({
                                                    ...filters,
                                                    to_date: fmt(date,"YYYY-MM-DD")                                                                                                                                                                                                                     
                                                })
                                            }}
                                            customInput={<DateCustomPicker />} />
                                        </div>

                                    </div>
                                </div>
                                <div>

                                    <div className='col-xl-12 border-bottom-divide mb-3 mt-3'></div>
                                    <div className='row mb-2'>
                                        <div className='col-xl-6 col-lg-6 col-md-6 selectbg'>
                                            <label className='font-18px font-primary-semibold mb-2 mt-3 colorhead'>Submitted By</label>
                                            <select className='mb-2 form-control h-44px arrowlist'
                                              onChange={(e) => {
                                                setFilters({
                                                    ...filters, submitby: e.target.value
                                                })
                                            }}
                                                value={filters.submitby}>
                                            <option value="">Select your option</option>
                                            {props?.touchPointList?.submitBy?.map((item)=>{
                                           return <option key={item?.id} value={item?.id}>{item?.name}</option>
                                        })}
                                            </select>
                                        </div>

                                        {/* <div className='col-xl-6 col-lg-6 col-md-6 selectbg'>
                                            <label className='font-18px font-primary-semibold mb-2 mt-3 colorhead'>Status</label>
                                            <select className='mb-2 form-control h-44px arrowlist' onChange={(e) => {
                                                setFilters({
                                                    ...filters, status: e.target.value
                                                })
                                            }}
                                                value={filters.status}>
                                                <option value="">Select your option</option>
                                                <option value="pending">pending</option>
                                                <option value="approved">approved</option>
                                                <option value="rejected">rejected</option>
                                                <option value="hold">hold</option>
                                            </select>
                                        </div> */}

                                        <div className='col-xl-6 col-lg-6 col-md-6 selectbg'>
                                            <label className='font-18px font-primary-semibold mb-2 mt-3 colorhead'>Touch Points</label>
                                            <select className='mb-2 form-control h-44px arrowlist'
                                             onChange={(e) => {
                                                setFilters({
                                                    ...filters, touchpoint: e.target.value
                                                })
                                            }}
                                                value={filters.touchpoint}>
                                            <option value="">Select your option</option>
                                            {props?.touchPointList?.touchPoints?.map((item)=>{
                                               return <option value={item?.id} key={item?.id}>{item?.title}</option>
                                            })}
                                            </select>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className="app-popup-footer border-top">
                            <div className="d-flex align-items-center justify-content-between">
                                <div>
                                    <a className="font-20px font-primary-semibold link-default cursor-pointer text-decoration-underline"
                                        onClick={() => {
                                            setFilters({
                                                from_date: "",
                                                to_date: "",
                                                touchpoint : "",
                                                submitby : ""
                                            })
                                            setFromDate("")
                                            setToDate("")
                                        }}
                                    >
                                        Clear all
                                    </a>
                                </div>
                                <div>
                                    <button type="button" className="btn text-center justify-content-center btn-primary h-44px font-18px"
                                        onClick={() => {
                                            applyFilters()
                                            props.setShowPopup("app-popup-hide")
                                            if (window.location.search != props.url) {
                                                props.setRequestList(false)
                                            }
                                        }}
                                    >
                                        Show
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ApprovalFilter;