import React, { forwardRef } from 'react';
import { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Footer } from '../../Components/Footer';
import Webheader from '../../library/WebHeader';
import Tabs from './Tabs';
import { useEffect } from 'react';
import { ApiService } from '../../services/ApiService';
import InvestmentPopup from './InvestmentPopup';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from '../../library/loader';
import Helper from '../../utils/helper';
import Pagination from '../../library/Pagination';
const MyInvestment = () => {
    const [showPopup, setShowPopup] = useState("app-popup-hide")
    const [screen, setScreen] = useState("")
    const history = useHistory()
    const location = useLocation()
    let params = useParams()
    let businessId = params?.businessid
    let touchPointId = params?.touchpointid

    const [touchPointOrder, setTouchPointOrder] = useState(false)
    const [selectedItem, setSelectedItem] = useState("")
    const [touchPoints, setTouchPoints] = useState(false)
    const [searchKey, setSearchKey] = useState(location?.state?.search)
    const [page, setPage] = useState(1)
    // const [tableId, setTableId] = useState("")

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    let firstDate = startDate ? startDate?.getFullYear() + '-' + (startDate?.getMonth() + 1) + '-' + startDate?.getDate() : ""
    let secondDate = endDate ? endDate?.getFullYear() + '-' + (endDate?.getMonth() + 1) + '-' + endDate?.getDate() : ""

    let currency = "KES"

    useEffect(() => {
        setTouchPointOrder(false)
        ApiService.fetch({
            url: "get-customer-touch-point-orders",
            options: {
                body: {
                    business_id: businessId,
                    table_id: touchPointId,
                    search: searchKey,
                    start_date: firstDate,
                    end_date: secondDate,
                    page: page
                }
            }
        }).then((data) => {
            if (data.status == true) {
                setTouchPointOrder(data)
            }
        });
    }, [searchKey, firstDate, secondDate, touchPointId, page])

    useEffect(() => {
        if (businessId) {
            // alert("")
            ApiService.fetch({
                url: "get-customer-touch-point-investor",
                options: {
                    body: {
                        business_id: businessId,
                    }
                }
            }).then((data) => {
                if (data.status == true) {
                    setTouchPoints(data)
                }
            });
        }
    }, [businessId])

    const orderItmCommaSep = (arr) => {
        if (arr != null && arr.length) {
            let commSepVal = [];
            arr.forEach(element => {
                let str = (element?.item?.title)?.toString();
                return commSepVal.push(str);
            });
            return commSepVal.join(', ');
        }
        return '';
    }

    if (!touchPointOrder) {
        return <div className="page-wrapper page-wrapper-footer1 page-wrapper-headerSearch overflow-hidden">
            <Webheader />
            <Tabs active={"investment"} />
            <Loader />
            <div className='clearfix'></div>
            <div className='Footer_border'><Footer /></div>
        </div>
    }

    const DateCustomPicker = forwardRef(({ value, onClick }, ref) => (
        <>
            <input onClick={onClick}
                type="text"
                value={value}
                readOnly={true}
                style={{ backgroundColor: "white" }}
                className=""
                placeholder="Select Start & End Date"
            />
        </>
    ));

    return (
        <>
            <div className="page-wrapper page-wrapper-footer1 page-wrapper-headerSearch overflow-hidden">
                <Webheader />
                <div className='MobileSearch hide-element'>
                    {/* Search section start */}
                    <div className='topbar-1 topbar-n1 border bg-white d-flex mx-auto flex-grow-1 ms-xl-4'>
                        <div className='flex-0-0-auto ps-2 d-flex align-items-center rounded-0 pe-2 area-filter area-filter-n1 border-end'>
                            <div className=''> <svg className='position-relative top--2 ms-2' width="13.451" height="15.904" viewBox="0 0 12.451 15.904"><use xlinkHref="#map-icon1"></use></svg></div>
                            <div className='flex-grow-1'>

                                <select className="form-select h-52px border-0">
                                    <option defaultValue={"Nairobi"}>Nairobi</option>
                                    <option value="1">Nairobi</option>
                                    <option value="2">Nairobi</option>
                                    <option value="3">Nairobi</option>
                                </select>
                            </div>
                        </div>
                        <div className="input-group input-group-search1">
                            <input
                                type="text"
                                placeholder='Search business by name or type'
                                className="form-control rounded-0 form-control-lg border-0 font-16px h-54px"
                            />
                            <button className="input-group-text bg-transparent border-0"><svg width="17.022" height="17.022" viewBox="0 0 17.022 17.022"><use xlinkHref="#search-icon"></use></svg></button>
                        </div>
                    </div>
                    {/* Search section end */}
                </div>

                <Tabs active={"investment"} />

                <div className="container mb-2 mb-md-5">
                    <div className='myinvesthead'>
                        <div className="row">
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
                                <div className='d-flex align-items-center'>
                                    <a className='me-2 cursor-pointer'
                                        onClick={() => {
                                            history.push("/myinvestment_dashboard/" + businessId)
                                        }}>
                                        <svg width="26" height="26" viewBox="0 0 26 26">
                                            <path id="Path_3316" data-name="Path 3316" d="M26,13A13,13,0,1,1,13,0,13,13,0,0,1,26,13ZM13.576,17.3a.814.814,0,1,1-1.15,1.151L7.55,13.576a.812.812,0,0,1,0-1.15L12.425,7.55a.814.814,0,1,1,1.15,1.15l-3.489,3.487h7.788a.813.813,0,0,1,0,1.625H10.087L13.576,17.3Z" fill="#ef626c" fillRule="evenodd" />
                                        </svg>
                                    </a>
                                    <div className='touchtxt d-flex align-items-center ms-2'>
                                        <label>Touch Points</label>
                                        <b className='pointshow ms-2'>{touchPoints?.data?.length}</b>
                                    </div>
                                </div>
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12'>
                                <div className='daylist mt-0'>
                                    <ul>
                                        <li>
                                            <select className='dropdays' onChange={(e) => {
                                                setSearchKey(e.target.value)
                                                setDateRange([null, null])
                                                // setTableId("")
                                                setPage(1)
                                                setTouchPointOrder(false)
                                            }}
                                                value={searchKey}>
                                                <option value="">Select your option</option>
                                                <option value="30">Last 30 Days</option>
                                                <option value="15">Last 15 Days</option>
                                                <option value="7">Last 7 Days</option>
                                                <option value="2">Last 2 Days</option>
                                                <option value={"today"}>Today</option>
                                            </select>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div className='salestablerow'>
                        <div className='row'>
                            <div className='col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12'>
                                <div className='salehead'>
                                    <h2 className='font-24px'>Total Sales - <span>{currency} {touchPointOrder?.total_sale}</span></h2>
                                    <h5 className='font-18px'>{touchPointOrder?.date}</h5>
                                </div>
                            </div>
                            <div className='col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12'>
                                <div className='salfilter start-date-pikar'>
                                    <div className='dateshow mb-2'>
                                        <DatePicker
                                            placeholderText="Select Start & End Date"
                                            selectsRange={true}
                                            startDate={startDate}
                                            endDate={endDate}
                                            onChange={(update) => {
                                                setDateRange(update)
                                                setSearchKey("")
                                                // setTableId("")
                                            }}
                                            isClearable={true}
                                            dateFormat={"yyyy-MM-dd"}
                                            customInput={<DateCustomPicker />}
                                        />
                                    </div>
                                    <div className='pointdiv mb-2'>
                                        <label>Touch Point:</label>
                                        <select className='pointlist' onChange={(e) => {
                                            history.push("/myinvestment/" + businessId + "/" + e.target.value)
                                            // setTableId(e.target.value)
                                            setSearchKey("")
                                            setDateRange([null, null])
                                            setPage(1)
                                            setTouchPointOrder(false)
                                        }}
                                            value={touchPointId}>
                                            <option value="">All</option>
                                            {touchPoints?.data?.map((item) => {
                                                return <option key={item?.id} value={item?.id}>{item?.title}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {touchPointOrder?.data?.data?.length > 0 ?
                            <div className='tablesaleinfo'>
                                <div className="table-responsive border rounded-3 table-app1 webtable">
                                    <table className="table table-bordered table-bordered-left-less rounded-3 mb-0 data_scroll_table1">
                                        <thead>
                                            <tr>
                                                <th scope="col">Touch Point</th>
                                                <th scope="col">Order No.</th>
                                                <th scope="col">Order Amount</th>
                                                <th scope="col">Item(s)</th>
                                                <th scope="col">Ordered On</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {touchPointOrder?.data?.data?.map((item) => {
                                                return <tr>
                                                    <td>{item?.get_table_manager?.title}</td>
                                                    <td>#{item?.id}</td>
                                                    <td>{currency} {item?.final_amount}</td>
                                                    <td> {orderItmCommaSep(item?.order_items)} </td>
                                                    <td>{Helper.dateFormat(item?.created_at)}</td>
                                                    <td>
                                                        <ul className='actionlist'>
                                                            <li>
                                                                <a className='view_order' onClick={() => {
                                                                    setScreen("investmentpopup")
                                                                    setShowPopup("")
                                                                    setSelectedItem(item)
                                                                }}>
                                                                    <svg id="view" xmlns="http://www.w3.org/2000/svg" width="18.068" height="13.25" viewBox="0 0 18.068 13.25">
                                                                        <path id="Path_1741" data-name="Path 1741" d="M19.158,12.045a10.052,10.052,0,0,0-9-6.42,10.052,10.052,0,0,0-9,6.42.6.6,0,0,0,0,.41,10.052,10.052,0,0,0,9,6.42,10.052,10.052,0,0,0,9-6.42.6.6,0,0,0,0-.41Zm-9,5.625a8.943,8.943,0,0,1-7.787-5.42A8.943,8.943,0,0,1,10.16,6.83a8.943,8.943,0,0,1,7.787,5.42A8.943,8.943,0,0,1,10.16,17.67Z" transform="translate(-1.126 -5.625)" fill="rgba(0,0,0,0.8)" />
                                                                        <path id="Path_1742" data-name="Path 1742" d="M14.864,11.25a3.614,3.614,0,1,0,3.614,3.614A3.614,3.614,0,0,0,14.864,11.25Zm0,6.023a2.409,2.409,0,1,1,2.409-2.409A2.409,2.409,0,0,1,14.864,17.273Z" transform="translate(-5.83 -8.239)" fill="rgba(0,0,0,0.8)" />
                                                                    </svg>   View Order
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            :
                            <div className='infoshow'>
                                No data found
                            </div>
                        }
                        
                    {touchPointOrder?.data?.data?.length > 0 &&
                  <Pagination data={touchPointOrder?.data} page={page} setPage={setPage}/>
                    }
                    </div>


                </div>
                <div className='clearfix'></div>
                <div className='Footer_border'><Footer /></div>
            </div>

            {/* Investment Popup start */}
            {screen == "investmentpopup" &&
                <InvestmentPopup showPopup={showPopup} setShowPopup={setShowPopup} selectedItem={selectedItem} />
            }
            {/* Investment Popup End */}
        </>
    )
}
export default MyInvestment;