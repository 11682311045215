import React from 'react';
import Helper from '../../utils/helper';
const InvestmentPopup = (props) => {
    const data = props.selectedItem
    //  console.log("data for popup", data)
    let currency = "KES"
    return (
        <>
            <div id='Voucher_Details' className={`app-popup app-popup-footer-no app-popup-header-1 app-popup-offcanvas-end ${props.showPopup}`}>
                <div className='app-popup-container'>
                    <div className="app-popup-content app-popup-485px">
                        <div className="app-popup-header border-bottom py-0 d-flex align-items-center">
                            <div>
                                <h2 className='text-primary font-24px mb-1'>Order Details</h2>
                            </div>
                            <button className='app-popup-close'
                                onClick={() => {
                                    props.setShowPopup("app-popup-hide")
                                }}><svg width="12.191" height="12.191" viewBox="0 0 12.191 12.191">
                                    <path id="Icon_material-close" data-name="Icon material-close" d="M19.691,8.728,18.464,7.5,13.6,12.368,8.728,7.5,7.5,8.728,12.368,13.6,7.5,18.464l1.228,1.228L13.6,14.824l4.868,4.868,1.228-1.228L14.824,13.6Z" transform="translate(-7.5 -7.5)" />
                                </svg>
                            </button>
                        </div>
                        <div className="app-popup-body pb-0 font-14px app-popup-body-scroll">

                            <div className='row mb-3'>
                                <div className='col-xl-12 col-lg-12 col-md-12'>
                                    <div className='orderimgshow'>
                                        <div className='investadv d-flex'>
                                            <img className="me-2" height="30px" src={data?.get_business_detail?.image} />
                                            <div className='investtxt border_r_none'>
                                                <h3 className='font-16px'>{data?.get_business_detail?.name} </h3>
                                                {/* <p className='mb-1 font-12px'>Westland, Nairobi</p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-12 border-bottom-row'></div>
                            </div>

                            <div className='row mb-3'>
                                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6'>
                                    <div className="mb-1 font-10px text-uppercase">Order No.</div>
                                    <h5 className="font-12px font-primary-semibold mb-2">#{data?.id}</h5>
                                </div>
                                <div className='col-xl-5 col-lg-5 col-md-5 col-sm-5 col-6'>
                                    <div className="mb-1 font-10px text-uppercase">ordered ON</div>
                                    <h5 className="font-12px font-primary-semibold mb-2">{Helper.dateFormat(data?.created_at)}</h5>
                                </div>
                                <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3'>
                                    <div className="mb-1 font-10px text-uppercase">Order Amount</div>
                                    <h5 className="font-12px font-primary-semibold mb-2">{currency} {data?.final_amount}</h5>
                                </div>
                                <div className='col-xl-12 border-bottom-row'></div>
                            </div>

                            <div className='orderlistinfo'>
                                <h5 className="font-14px font-primary-bold mb-3">Total Item(s)/Service(s): {data?.order_items?.length}</h5>
                                <ul>
                                    {data?.order_items?.map((orderitem) => {
                                        return <li>
                                            <div className='row pb-2'>
                                                <div className='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-3'>
                                                    <div className='itemimg'>
                                                        <img src={orderitem?.item?.thumb} />
                                                    </div>
                                                </div>
                                                <div className='col-xl-7 col-lg-7 col-md-7 col-sm-7 col-6 padspace0'>
                                                    <h3 className="font-14px font-primary-semibold">{orderitem?.item?.title}</h3>
                                                    <h6 className="font-14px mb-2 font-primary-regular">Qty: {orderitem?.quantity}</h6>
                                                </div>
                                                <div className='col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3'>
                                                    <div className="mb-2">&nbsp;</div>
                                                    <h4 className="font-14px font-primary-semibold mb-2">{currency} {orderitem?.final_price}</h4>
                                                </div>
                                            </div>
                                            {/* <div className='row'>
                                                <h2 className="font-11px font-primary-semibold">Special Instructions</h2>
                                                <label className="font-12px font-primary-regular mb-2">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
                                                </label>
                                            </div> */}
                                        </li>
                                    })}
                                </ul>
                                <div className='totalitemrow mt-2 pt-3 pb-3'>
                                    <div className='row pb-2'>
                                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6'>
                                            <span className="font-14px font-primary-semibold">Grand Total</span>
                                        </div>
                                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6'>
                                            <label className="font-14px font-primary-bold">{currency} {data?.total_amount}</label>
                                            {data?.payment_status == '-1' ?
                                                <span className='Unpaid_status3 paidtxt font-10px text-uppercase text-white px-1 font-primary-semibold'>UNPAID</span>
                                                :
                                                <span className='paid_status3 paidtxt font-10px text-uppercase text-white px-1 font-primary-semibold'>PAID</span>
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
export default InvestmentPopup;