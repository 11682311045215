import React from 'react';
const MemberShipOffer = (props) => {
    return(
        <>
        <div className='Choose_Subscription mb-2 mb-md-5 pb-xl-3'>
                    <div className="mb-3 mb-md-3">
                        <h3 className="font-26px line-height-140 mb-1">Discover Exclusive Offers</h3>
                        {/* <div className="font-18px-imp line-height-150">All Platinum Membership get access to these special offers and promotions.</div> */}
                    </div>
                    <div className='row gx-sm-2 gx-xl-4'>
                        {props?.data?.get_membership_benefits?.map((item)=>{
                        return <div className='col-12 col-xl-3 col-lg-3 col-md-4 col-sm-6 mb-3 mb-md-0 d-flex' key={item?.id}>
                            <div className="card overflow-hidden p-0">
                                <div className="card-body p-0 h-100">
                                    <div className='Offers_pro_img overflow-hidden'>
                                        <div className='img-outer'><img className="w-100 h-auto" src={item?.image} /></div>
                                    </div>

                                    <div className='p-3 pb-xl-4 font-primary-semibold line-height-150'>{item?.title}</div>

                                </div>
                            </div>
                        </div>
                        })}
                    </div>
                </div>
        </>
    )
}
export default MemberShipOffer;