import React from 'react';
import { useState } from 'react';
import { ApiService } from '../../services/ApiService';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Loader } from '../../Components/SvgIcons';
import { useHistory } from 'react-router-dom';
import ResendOtp from '../../library/ResendOtp';
import ApprovalResendOtp from './ApprovalResendOtp';
const ApprovalReqStatus = (props) => {
    let subject = props.selectedItem.subject
    const user = useSelector(state => state.user.user)
    const history = useHistory()
    const [showPopup, setShowPopup] = useState("app-popup-hide")
    const [screen, setScreen] = useState("")
    const [comment, setComment] = useState("")

    const [loading, setLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)

    let action = props.action
    let id = props.selectedItem.id

    const [otp, setOtp] = useState("")
    const [token, setToken] = useState("")
    const [otpError, setOtpError] = useState(false)
    const [otpMessage, setOtpMessage] = useState("")

    const getOtp = () => {
        ApiService.fetch({
            url: "post-approval-request-action",
            options: {
                body: {

                }
            }
        }).then((data) => {
            if (data.status == true) {
                // console.log("data for get otp", data)
                setLoading(false)
                setShowPopup("")
                setScreen("getotpopup")
                setOtp(data.otp)
                setToken(data.token)
                toast.success(data.message, {
                    position: toast.POSITION.TOP_LEFT
                });
            }
            else {
                setLoading(false)
                toast.error(data.message, {
                    position: toast.POSITION.TOP_LEFT
                });
            }
        });
    }

    const approveRequest = () => {
        ApiService.fetch({
            url: "post-approval-request-action",
            options: {
                body: {
                    approval_request_id: id,
                    request_status: action,
                    comment: comment,
                    otp: otp,
                    token: token
                }
            }
        }).then((data) => {
            if (data.status == true) {
                // console.log("check status", data)
                history.push("/approvalrequest/" +action)
                // toast.success(data.message, {
                //     position: toast.POSITION.TOP_LEFT
                // });
                window.location.reload(false);
            }
            else {
                setSubmitLoading(false)
                setOtpError(true)
                setOtpMessage(data.message)
                // toast.error(data.message, {
                //     position: toast.POSITION.TOP_LEFT
                // });
            }
        });
    }

    return (
        <>
            <ToastContainer />
            <div id='Voucher_Details' className={`app-popup app-popup-footer-no app-popup-header-1 app-popup-offcanvas-end ${props.showPopup}`}>
                <div className='app-popup-container'>
                    <div className="app-popup-content app-popup-485px">
                        <div className="app-popup-header border-bottom py-0 d-flex align-items-center">
                            <div>
                                <h2 className='text-primary font-24px mb-1'>Approval Requests </h2>
                            </div>
                            <button className='app-popup-close'
                                onClick={() => {
                                    props.setShowPopup("app-popup-hide")
                                }}><svg width="12.191" height="12.191" viewBox="0 0 12.191 12.191">
                                    <path id="Icon_material-close" data-name="Icon material-close" d="M19.691,8.728,18.464,7.5,13.6,12.368,8.728,7.5,7.5,8.728,12.368,13.6,7.5,18.464l1.228,1.228L13.6,14.824l4.868,4.868,1.228-1.228L14.824,13.6Z" transform="translate(-7.5 -7.5)" />
                                </svg>
                            </button>
                        </div>
                        <div className="app-popup-body pb-0 font-14px app-popup-body-scroll">

                            <div className='row mb-2'>
                                <div className='col-xl-12 col-lg-12 col-md-12'>
                                    <h5 className="font-24px font-primary-semibold requestcolor">{subject}</h5>
                                </div>
                                <div className='col-xl-12 col-lg-12 col-md-12 mt-2'>
                                    <textarea
                                        placeholder="Start writing comment..."
                                        className='reuestarea'
                                        value={comment}
                                        onChange={(e) => {
                                            setComment(e.target.value)
                                        }}
                                    ></textarea>
                                </div>
                            </div>
                        </div>

                        <div className='req_footer'>
                            {!loading ?
                                <a className="otpbtn" onClick={() => {
                                    getOtp()
                                    setLoading(true)
                                }}>
                                    Get OTP to
                                    {action == "approved" && " Approval"}
                                    {action == "rejected" && " Reject"}
                                    {action == "hold" && " Hold"}
                                </a>
                                : <Loader />}
                            <label>You will get OTP on your phone number</label>
                        </div>

                    </div>
                </div>
            </div>

            {screen == "getotpopup" &&
                <div id='getotpopup' className={`app-popup ${showPopup}`}>
                    <div className='app-popup-container'>
                        <div className="app-popup-content app-popup-375px">
                            <div className="app-popup-header border-bottom">
                                <h2 className='text-primary font-20px-imp'>Verify your Identity</h2>

                                <button onClick={() => {
                                    setShowPopup("app-popup-hide")
                                }} className='app-popup-close'><svg width="12.191" height="12.191" viewBox="0 0 12.191 12.191">
                                        <path id="Icon_material-close" data-name="Icon material-close" d="M19.691,8.728,18.464,7.5,13.6,12.368,8.728,7.5,7.5,8.728,12.368,13.6,7.5,18.464l1.228,1.228L13.6,14.824l4.868,4.868,1.228-1.228L14.824,13.6Z" transform="translate(-7.5 -7.5)" />
                                    </svg>
                                </button>
                            </div>
                            <div className="app-popup-body app-popup-body-scroll">
                                <div className="filter-contentBox filterwid">
                                    <div className='verifydetails'>
                                        <h2>Enter the code we sent over SMS to</h2>
                                        <h6 className='font-primary-regular'>+254 {user?.phone_no}</h6>
                                        <input
                                            type="text"
                                            value={otp}
                                            placeholder="Enter OTP"
                                            name=""
                                            className="verifyinput"
                                            onChange={(e) => {
                                                setOtp(e.target.value)
                                            }} />
                                            {otpError && <span className="error">{otpMessage}</span>}
                                        {/* <label>Resend code in 00:30</label> */}
                                        <ApprovalResendOtp 
                                        setOtpError={setOtpError} 
                                        setToken={setToken} 
                                        setOtp={setOtp}
                                        />

                                            <button type="button" className="btn text-center justify-content-center btn-primary h-44px font-16px submitapprovbtn"
                                                onClick={() => {
                                                    // setSubmitLoading(true)
                                                    approveRequest()
                                                    setOtpError(false)
                                                }}>
                                                Submit & Approve
                                            </button>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
export default ApprovalReqStatus;