import moment from 'moment';
export default class Helper {

    static getCommaSepratedString(arr, key, showTitle) {
        let newCommaArr = [];
        let tempTitle  = "";
        arr.forEach((element) => {
            if (element[key]) {
                newCommaArr.push(element[key][showTitle]);
            }else{
                tempTitle = element[showTitle];
             
                newCommaArr.push(this.capitalize(tempTitle));
            }
            
        });
        //this.consoleLog(newCommaArr.join(', '));
        return newCommaArr.join(', ');
    }

    static consoleLog(data, type = '') {
        console.log(data, type);
    }

    static dateFormat(date, format ='LLL') {
        let d = '';
        if (date) {
            d = moment(date).format(format).toString();
        }
        return d;
       
    }
    
    static formatDay(day) { 
       
        if (day) {
           day = day[0].toUpperCase() + day.slice(1);
           day = day.substr(0,3)
        }
        return day;
    }

    static timeFormat(currentTime) { 
        let strTime = '';
        if (currentTime) {
            let time = currentTime.split(":");
           // this.consoleLog({ currentTime });
            let hours = time[0];
            let minutes = time[1];
            let second = time[2];
            hours = hours || 12;
            const ampm = hours >= 12 ? "PM" : "AM";
            minutes = minutes < 10 ? `${minutes}` : minutes;
            hours %= 12;
            if (hours == 0){
                hours =12
            }
            strTime = `${hours}:${minutes} ${ampm}`;
        }
        return strTime;
    }

    static capitalize(input) {
        return input.toLowerCase().split(' ').map(s => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
    }

    static truncateWords(sentence, amount, tail) {
        const words = sentence.split(' ');
        if (amount >= words.length) {
            return sentence;
        } 

        const truncated = words.slice(0, amount);
        
        return `${truncated.join(' ')}${tail}`;
    }

    // static getMyLocation() {
    //     const location = window.navigator && window.navigator.geolocation
    //     var locationState = {}
    //     if (location) {
    //         location.getCurrentPosition(function (position) {
                
    //             locationState = {
    //                 latitude: position.coords.latitude,
    //                 longitude: position.coords.longitude
    //             }; // you should set state when you have the values.
    //         }.bind(this));
    //         console.log(locationState)
    //         return '';
    //     }
       

    // }

    static getAccesstoken(){
        if(localStorage.getItem('access_token')){
            return true;
        }return false;
    }





}