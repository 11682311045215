import React from 'react';
import {CanvasJSChart} from 'canvasjs-react-charts'
import { chart as chartJS } from 'chart.js/auto'
import { Bar } from 'react-chartjs-2'
const InvestmentChart = (props) => {
    console.log("chartdata customer", props.data)
    let chart = props?.data

    let saledata = []
    let expensedata = []
    let profitdata = []
    // if(props.data.data != null){
    // for (const key in props.data.data) {
    //      let value= props.data.data[key]; 
    //     saledata.push({label : key, y : value.total_sale})
    //     expensedata.push({label : key, y : value.total_expense})
    //     profitdata.push({label : key, y : value.total_profit})
    //   }
    // }

    // console.log("arrayfirst", saledata,expensedata,profitdata)

    // const options = {
    //     axisY: {
    //         titleFontColor: "#4F81BC",
    //         lineColor: "#4F81BC",
    //         labelFontColor: "#4F81BC",
    //         tickColor: "#4F81BC"
    //     },
    //     data: [{
    //         type: "column",
    //         name: "Total Sale",
    //         legendText: "Total Sale",
    //         showInLegend: true, 
    //         dataPoints: saledata
    //     },
    //     {
    //         type: "column",
    //         name: "Total Expenses",
    //         legendText: "Total Expenses",
    //         showInLegend: true, 
    //         dataPoints: expensedata
    //     },
    //     {
    //         type: "column",	
    //         name: "Total Profit",
    //         legendText: "Total Profit",
    //         showInLegend: true,
    //         dataPoints: profitdata
    //     }]
    //  }

    let labels = []
    if (props.data.data != null) {
        for (const key in props.data.data) {
            let value = props.data.data[key];
            saledata.push(value.total_sale);
            expensedata.push(value.total_expense)
            profitdata.push(value.total_profit)
            labels.push(key)
        }
    }

    const data = {
        labels: labels,
        datasets: [
            {
                label: "Total Sale",
                data: saledata,
                backgroundColor: "#3366CC",
            },
            {
                label: "Total Expenses",
                data: expensedata,
                backgroundColor: "#EF626C"

            },
            {
                label: "Total Profit",
                data: profitdata,
                backgroundColor: "#33AF3B"

            }
        ]
    }

    var options = {

    };
    return (
        <>
            {/* <CanvasJSChart options = {options} width="10px"
        /> */}
            <Bar data={data} options={options}/>
        </>
    )
}
export default InvestmentChart;