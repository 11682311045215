import React, { useState } from 'react';
import Helper from '../../utils/helper';
import { useEffect } from 'react';
import { ApiService } from '../../services/ApiService';
import { Loader } from '../../Components/SvgIcons';
import { useHistory, useParams } from 'react-router-dom';
const MyDeliveryStatus = (props) => {
    let selectedItem = props.selectedItem
    console.log('delivery status', selectedItem)
    const [deliveryDetail, setDeliveryDetail] = useState({})
    const [firstStatus, setFirstStatus] = useState("")
    const params = useParams()
    const history = useHistory()
    useEffect(() => {
        if (params.slug) {
          
            ApiService.fetch({
                url: "delivery-detail",
                options: {
                    body: {
                        slug: params?.slug
                    }
                }
            }).then((data) => {
                if (data.status == true) {
                    
                    setDeliveryDetail(data.data)
                    setFirstStatus(data.data.get_delivery_statuses[0])
                }
            });
        }
    }, [params?.slug])

    return (
        <>
            <div id='Voucher_Details' className={`app-popup app-popup-footer-no app-popup-header-1 app-popup-offcanvas-end ${props.showPopup}`}>
                <div className='app-popup-container'>
                  
                        <div className="app-popup-content app-popup-600px">
                            <div className="app-popup-header border-bottom py-0 d-flex align-items-center">
                                <div>
                                    <h2 className='text-primary font-24px mb-1'>Delivery Status</h2>
                                </div>
                                <button className='app-popup-close'
                                    onClick={() => {
                                        history.push("/mydelivery")
                                        props.setShowPopup("app-popup-hide")

                                    }}><svg width="12.191" height="12.191" viewBox="0 0 12.191 12.191">
                                        <path id="Icon_material-close" data-name="Icon material-close" d="M19.691,8.728,18.464,7.5,13.6,12.368,8.728,7.5,7.5,8.728,12.368,13.6,7.5,18.464l1.228,1.228L13.6,14.824l4.868,4.868,1.228-1.228L14.824,13.6Z" transform="translate(-7.5 -7.5)" />
                                    </svg>
                                </button>
                            </div>
                            <div className="app-popup-body pb-0 font-14px app-popup-body-scroll">

                                <div className='row mb-3'>
                                    <div className='col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6'>
                                        <div className="mb-1 font-12px text-uppercase">Delivery Type</div>
                                        <h5 className="font-14px font-primary-semibold mb-2">{deliveryDetail?.delivery_type}</h5>
                                    </div>
                                    <div className='col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6'>
                                        <div className="mb-1 font-12px text-uppercase">Sender</div>
                                        <h5 className="font-14px font-primary-semibold mb-2">{deliveryDetail?.get_from_customer_detail?.name}</h5>
                                    </div>
                                    <div className='col-xl-4 col-lg-4 col-md-4 m-p-top'>
                                        <div className="mb-1 font-12px text-uppercase">Receiver</div>
                                        <h5 className="font-14px font-primary-semibold mb-2">{deliveryDetail?.get_to_customer_detail?.name}</h5>
                                    </div>
                                    <div className='col-xl-12 border-bottom-row'></div>

                                </div>

                                <div className='row pb-2'>
                                    <div className='col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6'>
                                        <div className="mb-1 font-12px text-uppercase">From</div>
                                        <h5 className="font-14px font-primary-semibold mb-2">{deliveryDetail?.get_from_branch_detail?.title}</h5>
                                    </div>
                                    <div className='col-xl-7 col-lg-7 col-md-7 col-sm-6 col-6'>
                                        <div className="mb-1 font-12px text-uppercase">TO</div>
                                        <h5 className="font-14px font-primary-semibold mb-2">{deliveryDetail?.get_to_branch_detail?.title}</h5>
                                    </div>
                                </div>


                                <div className='row mb-3'>
                                    <div className='col-xl-5 col-lg-5 col-md-5 col-sm-6'>
                                        <div className="mb-1 font-12px text-uppercase">Order No.</div>
                                        <h5 className="font-14px font-primary-semibold mb-2">#{deliveryDetail?.order_id}</h5>
                                    </div>
                                    <div className='col-xl-7 col-lg-7 col-md-7 col-sm-6 m-p-top'>
                                        <div className="mb-1 font-12px text-uppercase">Delivery Booking Date & Time</div>
                                        <h5 className="font-14px font-primary-semibold mb-2">{Helper.dateFormat(deliveryDetail?.get_order_detail?.created_at)}</h5>
                                    </div>

                                </div>

                                <div className='row mb-3'>
                                    <div className='col-xl-5 col-lg-5 col-md-5'>
                                        <div className="mb-1 font-12px text-uppercase">Touch Point</div>
                                        <h5 className="font-14px font-primary-semibold mb-2">{deliveryDetail?.get_touch_point?.title}</h5>
                                    </div>
                                    {selectedItem?.estimated_delivery_date &&
                                        <div className='col-xl-7 col-lg-7 col-md-7'>
                                            <div className="mb-1 font-12px text-uppercase">Estimated Delivery Date & Time</div>
                                            <h5 className="font-14px font-primary-semibold mb-2">{Helper.dateFormat(deliveryDetail?.estimated_delivery_date)}</h5>
                                        </div>
                                    }

                                </div>

                                <div className='row mb-3'>
                                    <div className='col-xl-12'>
                                        <div className="mb-1 font-12px text-uppercase">Last Update</div>
                                        <h5 className="font-14px font-primary-semibold statustxt mb-2"><span>{deliveryDetail?.get_delivery_status_detail?.title} </span> - {Helper.dateFormat(deliveryDetail?.updated_at)}</h5>
                                        <div className='dellist'>
                                            <ul>
                                                {deliveryDetail?.get_delivery_images?.map((item, index) => {
                                                    return <li key={index}>
                                                        <a href="#" onClick={() => {
                                                            props.setShowPopup("app-popup-hide")
                                                        }}><img className="me-2 inveimg" height="50px" src={item?.image} /></a>
                                                    </li>
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>


                                <div className='delivery-box-border'>
                                    <div className='deliveryhead pb-0'>
                                        <div className='statusdelver statusdelver_overflow'>
                                            <ul>
                                                {deliveryDetail?.get_delivery_statuses?.map((item, index) => {
                                                    return <li key={index}>{item?.get_delivery_status_detail?.type}</li>
                                                })}
                                                {/* <li>Dispatched</li>
                                            <li>In Transit</li>
                                            <li>Delivered</li> */}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className='statusdetails'>
                                        <div className='row'>
                                            <div className='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 padr'>
                                                <div className='datebox'>
                                                    <ul>
                                                        <li>
                                                            <div className='datelists'>
                                                                <span>{Helper.dateFormat(firstStatus?.updated_at).slice(0, -8)}</span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10'>
                                                <div className='date_status'>
                                                    <ul>
                                                        <li>
                                                            <div className='del_box_status delcover'>
                                                                <div className='row'>
                                                                    <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6'>
                                                                        <div className="mb-1 font-10px text-uppercase">Status</div>
                                                                        <h5 className="font-12px font-primary-semibold mb-2">{firstStatus?.get_delivery_status_detail?.type}</h5>
                                                                    </div>
                                                                    <div className='col-xl-8 col-lg-8 col-md-6 col-sm-6 col-6'>
                                                                        <div className="mb-1 font-10px text-uppercase">Time</div>
                                                                        <h5 className="font-12px font-primary-semibold mb-2">{Helper.dateFormat(firstStatus?.updated_at).slice(-8)}</h5>
                                                                    </div>
                                                                </div>
                                                                {/* <div className='row'>
                                                                <div className='col-xl-12'>
                                                                    <div className="mb-1 font-10px text-uppercase">Remark</div>
                                                                    <h5 className="font-12px font-primary-semibold mb-2">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore</h5>
                                                                </div>
                                                            </div> */}
                                                            </div>
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        {deliveryDetail?.get_delivery_statuses?.slice(1, deliveryDetail?.get_delivery_statuses?.length)?.map((item, index) => {
                                            return <div className='row' key={index}>
                                                <div className='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 padr'>
                                                    <div className='datebox'>
                                                        <ul>
                                                            <li>
                                                                <div className='datelists'>
                                                                    <span>{Helper.dateFormat(item?.updated_at).slice(0, -8)}</span>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className='col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10'>
                                                    <div className='date_status'>
                                                        <ul>
                                                            <li>
                                                                <div className='del_box_status'>
                                                                    <div className='row'>
                                                                        <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6'>
                                                                            <div className="mb-1 font-10px text-uppercase">Status</div>
                                                                            <h5 className="font-12px font-primary-semibold mb-2">{item?.get_delivery_status_detail?.type}</h5>
                                                                        </div>
                                                                        <div className='col-xl-8 col-lg-8 col-md-6 col-sm-6 col-6'>
                                                                            <div className="mb-1 font-10px text-uppercase">Time</div>
                                                                            <h5 className="font-12px font-primary-semibold mb-2">{Helper.dateFormat(item?.updated_at).slice(-8)}</h5>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className='row'>
                                                                <div className='col-xl-12'>
                                                                    <div className="mb-1 font-10px text-uppercase">Remark</div>
                                                                    <h5 className="font-12px font-primary-semibold mb-2">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore</h5>
                                                                </div>
                                                            </div> */}
                                                                </div>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                        {/* <div className='row'>
                                        <div className='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 padr'>
                                            <div className='datebox'>
                                                <ul>
                                                    <li>
                                                        <div className='datelists'>
                                                            <span>30 Mar</span>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10'>
                                            <div className='date_status'>
                                                <ul>
                                                    <li>
                                                        <div className='del_box_status'>
                                                            <div className='row'>
                                                                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6'>
                                                                    <div className="mb-1 font-10px text-uppercase">Status</div>
                                                                    <h5 className="font-12px font-primary-semibold mb-2">Delivered</h5>
                                                                </div>
                                                                <div className='col-xl-8 col-lg-8 col-md-6 col-sm-6 col-6'>
                                                                    <div className="mb-1 font-10px text-uppercase">Time</div>
                                                                    <h5 className="font-12px font-primary-semibold mb-2">04:00 PM</h5>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-xl-12'>
                                                                    <div className="mb-1 font-10px text-uppercase">Remark</div>
                                                                    <h5 className="font-12px font-primary-semibold mb-2">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='row'>
                                        <div className='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 padr'>
                                            <div className='datebox'>
                                                <ul>
                                                    <li>
                                                        <div className='datelists'>
                                                            <span>30 Mar</span>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className='col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10'>
                                            <div className='date_status'>
                                                <ul>
                                                    <li>
                                                        <div className='del_box_status'>
                                                            <div className='row'>
                                                                <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6'>
                                                                    <div className="mb-1 font-10px text-uppercase">Status</div>
                                                                    <h5 className="font-12px font-primary-semibold mb-2">Delivered</h5>
                                                                </div>
                                                                <div className='col-xl-8 col-lg-8 col-md-6 col-sm-6 col-6'>
                                                                    <div className="mb-1 font-10px text-uppercase">Time</div>
                                                                    <h5 className="font-12px font-primary-semibold mb-2">04:00 PM</h5>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-xl-12'>
                                                                    <div className="mb-1 font-10px text-uppercase">Remark</div>
                                                                    <h5 className="font-12px font-primary-semibold mb-2">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                    </div> */}
                                    </div>
                                </div>
                                <div className='codestrip'>
                                    <a href="#"><img height="200px" className="me-2" src={deliveryDetail?.get_touch_point?.qr_code} /></a>
                                </div>
                            </div>

                        </div>
                    
                </div>
            </div>
        </>
    )
}
export default MyDeliveryStatus;