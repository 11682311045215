import React from 'react';
import { useState } from 'react';
import { ApiService } from '../services/ApiService';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
const ForgotPassword = (props) => {
    const [form, setForm] = useState({
        email: "",
        error: false,
        loading: false,
    })
    let emailPattern = "[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"

    const forgotPasswordApi = () => {
        setForm({...form, error : true})
        if(form?.email?.match(emailPattern)){
            setForm({ ...form, loading: true })
        ApiService.forgotPassword({ email: form?.email }).then((data) => {
            if (data.status) {
                setForm({ ...form, loading: false })
                toast.success(data.message)
                props.setOpenLogin('app-popup-hide')
                props.setScreen('reset-password')
                props.token(data?.token)
            } else {
                toast.error(data.message)
                // alert(data.message)
                setForm({ ...form, loading: false })
            }
        })
            .catch(err => {
                setForm({ ...form, loading: false })
            })
        }
    }

    const handleKeypress = e => {
        if (e.keyCode === 13) {
            forgotPasswordApi();
        }
    }

    return(
        <>
        <div id='LoginPopup'
                className={`app-popup`}
            // className={props.className}
            >
                <div className='app-popup-container popup-scroll-view'>
                    <div className="app-popup-content app-popup-430px login-popups">
                        <div className="app-popup-header border-bottom">
                            <h2 className='text-primary font-22px'>Forgot Password</h2>

                            <button
                                onClick={() => {
                                    props.setScreen("")
                                }}
                                className='app-popup-close'><svg width="12.191" height="12.191" viewBox="0 0 12.191 12.191" >
                                    <path id="Icon_material-close" data-name="Icon material-close" d="M19.691,8.728,18.464,7.5,13.6,12.368,8.728,7.5,7.5,8.728,12.368,13.6,7.5,18.464l1.228,1.228L13.6,14.824l4.868,4.868,1.228-1.228L14.824,13.6Z" transform="translate(-7.5 -7.5)" />
                                </svg>
                            </button>
                        </div>

                        <div className="app-popup-body pb-0 pt-3 font-14px app-popup-body-scroll">
                            {/* <h6 className='font-18px mb-2 font-primary-semibold'>Continue with Email</h6> */}
                            <p className='mb-2'>Enter your registered email id and we will send you an OTP to reset your password.</p>
                            <div onKeyDown={handleKeypress}>
                              
                                <div className="form-floating form-floating-56px mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInput"
                                        placeholder="Enter Email"
                                        value={form?.email}
                                        onChange={(e) => {
                                            setForm({
                                                ...form, email: e.target.value
                                            })
                                        }}
                                    />
                                    <label htmlFor="floatingInput">Enter Email</label>
                                    {(form.email == '' && form.error) ? <p className='error'>This field is required</p>
                                        : (!form.email.match(emailPattern) && form.error) ? <p className='error'>Enter a valid email address</p> : ''}
                                </div>
                             
                            </div>


                        </div>
                        <div className="app-popup-footer pb-4">
                            <div className="">
                                {!form?.loading ?
                                    <div>
                                        <button type="button" id="myBtn" className="btn btn-primary w-100 h-44px font-18px text-center justify-content-center mb-2"
                                            onClick={(e) => {
                                                // props?.setScreen('reset-password')
                                                forgotPasswordApi()
                                            }}
                                        >Request Otp</button></div>
                                    :
                                    <div className="d-flex justify-content-center">
                                        <svg className="loader d-flex justify-content-center" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
                                        </svg></div>
                                }
                                {/* <div className='font-12px text-grey-dark'>An OTP will be sent to your mobile number for verification</div> */}
                            </div>
                            <div className='end-section text-center font-16px line-height-140 pt-2'>
                                Back to <a href='#' className='link-primary font-primary-semibold'
                                    onClick={() => {
                                        props?.setScreen('')
                                        props?.setOpenLogin('')
                                    }}>Sign in</a>
                            </div>
                        </div>


                    </div>
                </div>

            </div>
        </>
    )
}
export default ForgotPassword;