export class LocationService {

    STORAGE_KEY     = "location";
    STORAGE_TIMEOUT = 60 * 1000 * 30;
    GOOGLE_API_KEY  = 'AIzaSyDKRW6UcDoITftfJ0hCkU3MNOCQozOub1k';
    GOOGLE_URL      = 'https://maps.googleapis.com/maps/api/geocode/json';

    //set the stored location with expiry date
    setStorageLocation(location) {
        location = this.formatResponse(location);
        if (location) {
            let date = new Date();
            date.setTime(date.getTime() + (this.STORAGE_TIMEOUT));
            location.expiry = date.getTime()
            location = JSON.stringify(location);
            window.localStorage.setItem(this.STORAGE_KEY, location);
        }

    }
    //get the stored location with expiry date
    getStorageLocation() {
        let location = window.localStorage.getItem(this.STORAGE_KEY);
        if (location) {
            location = JSON.parse(location);
            if (location.expiry < new Date().getTime()) {
                return false;
            }
            return this.formatResponse(location);
        }
        else {
            return false;
        }
    }
    //format response before handle it
    formatResponse(location) {
        return {
            location: location.location,
            lat: location.lat,
            lng: location.lng,
            expiry: location.expiry || new Date(),
        }
    }
     //format error before handle it
    formatError(err) {
        return err;
    }
     //get current location using navigator api and google location api
    getCurrentLocation(success, error) {

        if (this.getStorageLocation()) {
            success(this.getStorageLocation());
        }
        else {
            this.getNavigatorLocation((position) => {
                this.getAddress(position.coords.latitude, position.coords.longitude).then(data => {
                    this.setStorageLocation({
                        location: data.formatted_address,
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    });
                    success(this.getStorageLocation());
                }).catch(err => {
                    error(this.formatError(err))
                })
            }, (err) => {
                error(this.formatError(err))
            })
        }

    }

    getNavigatorLocation(succes, error) {
        navigator.geolocation.getCurrentPosition((position) => {

            this.getAddress(position.coords.latitude, position.coords.longitude).then(data => {
                succes(position)
            })
            .catch(err => {
                    error(err);
            });
            console.log("Latitude is :", position.coords.latitude);
            console.log("Longitude is :", position.coords.longitude);
        }, (err) => {
            console.log("location.showPicker Error catch", err)
            error(err)
        }, {
            timeout: 5000,
            maximumAge: 60 * 1000 * 5
        });
    }

    getAddress(lat, long) {
        var onComplete = fetch(
            this.GOOGLE_URL + "?key=" + this.GOOGLE_API_KEY + "&latlng=" + lat + "," + long,
            {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" }
            });
        return onComplete.then(response => response.json())
                         .then(res => res.results[0])

    }



}
