import React, { useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from 'react-redux';
import { ApiService } from '../../services/ApiService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Autocomplete from 'react-autocomplete';
import Terms from './Terms';
import { useEffect } from 'react';
import { Loader } from '../../Components/SvgIcons';
import DateTime from '../../library/DateTime';
const BuyMemberShip = (props) => {
    const user = useSelector(state => state.user.user)
    // console.log("membershipdata for buy", props)
    let currency = "KES"
    const [form, setForm] = useState({
        otherPersonName: "",
        otherPersonPhone: "",
        otherPersonEmail: "",
        messageData: "",
        otherPerson: "0",
        message: "0",
        notify: "0",
        agree: "0",
    })

    const [error, setError] = useState({
        name: false,
        phone: false,
        email: false,
        message: false,
        agree: false
    })

    let phonePattern = /^\d(?:[- ]*\d){6,15}$/
    let emailPattern = "[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"

    const [screen, setScreen] = useState("")
    const [showPopup, setShowPopup] = useState("app-popup-hide")

    const [loading, setLoading] = useState(false)

    let dateformat = new Date()
    const [activeDate, setActivedate] = useState(dateformat.getFullYear() + '-' + (dateformat.getMonth() + 1) + '-' + dateformat.getDate())

    const checkActive = () => {
        ApiService.fetch({
            url: "check-active-membership",
            options: {
                body: {
                    membership_id: props?.data?.id,
                    activation_date: activeDate,
                    buy_for_someone: form.otherPerson,
                    phone_no: form.otherPersonPhone,
                    email: form.otherPersonEmail
                }
            }
        }).then((data) => {
            if (data.status == true) {
                setLoading(false)
                buyMemmberShip()
            } else {
                setLoading(false)
                toast.error(data.message, {
                    position: toast.POSITION.TOP_LEFT
                });
            }
        });
    }

    const buyMemmberShip = () => {
        ApiService.fetch({
            url: "buy-membership",
            options: {
                body: {
                    membership_id: props?.data?.id,
                    activation_date: activeDate,
                    buy_for_someone: form.otherPerson,
                    is_message: form.message,
                    name: form.otherPersonName,
                    phone_no: form.otherPersonPhone,
                    email: form.otherPersonEmail,
                    message: form.messageData,
                }
            }
        }).then((data) => {
            if (data.status == true) {
                lollapay(data.data.amount, data.data.lollapay_token, data.data.slug)
                props.setShowPopup("app-popup-hide")
            } else {
                toast.error(data.message, {
                    position: toast.POSITION.TOP_LEFT
                });
            }
        });
    }

    const payProcess = () => {
        setError({
            name: form.otherPersonName == "" ? true : false,
            phone: !form.otherPersonPhone.match(phonePattern) ? true : false,
            email: !form.otherPersonEmail.match(emailPattern) ? true : false,
            message: form.messageData == "" ? true : false,
            agree: form.agree == "0" ? true : false
        })

        if (form.agree == "1" && activeDate != null) {
            if (form.otherPerson == "1") {
                if (form.otherPersonName != "" && form.otherPersonPhone.match(phonePattern) && form.otherPersonEmail.match(emailPattern)) {
                    if (form.message == "1") {
                        if (form.messageData != "") {
                            setLoading(true)
                            checkActive()
                        }
                    } else {
                        setLoading(true)
                        checkActive()
                    }
                }
            }
            else {
                setLoading(true)
                checkActive()
            }
        }
    }

    let lollapayScript = document.createElement('script')
    lollapayScript.setAttribute('src', 'https://lollapay.com/widget/lollapay-widget.js')
    document.head.appendChild(lollapayScript)

    function lollapay(amount, token, slug) {
        if (!token) {
            toast.error("Lollapay not setup", {
                position: toast.POSITION.TOP_LEFT
            });
            return 1;
        }
        var amount = amount
        var options = {
            "marchant_name": "Delytt Payment",
            "client_key": "xvI97CPenFuYMEKG",
            "prefill": {
                "name": user.name,
                "phone_no": user.phone_no
            },
            "currency": "KES",
            "amount": amount,
            "refID": slug,
            "qrToken": token,
            "theme": "#E5002D",
            "sub_account_email": "mealinityweb@yopmail.com",
            "logo": "https://delytt.com/public/assets/img/logo.png",
        };
        var LP = new window.LollaPay(options);
        LP.open();
        LP.onSuccess((data) => {
            checkPayment(data.data.response_id, slug)
        });
        LP.onError((data) => {
            // console.log("final error pay", data);
            this.notifcation("There is some problem while proccessing your payment", "error")
        });
        LP.onCancel((data) => {
            // console.log("final cancel pay", data);
        });

    }

    const checkPayment = (responseId, slug) => {
        ApiService.fetch({
            url: "check-purchased-payment-status",
            options: {
                body:
                {
                    response_id: responseId,
                    slug: slug
                }
            }
        }).then((data) => {
            if (data.status == true) {
                toast.success(data.message, {
                    position: toast.POSITION.TOP_LEFT
                });
                window.location.reload(false);
            } else {
                toast.error(data.message, {
                    position: toast.POSITION.TOP_LEFT
                });
            }
        });
    }

    return (
        <>
            <ToastContainer />
            <div id='Buy_Membership' className={`app-popup app-popup-footer-no app-popup-header-1 app-popup-offcanvas-end ${props.showPopup}`}>
                <div className='app-popup-container'>
                    <div className="app-popup-content app-popup-485px">
                        <div className="app-popup-header border-bottom py-0 d-flex align-items-center">
                            <div>
                                <h2 className='text-primary font-22px mb-1'>Buy Membership</h2>
                            </div>
                            <button className='app-popup-close'
                                onClick={() => {
                                    props.setShowPopup("app-popup-hide")
                                }}
                            ><svg width="12.191" height="12.191" viewBox="0 0 12.191 12.191">
                                    <path id="Icon_material-close" data-name="Icon material-close" d="M19.691,8.728,18.464,7.5,13.6,12.368,8.728,7.5,7.5,8.728,12.368,13.6,7.5,18.464l1.228,1.228L13.6,14.824l4.868,4.868,1.228-1.228L14.824,13.6Z" transform="translate(-7.5 -7.5)" />
                                </svg>
                            </button>
                        </div>
                        <div className="app-popup-body pb-0 font-14px app-popup-body-scroll">

                            <div className='mb-4'>
                                <div className="mb-1 font-16px">Membership</div>
                                <h5 className="font-24px font-primary-bold mb-2">{props?.data?.name}</h5>
                            </div>

                            <div className='mb-4'>
                                <div className="mb-1 font-16px">Business</div>
                                <h5 className="font-24px font-primary-bold mb-2">{props?.data?.get_business?.name}</h5>
                            </div>

                            <div className='border-bottom border-top pb-3'>
                                {/* <div>
                                    <div className='d-flex justify-content-between mt-3'>
                                        <div className="font-16px text-center">Discount</div>
                                        <div className='font-18px-imp text-end flex-grow-1'>KSH 15,000</div>
                                        <div>
                                        </div>
                                    </div>

                                    <div className='d-flex justify-content-between mt-3'>
                                        <div className="font-16px text-center">Base Membership Fee</div>
                                        <div className='font-18px-imp text-end flex-grow-1'>KSH 1,000</div>
                                        <div>
                                        </div>
                                    </div>
                                </div> */}

                                <div className='d-flex justify-content-between pt-3 font-primary-bold mt-3'>
                                    <div className="font-16px text-center">Payable</div>
                                    <div className='font-18px-imp text-end flex-grow-1'>{currency} {props?.data?.price}</div>
                                    <div>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-3 mb-2'>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={form.otherPerson == "1"}
                                        id="flexCheckDefault"
                                        onChange={() => {
                                            setForm({ ...form, otherPerson: form.otherPerson == "0" ? "1" : "0" })
                                        }}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        I am buying for someone else
                                    </label>
                                </div>
                            </div>

                            {/* checked content start */}
                            {form.otherPerson == "1" &&
                                <>
                                    <div className='someoneElse_info pt-2'>

                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label font-primary-semibold font-16px">
                                                Phone Number
                                            </label>
                                            <div className="input-group mb-2">
                                                <span className="input-group-text bg-transparent px-3" id="basic-addon1">
                                                    +254
                                                </span>
                                                <input type="text" className="form-select h-50px"
                                                    placeholder={form.otherPersonPhone}
                                                    onChange={(e) => {
                                                        setForm({ ...form, otherPersonPhone: e.target.value })
                                                    }} />

                                            </div>
                                            {(error.phone && !form.otherPersonPhone.match(phonePattern)) && <span className="error">Enter a valid phone number</span>}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label font-primary-semibold font-16px">
                                                Full Name
                                            </label>
                                            <input
                                                value={form.otherPersonName}
                                                type="text"
                                                className="form-control h-50px mb-2"
                                                id="exampleFormControlInput1"
                                                onChange={(e) => {
                                                    setForm({
                                                        ...form,
                                                        otherPersonName: e.target.value
                                                    })
                                                }}
                                            />
                                            {(error.name && form.otherPersonName == "") && <span className="error">This field is required</span>}
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label font-primary-semibold font-16px">
                                                Email
                                            </label>
                                            <input
                                                type="email"
                                                value={form.otherPersonEmail}
                                                className="form-control h-50px mb-2"
                                                id="exampleFormControlInput1"
                                                onChange={(e) => {
                                                    setForm({ ...form, otherPersonEmail: e.target.value })
                                                }}
                                            />
                                            {(error.email && !form.otherPersonEmail.match(emailPattern)) && <span className="error">Enter a valid email</span>}
                                        </div>

                                        <div className="mb-3">
                                            <div className="form-check form-switch mb-2 d-flex align-items-center">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    role="switch"
                                                    id="flexSwitchCheckChecked"
                                                    checked={form.message == "1"}
                                                    onChange={() => {
                                                        setForm({ ...form, message: form.message == "0" ? "1" : "0" })
                                                    }}
                                                />
                                                <label className="form-check-label font-16px font-primary-semibold" htmlFor="flexSwitchCheckChecked">
                                                    Add personal message
                                                </label>
                                            </div>
                                            {form.message == "1" &&
                                                <textarea
                                                    value={form.messageData}
                                                    className="form-control font-14px textarea_h_130 p-3 mb-2"
                                                    onChange={(e) => {
                                                        setForm({ ...form, messageData: e.target.value })
                                                    }}
                                                />
                                            }
                                            {(error.message && form.messageData == "" && form.message == "1") && <span className="error">This field is required</span>}
                                        </div>
                                    </div>
                                </>
                            }

                            <div className="mb-3">
                                <label htmlFor="exampleFormControlInput1" className="form-label font-primary-semibold font-16px">
                                    Set Activation Date
                                </label>
                                <div className="input-group mb-3 date-row-full">
                                        <DateTime className="cele-icons" activeDate={activeDate} type="membershipActivedate" setActivedate={setActivedate} selectedItem={props.data} />
                                    Max {props.data.max_activation_value} {props.data.max_activation_type} ahead from the date of purchase

                                </div>
                            </div>

                            <div className=''>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultValue=""
                                        id="flexCheckDefault2"
                                        checked={form.agree == "1"}
                                        onChange={() => {
                                            setForm({ ...form, agree: form.agree == "0" ? "1" : "0" })
                                        }}
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault2">
                                        I agree to
                                        <a className="link-primary text-decoration-underline ms-1"
                                            onClick={() => {
                                                setScreen("terms")
                                                setShowPopup("")
                                            }}>
                                            terms and conditions
                                        </a>
                                    </label>
                                </div>
                                {(form.agree == "0" && error.agree) && <span className="error">Please accept terms and conditions</span>}
                            </div>
                            <div className='mb-4'>
                               {loading ?
                               <Loader />
                            : <a className="btn btn-primary justify-content-center h-44px w-100 me-3 mt-3" onClick={() => {
                                payProcess()
                            }}>Proceed to Pay</a> }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Terms and Conditions Popup start */}
            {screen == "terms" &&
                <Terms showPopup={showPopup} setShowPopup={setShowPopup} terms={props.data} />
            }
            {/* Terms and Conditions Popup End */}
        </>
    )
}
export default BuyMemberShip;