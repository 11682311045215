import React from 'react';
import { ApiService } from '../../services/ApiService';
import { useState } from 'react';
const Products = (props) => {
    const branchData = props.data
    const [loading, setLoading] = useState(false)
    return (
        <div className='product-iframe'>
            <iframe src={ApiService.config.url + 'menus?token=' + branchData.get_booking_touchpoint.qr_code_token + '&show_header_footer=1'} width="100%" height="800"></iframe>
            {/* <iframe src={"http://localhost:3001/mealinity/" + 'menus?token=' + branchData.get_booking_touchpoint.qr_code_token +'&show_header_footer=1'} width="100%" height="800"></iframe> */}
        </div>
    )
}
export default Products;