import React from 'react';
const MobileViewDelivery = () => {
    return(
       <div className='delivery_mid'>
            <div className='delivery_mobile_page'>
                <div className='delivery_mobile_page'>
                    <div className='header_del'>
                        <a href="#"><img className="me-2" src={require("../../assets/img/logo.png")} /></a>
                    </div>
                    <div className='delivery_content'>

                        <div className='deliveryinfomation'>
                            <div className='row pb-2'>
                                <div className='col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6'>
                                    <div className="mb-1 font-12px text-uppercase">Sender</div>
                                    <h5 className="font-14px font-primary-semibold mb-2">Crooks Yvette </h5>
                                </div>
                                <div className='col-xl-7 col-lg-7 col-md-7 col-sm-6 col-6'>
                                    <div className="mb-1 font-12px text-uppercase">Receiver</div>
                                    <h5 className="font-14px font-primary-semibold mb-2">Bins Ilene</h5>
                                </div>
                            </div>

                            <div className='row mb-2'>
                                <div className='col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6'>
                                    <div className="mb-1 font-12px text-uppercase">Order No.</div>
                                        <h5 className="font-14px font-primary-semibold mb-2">#4360325588236</h5>
                                    </div>
                                    <div className='col-xl-7 col-lg-7 col-md-7 col-sm-6 col-6'>
                                        <div className="mb-1 font-12px text-uppercase">Bus NO.</div>
                                        <h5 className="font-14px font-primary-semibold mb-2">KCV - 931D</h5>
                                    </div>
                            </div>

                            <div className='row mb-2'>
                                <div className='col-xl-12'>
                                    <div className="mb-1 font-12px text-uppercase">Last Update</div>
                                    <h5 className="font-14px font-primary-semibold statustxt mb-2"><span>Delivered </span> - 30 March 2023, 8:00 PM</h5>
                                </div>
                            </div>

                        </div>

                        <div className='bookdeliveryinfo mt-3 pb-3'>
                            <div className='deliveryhead'>
                                <div className='statusdelver'>
                                    <ul>
                                        <li>Booked</li>
                                        <li>Dispatched</li>
                                        <li>In Transit</li>
                                        <li>Delivered</li>
                                    </ul>
                                </div>
                            </div>

                            <div className='statusdetails padlr'>
                                        <div className='row pad_row_del'>
                                            <div className='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 padr'>
                                                <div className='datebox'>
                                                    <ul>
                                                        <li>
                                                        <div className='datelists'>
                                                            <span>30 Mar</span>
                                                        </div>
                                                        </li>
                                                    </ul>
                                                </div>                                                
                                            </div>
                                            <div className='col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10'>
                                                <div className='date_status'>
                                                    <ul>
                                                        <li>
                                                            <div className='del_box_status delcover'>
                                                                <div className='row'>
                                                                    <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6'>
                                                                        <div className="mb-1 font-10px text-uppercase">Status</div>
                                                                        <h5 className="font-12px font-primary-semibold mb-2">Delivered</h5>
                                                                    </div>
                                                                    <div className='col-xl-8 col-lg-8 col-md-6 col-sm-6 col-6'>
                                                                        <div className="mb-1 font-10px text-uppercase">Time</div>
                                                                        <h5 className="font-12px font-primary-semibold mb-2">04:00 PM</h5>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-xl-12'>
                                                                        <div className="mb-1 font-10px text-uppercase">Remark</div>
                                                                        <h5 className="font-12px font-primary-semibold mb-2">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore</h5>
                                                                    </div>                                                                    
                                                                </div>
                                                            </div>
                                                        </li>
                                                        
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className='viewdetailsbtns'>
                                            <a href='#' className='viewinfobtn'>View Detail</a>
                                        </div>

                                        <div className='row pad_row_del del_less del_extent'>
                                            <div className='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 padr'>
                                                <div className='datebox'>
                                                    <ul>
                                                        <li>
                                                        <div className='datelists'>
                                                            <span>30 Mar</span>
                                                        </div>
                                                        </li>
                                                    </ul>
                                                </div>                                                
                                            </div>
                                            <div className='col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10'>
                                                <div className='date_status'>
                                                    <ul>
                                                        <li>
                                                            <div className='del_box_status'>
                                                                <div className='row'>
                                                                    <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6'>
                                                                        <div className="mb-1 font-10px text-uppercase">Status</div>
                                                                        <h5 className="font-12px font-primary-semibold mb-2">Delivered</h5>
                                                                    </div>
                                                                    <div className='col-xl-8 col-lg-8 col-md-6 col-sm-6 col-6'>
                                                                        <div className="mb-1 font-10px text-uppercase">Time</div>
                                                                        <h5 className="font-12px font-primary-semibold mb-2">04:00 PM</h5>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-xl-12'>
                                                                        <div className="mb-1 font-10px text-uppercase">Remark</div>
                                                                        <h5 className="font-12px font-primary-semibold mb-2">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore</h5>
                                                                    </div>                                                                    
                                                                </div>
                                                            </div>
                                                        </li>
                                                        
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row pad_row_del del_less'>
                                            <div className='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 padr'>
                                                <div className='datebox'>
                                                    <ul>
                                                        <li>
                                                        <div className='datelists'>
                                                            <span>30 Mar</span>
                                                        </div>
                                                        </li>
                                                    </ul>
                                                </div>                                                
                                            </div>
                                            <div className='col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10'>
                                                <div className='date_status'>
                                                    <ul>
                                                        <li>
                                                            <div className='del_box_status'>
                                                                <div className='row'>
                                                                    <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6'>
                                                                        <div className="mb-1 font-10px text-uppercase">Status</div>
                                                                        <h5 className="font-12px font-primary-semibold mb-2">Delivered</h5>
                                                                    </div>
                                                                    <div className='col-xl-8 col-lg-8 col-md-6 col-sm-6 col-6'>
                                                                        <div className="mb-1 font-10px text-uppercase">Time</div>
                                                                        <h5 className="font-12px font-primary-semibold mb-2">04:00 PM</h5>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-xl-12'>
                                                                        <div className="mb-1 font-10px text-uppercase">Remark</div>
                                                                        <h5 className="font-12px font-primary-semibold mb-2">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore</h5>
                                                                    </div>                                                                    
                                                                </div>
                                                            </div>
                                                        </li>
                                                        
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>


                                        <div className='row pad_row_del del_less'>
                                            <div className='col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 padr'>
                                                <div className='datebox'>
                                                    <ul>
                                                        <li>
                                                        <div className='datelists'>
                                                            <span>30 Mar</span>
                                                        </div>
                                                        </li>
                                                    </ul>
                                                </div>                                                
                                            </div>
                                            <div className='col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10'>
                                                <div className='date_status'>
                                                    <ul>
                                                        <li>
                                                            <div className='del_box_status'>
                                                                <div className='row'>
                                                                    <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6'>
                                                                        <div className="mb-1 font-10px text-uppercase">Status</div>
                                                                        <h5 className="font-12px font-primary-semibold mb-2">Delivered</h5>
                                                                    </div>
                                                                    <div className='col-xl-8 col-lg-8 col-md-6 col-sm-6 col-6'>
                                                                        <div className="mb-1 font-10px text-uppercase">Time</div>
                                                                        <h5 className="font-12px font-primary-semibold mb-2">04:00 PM</h5>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-xl-12'>
                                                                        <div className="mb-1 font-10px text-uppercase">Remark</div>
                                                                        <h5 className="font-12px font-primary-semibold mb-2">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore</h5>
                                                                    </div>                                                                    
                                                                </div>
                                                            </div>
                                                        </li>
                                                        
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        </div>






                        


                                
                    </div>
                </div>
            </div>
       </div>
    )
}
export default MobileViewDelivery;