import React from 'react';

import { useSelector } from 'react-redux';
import { GoogleMap, useJsApiLoader, Marker, LoadScript } from '@react-google-maps/api';


export const ShowMap = (props) => {
    let branchData = props.data;
    //console.log(branchData)

    let center = {
        //lat: -3.745,
       // lng: -38.523
    };

    if (branchData) {
        if (branchData.location_lat != 'undefined' && branchData.location_long != 'undefined') {
            center = {
                lat: parseFloat(branchData.location_lat),
                lng: parseFloat(branchData.location_long)
            };
        }
    }
    return <LoadScript
        googleMapsApiKey='AIzaSyABfM-cU_7enQcQBNIUHsXSkdM62PF2Phc'>
        <GoogleMap
            mapContainerStyle={{
                width: '100%',
                height: '100%'
            }}
            zoom={13}
            center={center}>
            <Marker position={center} />
        </GoogleMap>
    </LoadScript>;
        
}