import React from 'react';
const Pagination = (props) => {
let data = props.data
let page = props.page
let setPage = props.setPage
    let pages = []
    for (let i = 1; i <= data?.last_page; i++) {
        pages.push(i)
    }

    return(
        <>
        <div className='paging-section'>
                            <a
                                className="link-default font-14px font-primary-bold text-decoration-underline mb-3 mb-sm-0"
                                onClick={() => {
                                    if (page > 1) {
                                        setPage(page - 1)
                                    }
                                }}
                            >
                                PREVIOUS
                            </a>
                            {(page > 2 ? pages?.slice(page - 3, page + 2) : pages?.slice(0, 5))?.map((item) => {
                                return <span className={`ms-2 cursor-pointer ${item == page && "activepage"}`} onClick={() => { setPage(item) }}>{item}</span>
                            })
                            }
                            {/* {page + 2 < data?.last_page && "..."} */}
                            <a
                                className="link-default font-14px font-primary-bold text-decoration-underline mb-3 mb-sm-0 ms-2"
                                onClick={() => {
                                    if (page < data?.last_page) {
                                        setPage(page + 1)
                                    }
                                }}
                            >
                                {" "}NEXT
                            </a>
                        </div>
        </>
    )
}
export default Pagination;