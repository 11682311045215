import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ApiService } from '../services/ApiService';
import { toast } from 'react-toastify';
import { STORE_USER_DATA } from '../redux/constant';
import { SocialLogin } from '../services/SocialLogin';
const LoginUser = (props) => {
    const [openLogin, setOpenLogin] = useState("app-popup-hide");
    const user = useSelector(state => state.user);
    // console.log('tokennn', user)
    const dispatch = useDispatch()
    const history = useHistory()
    const [form, setForm] = useState({
        email: "",
        password: "",
        phone : '',
        error: false,
        loading: false,
        showPassword: false
    })
    let emailPattern = "[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
    const Login = () => {
        setForm({ ...form, error: true })
        // if (form?.phone?.length > 8 && form?.phone?.length < 16) {
        if(form?.email?.match(emailPattern) && form?.password != ''){
            setForm({ ...form, loading: true })
            ApiService.login({
                email: form?.email,
                password: form?.password
            }).then((data) => {
                if (data.status) {
                    setForm({ ...form, loading: false })
                    toast.success(data.message)
                    props.setOpenLogin('app-popup-hide')
                    dispatch(
                        {
                            type: STORE_USER_DATA,
                            data: { user: data.user, access_token: data.access_token }
                        }
                    )
                } else {
                    toast.error(data.message)
                    // alert(data.message)
                    setForm({ ...form, loading: false })
                }
            })
                .catch(err => {
                    setForm({ ...form, loading: false })
                })
        }
    }

    const socialLogin = (type) => {
        SocialLogin.open(type, (auth_token) => {
            dispatch({
                type: STORE_USER_DATA,
                data: { user: {}, access_token: auth_token }
            });
            ApiService.myProfile({})
                .then((data) => {
                    if (data.status) {
                        toast.success('Login sucessful')
                        dispatch(
                            {
                                type: STORE_USER_DATA,
                                data: { user: data.data, access_token: auth_token }
                            }
                        )
                    }
                    else {
                        toast.error(data?.message)
                    }
                })
                .catch(err => {

                })

        })
    }

    const handleKeypress = e => {
        if (e.keyCode === 13) {
            Login();
        }
    }

    if (user?.access_token) {
        props.setOpenLogin("app-popup-hide")
    }
    return (
        <>
            <div id='LoginPopup'
                className={`app-popup ${props?.openLogin}`}
            // className={props.className}
            >

                <div className='app-popup-container popup-scroll-view'>
                    <div className="app-popup-content app-popup-430px login-popups">
                        <div className="app-popup-header border-bottom">
                            <h2 className='text-primary font-22px'>Sign in</h2>

                            <button
                                onClick={() => {
                                    props.setOpenLogin("app-popup-hide")
                                }}
                                className='app-popup-close'><svg width="12.191" height="12.191" viewBox="0 0 12.191 12.191" >
                                    <path id="Icon_material-close" data-name="Icon material-close" d="M19.691,8.728,18.464,7.5,13.6,12.368,8.728,7.5,7.5,8.728,12.368,13.6,7.5,18.464l1.228,1.228L13.6,14.824l4.868,4.868,1.228-1.228L14.824,13.6Z" transform="translate(-7.5 -7.5)" />
                                </svg>
                            </button>
                        </div>
                        <div className="app-popup-footer pb-0 pt-3">
                            <h6 className='font-18px mb-2 font-primary-semibold'>Continue with Social</h6>
                            <div className='row'>
                                <div className='col-6 cols-m-6 mb-2'>
                                    <button type="button" className="btn continue-social-btns w-100 h-44px font-16px mb-2"
                                        onClick={() => {
                                            socialLogin('google')
                                            // window.open(`https://abnocloud.com/mealinity/business/auth/social/google?redirect_app=${ApiService.config.url}?auth_token=`, '_self')
                                        }}> Google
                                        <span className='icon-btns'>
                                            <img src={require("../assets/img/google-icon.png")} />
                                        </span>
                                    </button>
                                </div>
                                <div className='col-6 cols-m-6 mb-2'>
                                    <button type="button" className="btn continue-social-btns w-100 h-44px font-16px mb-2"
                                        onClick={() => {
                                            socialLogin('facebook')
                                            // window.open(`https://abnocloud.com/mealinity/business/auth/social/facebook?redirect_app=${ApiService.config.url}?auth_token=`, '_self')
                                        }}
                                    >Facebook
                                        <span className='icon-btns'>
                                            <img src={require("../assets/img/fb-icon.png")} />
                                        </span>
                                    </button>
                                </div>
                                <div className='col-6 cols-m-6 mb-2'>
                                    <button type="button" className="btn continue-social-btns w-100 h-44px font-16px mb-2"
                                        onClick={() => {
                                            socialLogin('twitter')
                                            // window.open(`https://abnocloud.com/mealinity/business/auth/social/twitter?redirect_app=${ApiService.config.url}?auth_token=`, '_self')
                                        }}
                                    >Twitter
                                        <span className='icon-btns'>
                                            <img src={require("../assets/img/twitter-icon.png")} />
                                        </span>
                                    </button>
                                </div>
                                <div className='col-6 cols-m-6 mb-2'>
                                    <button type="button" className="btn continue-social-btns w-100 h-44px font-16px mb-2"
                                        onClick={() => {
                                            socialLogin('linkedin-openid')
                                            // window.open(`https://abnocloud.com/mealinity/business/auth/social/linkedin-openid?redirect_app=${ApiService.config.url}?auth_token=`, '_self')
                                        }}
                                    >LinkedIn
                                        <span className='icon-btns'>
                                            <img src={require("../assets/img/linkdin-icon.png")} />
                                        </span>
                                    </button>
                                </div>
                            </div>

                            <div className='divider-row'>
                                <span className='font-14px'>Or</span>
                            </div>

                        </div>

                        <div className="app-popup-body pb-0 pt-3 font-14px app-popup-body-scroll">
                            <h6 className='font-18px mb-2 font-primary-semibold'>Continue with Email</h6>
                            <div onKeyDown={handleKeypress}>
                                <div className="form-floating form-floating-56px mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInput"
                                        placeholder="Enter Email"
                                        value={form?.email}
                                        onChange={(e) => {
                                            setForm({
                                                ...form, email: e.target.value
                                            })
                                        }}
                                    />
                                    <label htmlFor="floatingInput">Enter Email</label>
                                    {/* {(form.phone == '' && form.error) ? <p className='error'>This field required.</p> :
                                        (form.phone.length < 9 && form.error) ? <p className='error'>Phone number must be minimum length of 9.</p> :
                                            (form.phone.length > 15 && form.error) ? <p className='error'>Phone number must be maximum length of 15.</p> : ''} */}
                                            {(form?.email == '' && form?.error) ? <p className='error'>This field required.</p> :
                                            (form?.error && !form?.email?.match(emailPattern)) ? <p className='error'>Enter valid email address.</p> : ''}
                                </div>

                                <div className="form-floating form-floating-56px">
                                    <input
                                        type={form?.showPassword ? 'text' : 'password'}
                                        className="form-control"
                                        id="floatingInput"
                                        placeholder="Enter Password"
                                        value={form?.password}
                                        onChange={(e) => {
                                            setForm({
                                                ...form, password: e.target.value
                                            })
                                        }}
                                    />
                                    <div className='icon-password' onClick={() => {
                                        setForm({
                                            ...form, showPassword: form?.showPassword == false ? true : false
                                        })
                                    }}>

                                        {form?.showPassword ? 'Hide' : 'Show'}
                                    </div>
                                    <label htmlFor="floatingInput">Enter Password</label>
                                    {(form.password == '' && form.error) ? <p className='error'>This field required.</p> :
                                        (form.password.length < 8 && form.error) ? <p className='error'>Password must be minimum length of 8.</p> :
                                            (form.password.length > 20 && form.error) ? <p className='error'>Password must be maximum length of 20.</p> : ''}
                                </div>
                            </div>
                            {/* <div className='end-section text-center font-16px line-height-140 pt-2'> */}
                            {/* <div className='d-flex justify-content-right mt-2'>
                                <a className='link-primary'
                                onClick={() => {
                                    props?.setScreen('forgot-password')
                                    props?.setOpenLogin('app-popup-hide')
                                }}>Forgot Password?</a>
                            </div> */}
                            {/* </div> */}

                        </div>
                        <div className="app-popup-footer pb-4">
                            <div className="">
                                {!form?.loading ?
                                    <div>
                                        <button type="button" id="myBtn" className="btn btn-primary font-primary-semibold w-100 h-44px font-18px text-center justify-content-center mb-2"
                                            onClick={(e) => {
                                                Login()
                                            }}
                                        >Sign in</button></div>
                                    :
                                    <div className="d-flex justify-content-center">
                                        <svg className="loader d-flex justify-content-center" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
                                        </svg></div>
                                }
                                {/* <div className='font-12px text-grey-dark'>An OTP will be sent to your mobile number for verification</div> */}
                            </div>
                            <div className='end-section text-center font-16px line-height-140 pt-2'>
                                Don't have an account? <a className='link-primary font-primary-semibold'
                                    onClick={() => {
                                        props?.setScreen('signup')
                                        props?.setOpenLogin('app-popup-hide')
                                    }}
                                >Sign up</a>
                            </div>
                        </div>


                    </div>
                </div>

            </div>
        </>
    )
}
export default LoginUser;