import React from 'react';
import { ApiService } from '../services/ApiService';
import { useSelector } from 'react-redux';



export const Footer = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const auth_token = queryParams.get("auth_token")
  const settings = useSelector(state => state.settings)
  // console.log("footer on category", settings)

  if(settings?.is_mobile_view || auth_token){
    return <></>
  }


  return <footer className="site-footer border-top site-footer-listing bg-white py-2 py-md-1 py-xl-1 text-center">
    <div className='container py-xl-4'>
      <div className='footer-inner'>
        <div className="flex-lg-row align-items-center justify-content-between site-footer-content">
          <div className='w-100 d-flex flex-lg-row align-items-center justify-content-center'>
            <div className='footer_SocialLinks1 footer-social-bg mb-3 mb-lg-0'>
              <a className='mx-1 mx-xl-2' href='https://www.facebook.com/Delytt' target={'_blank'}><svg id="Component_5_1" data-name="Component 5 – 1" xmlns="http://www.w3.org/2000/svg" width="45" height="41" viewBox="0 0 45 41">
                <g id="Rectangle_1998" data-name="Rectangle 1998" fill="#fff" strokeWidth="1">
                  <rect width="45" height="41" rx="3" stroke="none" />
                  <rect x="0.5" y="0.5" width="44" height="40" rx="2.5" fill="none" />
                </g>
                <path id="Icon_awesome-facebook-f" data-name="Icon awesome-facebook-f" d="M11.284,10.873l.537-3.5H8.464V5.1a1.749,1.749,0,0,1,1.972-1.89h1.526V.236A18.61,18.61,0,0,0,9.254,0C6.489,0,4.682,1.676,4.682,4.709V7.375H1.609v3.5H4.682V19.33H8.464V10.873Z" transform="translate(15.574 10.703)" />
              </svg>
              </a>
              <a className='mx-1 mx-xl-2' href='https://www.instagram.com/delytt/?igshid=NDk5N2NlZjQ' target={'_blank'}><svg id="Component_8_1" data-name="Component 8 – 1" xmlns="http://www.w3.org/2000/svg" width="45" height="41" viewBox="0 0 45 41">
                <g id="Rectangle_1999" data-name="Rectangle 1999" fill="#fff" stroke="#36c" strokeWidth="1">
                  <rect width="45" height="41" rx="3" stroke="none" />
                  <rect x="0.5" y="0.5" width="44" height="40" rx="2.5" fill="none" />
                </g>
                <path id="Icon_awesome-instagram" data-name="Icon awesome-instagram" d="M10.548,7.377a5.409,5.409,0,1,0,5.409,5.409A5.4,5.4,0,0,0,10.548,7.377Zm0,8.926a3.517,3.517,0,1,1,3.517-3.517A3.523,3.523,0,0,1,10.548,16.3ZM17.44,7.156a1.262,1.262,0,1,1-1.262-1.262A1.259,1.259,0,0,1,17.44,7.156Zm3.583,1.28a6.243,6.243,0,0,0-1.7-4.42,6.285,6.285,0,0,0-4.42-1.7c-1.742-.1-6.963-.1-8.7,0a6.276,6.276,0,0,0-4.42,1.7,6.264,6.264,0,0,0-1.7,4.42c-.1,1.742-.1,6.962,0,8.7a6.243,6.243,0,0,0,1.7,4.42,6.293,6.293,0,0,0,4.42,1.7c1.742.1,6.963.1,8.7,0a6.244,6.244,0,0,0,4.42-1.7,6.284,6.284,0,0,0,1.7-4.42c.1-1.742.1-6.958,0-8.7ZM18.772,19a3.56,3.56,0,0,1-2.005,2.005c-1.389.551-4.684.424-6.219.424s-4.835.122-6.219-.424A3.56,3.56,0,0,1,2.324,19c-.551-1.389-.424-4.684-.424-6.219s-.122-4.835.424-6.219A3.56,3.56,0,0,1,4.329,4.562c1.389-.551,4.684-.424,6.219-.424s4.835-.122,6.219.424a3.56,3.56,0,0,1,2.005,2.005c.551,1.389.424,4.684.424,6.219S19.323,17.621,18.772,19Z" transform="translate(11.918 6.977)" fill="#36c" />
              </svg>
              </a>
              <a className='mx-1 mx-xl-2' href='https://twitter.com/delytt_?s=21&t=y-khUCjM9Y0p9IjU_Qkpsw' target={'_blank'}><svg id="Component_7_1" data-name="Component 7 – 1" xmlns="http://www.w3.org/2000/svg" width="45" height="41" viewBox="0 0 45 41">
                <g id="Rectangle_2000" data-name="Rectangle 2000" fill="#fff" stroke="#36c" strokeWidth="1">
                  <rect width="45" height="41" rx="3" stroke="none" />
                  <rect x="0.5" y="0.5" width="44" height="40" rx="2.5" fill="none" />
                </g>
                <path id="Icon_awesome-twitter" data-name="Icon awesome-twitter" d="M18.943,7.654c.013.188.013.375.013.563A12.227,12.227,0,0,1,6.645,20.528,12.228,12.228,0,0,1,0,18.585a8.951,8.951,0,0,0,1.045.054A8.666,8.666,0,0,0,6.417,16.79a4.335,4.335,0,0,1-4.046-3,5.457,5.457,0,0,0,.817.067,4.576,4.576,0,0,0,1.139-.147A4.328,4.328,0,0,1,.857,9.463V9.409a4.358,4.358,0,0,0,1.956.549,4.333,4.333,0,0,1-1.34-5.787A12.3,12.3,0,0,0,10.4,8.7a4.885,4.885,0,0,1-.107-.991,4.331,4.331,0,0,1,7.489-2.961A8.519,8.519,0,0,0,20.523,3.7a4.315,4.315,0,0,1-1.9,2.385,8.675,8.675,0,0,0,2.492-.67A9.3,9.3,0,0,1,18.943,7.654Z" transform="translate(13.203 8.41)" fill="#36c" />
              </svg>
              </a>
              <a className='mx-1 mx-xl-2' href='https://www.linkedin.com/company/delytt' target={'_blank'}><svg id="Component_9_1" data-name="Component 9 – 1" xmlns="http://www.w3.org/2000/svg" width="45" height="41" viewBox="0 0 45 41">
                <g id="Rectangle_2001" data-name="Rectangle 2001" fill="#fff" stroke="#36c" strokeWidth="1">
                  <rect width="45" height="41" rx="3" stroke="none" />
                  <rect x="0.5" y="0.5" width="44" height="40" rx="2.5" fill="none" />
                </g>
                <path id="Icon_awesome-linkedin-in" data-name="Icon awesome-linkedin-in" d="M4.039,18.046H.3V6H4.039ZM2.167,4.355A2.177,2.177,0,1,1,4.333,2.167,2.185,2.185,0,0,1,2.167,4.355ZM18.042,18.046H14.309V12.181c0-1.4-.028-3.19-1.945-3.19-1.945,0-2.243,1.519-2.243,3.089v5.965H6.383V6H9.971V7.641h.052A3.931,3.931,0,0,1,13.563,5.7c3.786,0,4.482,2.493,4.482,5.732v6.618Z" transform="translate(14.494 10.738)" fill="#36c" />
              </svg>
              </a>
              {/* <a className='mx-1 mx-xl-2' href='#!' target={'_blank'}><svg id="Component_10_1" data-name="Component 10 – 1" xmlns="http://www.w3.org/2000/svg" width="45" height="41" viewBox="0 0 45 41">
              <g id="Rectangle_2002" data-name="Rectangle 2002" fill="#fff" stroke="#36c" strokeWidth="1">
                <rect width="45" height="41" rx="3" stroke="none"/>
                <rect x="0.5" y="0.5" width="44" height="40" rx="2.5" fill="none"/>
              </g>
              <path id="Icon_ionic-logo-youtube" data-name="Icon ionic-logo-youtube" d="M23.77,8.463a3.633,3.633,0,0,0-3.458-3.795c-2.589-.122-5.23-.168-7.926-.168h-.841c-2.692,0-5.337.047-7.926.168a3.641,3.641,0,0,0-3.454,3.8C.047,10.136,0,11.8,0,13.464s.047,3.328.159,5a3.645,3.645,0,0,0,3.454,3.809c2.72.126,5.51.182,8.347.178s5.622-.047,8.347-.178a3.646,3.646,0,0,0,3.458-3.809c.112-1.668.164-3.332.159-5S23.882,10.132,23.77,8.463Zm-14.1,9.585V8.865l6.777,4.589Z" transform="translate(11.746 7.014)" fill="#36c"/>
            </svg>
            </a> */}
            </div>
          </div>

          <div className='footer_Links1 mt-4 mb-3 mb-lg-0 font-14px w-100 flex-lg-row align-items-center justify-content-center'>
            {/* <a href='#!'>Contact Us</a>   <span className='mx-2'>/</span> */}
            {/* <a onClick={() => {
              window.open(ApiService.config.url + "business/about", '_blank', 'noreferrer')
            }}>About </a> <span className='mx-2'>/</span> */}

            <a onClick={() => {
              window.open(ApiService.config.url + 'business', '_blank', 'noreferrer')
            }}> List your Business  </a> <span className='mx-2'>/</span>

            {/* <a href='#!'> Delytt Blog  </a> <span className='mx-2'>/</span> */}

            {/* <a onClick={() => {
              window.open("https://helpdesk.delytt.com/", '_blank', 'noreferrer')
            }}> Support </a>  <span className='mx-2'>/</span> */}

            {/* <a href='#!'> Businesses </a> <span className='mx-2'>/</span> */}

            <a onClick={() => {
              window.open(ApiService.config.url + "business/term-conditions", '_blank', 'noreferrer')
            }}>Terms & Conditions </a> <span className='mx-2'>/</span>

            <a onClick={() => {
              window.open(ApiService.config.url + "business/privacy-policy", '_blank', 'noreferrer')
            }}>Privacy Notice</a>

          </div>

          <div className='footer_Links1 mt-2 mb-3 mb-lg-0 font-14px w-100 flex-lg-row align-items-center justify-content-center'>
            <div className='w-100 pt-1 color-text font-14px'>{settings?.settings?.FOOTER_TEXT}</div>
            {/* <div className='w-100 pt-1 color-text font-14px'>Copyright © 2023 - Delytt. All rights Reserved.</div> */}
          </div>

          {/* <div className='text-lg-start'>
          <span className='footerLogo'>
            <a href={ApiService.config.url} target="_blank"> <img className='' src={require("../assets/img/logo.png")} /></a>
          </span>
          <div className='opacity-07 pt-1 font-14px'>{settings?.settings?.FOOTER_TEXT}</div>
          </div> */}





        </div>
      </div>
    </div>
    {/* <div className='mt-1'>
     <a className='link-primary text-decoration-underline' target={'_blank'} href='www.delytt.com'>www.delytt.com</a>
     </div> */}
  </footer>
}


