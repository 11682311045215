import React from 'react'

function Search({ debounceTime, callback, placeholder, label, id, className }) {
    let debounceTimer = null;
    return (<>
        <input
            id={id}
            type="text"
            className={className}
            placeholder={placeholder}
            onChange={(event) => {
                clearTimeout(debounceTimer);
                debounceTimer = setTimeout(
                    callback.bind(null, event.target.value),
                    debounceTime
                )
            }} />
        {/* <label className="floatingInputLabel" htmlFor="floatingInput">
            {label}
        </label> */}

        {/* <span className='search_icon'>
            {(window.$(`#${id}`).val()) ?
                <i className='fa fa-times text-grey' onClick={() => {
                    window.$(`#${id}`).val('');
                    callback('');
                    // window.$(`#${id}`).trigger("onChange");
                    //    window.$(`#${id}`).submit();

                }}></i>
                :
                <svg
                    className="stroke-2d2d2d"
                    width="17.104"
                    height="17.104"
                    viewBox="0 0 15.104 15.104"
                >
                    <use xlinkHref="#search-icon" />

                </svg>
            }
        </span> */}

    </>
    )
}

export default Search;