import React from 'react';



export const SvgIcons = () => {


  return <div className="svg-icon-wrap">
    <svg>
      <g
        id="icon-call"
        width="26.531"
        height="26.531"
        viewBox="0 0 26.531 26.531"
      >
        <g id="noun-call-1123733" transform="translate(-76.56 -6.563)">
          <path
            id="Path_2481"
            data-name="Path 2481"
            d="M89.825,6.563a13.265,13.265,0,1,0,13.265,13.265A13.263,13.263,0,0,0,89.825,6.563ZM86.09,11.869l2.749,3.067L87.56,16.077,84.811,13.01Zm8.389,15.223a2.733,2.733,0,0,1-3.173.34,20.935,20.935,0,0,1-5.561-4.6A20.748,20.748,0,0,1,82.5,17.6a2.731,2.731,0,0,1,.679-3.125l1.077-.96L87.029,16.6a1.626,1.626,0,0,0-.244,1.629,9.55,9.55,0,0,0,1.183,2.117A9.648,9.648,0,0,0,91.1,23.07c0,.005.005.005.011.011a1.639,1.639,0,0,0,1.65-.058l2.791,3.109ZM96.1,25.628l-2.77-3.093,1.279-1.141,2.77,3.093Z"
          />
        </g>
      </g>
      <g id="icon-mail" width={27} height={27} viewBox="0 0 27 27">
        <g id="Component_1_1" data-name="Component 1 – 1">
          <circle
            id="Ellipse_59"
            data-name="Ellipse 59"
            cx="13.5"
            cy="13.5"
            r="13.5"
          />
        </g>
        <path
          id="Icon_material-email"
          data-name="Icon material-email"
          d="M13.4,6H4.155A1.154,1.154,0,0,0,3.006,7.155L3,14.086a1.159,1.159,0,0,0,1.155,1.155H13.4a1.159,1.159,0,0,0,1.155-1.155V7.155A1.159,1.159,0,0,0,13.4,6Zm0,2.31L8.776,11.2,4.155,8.31V7.155l4.621,2.888L13.4,7.155Z"
          transform="translate(4.724 2.875)"
          fill="#fff"
        />
      </g>
      <g
        id="icon-site"
        data-name="Component 1 – 1"
        width={27}
        height={27}
        viewBox="0 0 27 27"
      >
        <circle
          id="Ellipse_181"
          data-name="Ellipse 181"
          cx="13.5"
          cy="13.5"
          r="13.5"
        />
        <path
          id="Path_2485"
          data-name="Path 2485"
          d="M117.134,39.2a7.934,7.934,0,1,0,7.934,7.934,7.942,7.942,0,0,0-7.934-7.934Zm-.554,1.2v2.485h-1.92A3.817,3.817,0,0,1,116.58,40.4Zm1.107,0a3.817,3.817,0,0,1,1.92,2.485h-1.92Zm-3.108.4a8.179,8.179,0,0,0-1.026,2.082h-1.764A6.82,6.82,0,0,1,114.58,40.806Zm5.109,0a6.819,6.819,0,0,1,2.791,2.082h-1.764A8.181,8.181,0,0,0,119.688,40.806Zm-8.614,3.189h2.162a13.808,13.808,0,0,0-.334,2.583H110.33A6.8,6.8,0,0,1,111.074,43.994Zm3.264,0h2.243v2.583h-2.572a13.85,13.85,0,0,1,.329-2.583Zm3.35,0h2.243a13.863,13.863,0,0,1,.329,2.583h-2.572Zm3.344,0h2.162a6.8,6.8,0,0,1,.744,2.583h-2.572A13.82,13.82,0,0,0,121.032,43.994Zm-10.7,3.69H112.9a13.749,13.749,0,0,0,.334,2.583h-2.168A6.8,6.8,0,0,1,110.33,47.685Zm3.679,0h2.572v2.583h-2.243a13.865,13.865,0,0,1-.329-2.583Zm3.679,0h2.572a13.844,13.844,0,0,1-.329,2.583h-2.243Zm3.679,0h2.572a6.8,6.8,0,0,1-.738,2.583h-2.168A13.748,13.748,0,0,0,121.366,47.685Zm-9.583,3.69h1.764a8.187,8.187,0,0,0,1.032,2.093,6.826,6.826,0,0,1-2.8-2.093Zm2.871,0h1.926v2.491A3.824,3.824,0,0,1,114.655,51.375Zm3.033,0h1.926a3.825,3.825,0,0,1-1.926,2.491Zm3.033,0h1.764a6.826,6.826,0,0,1-2.8,2.093A8.19,8.19,0,0,0,120.721,51.375Z"
          transform="translate(-103.634 -33.141)"
          fill="#fff"
        />
      </g>
      <g id="icon-facebook" width={27} height={27} viewBox="0 0 27 27">
        <circle
          id="Ellipse_182"
          data-name="Ellipse 182"
          cx="13.5"
          cy="13.5"
          r="13.5"
        />
        <path
          id="Icon_awesome-facebook-f"
          data-name="Icon awesome-facebook-f"
          d="M7.337,6.437l.318-2.071H5.667V3.022A1.035,1.035,0,0,1,6.835,1.9h.9V.14A11.016,11.016,0,0,0,6.135,0,2.528,2.528,0,0,0,3.428,2.787V4.366H1.609V6.437H3.428v5.006H5.667V6.437Z"
          transform="translate(8.826 8.274)"
          fill="#fff"
        />
      </g>
      <g id="icon-twitter" width={27} height={27} viewBox="0 0 27 27">
        <circle
          id="Ellipse_183"
          data-name="Ellipse 183"
          cx="13.5"
          cy="13.5"
          r="13.5"
        />
        <path
          id="Icon_ionic-logo-twitter"
          data-name="Icon ionic-logo-twitter"
          d="M14.194,5.732a5.3,5.3,0,0,1-1.506.414A2.631,2.631,0,0,0,13.842,4.7a5.21,5.21,0,0,1-1.666.636A2.621,2.621,0,0,0,7.64,7.124a2.567,2.567,0,0,0,.068.6A7.431,7.431,0,0,1,2.3,4.979a2.627,2.627,0,0,0,.815,3.5,2.561,2.561,0,0,1-1.192-.328v.033a2.624,2.624,0,0,0,2.1,2.573,2.639,2.639,0,0,1-.691.092,2.474,2.474,0,0,1-.493-.049A2.626,2.626,0,0,0,5.3,12.628a5.257,5.257,0,0,1-3.258,1.124,5.315,5.315,0,0,1-.626-.038A7.329,7.329,0,0,0,5.427,14.9a7.416,7.416,0,0,0,7.467-7.47c0-.114,0-.228-.008-.339A5.338,5.338,0,0,0,14.194,5.732Z"
          transform="translate(6.311 4.594)"
          fill="#fff"
        />
      </g>
      <g id="icon-insta" width={27} height={27} viewBox="0 0 27 27">
        <circle
          id="Ellipse_184"
          data-name="Ellipse 184"
          cx="13.5"
          cy="13.5"
          r="13.5"
        />
        <path
          id="Icon_simple-instagram"
          data-name="Icon simple-instagram"
          d="M5.776,0C4.207,0,4.011.007,3.395.035A4.259,4.259,0,0,0,1.993.3,2.828,2.828,0,0,0,.969.969,2.818,2.818,0,0,0,.3,1.993a4.246,4.246,0,0,0-.269,1.4C.006,4.011,0,4.207,0,5.776S.007,7.541.035,8.157A4.261,4.261,0,0,0,.3,9.559a2.832,2.832,0,0,0,.666,1.023,2.824,2.824,0,0,0,1.023.666,4.264,4.264,0,0,0,1.4.269c.616.029.812.035,2.381.035s1.765-.007,2.381-.035a4.274,4.274,0,0,0,1.4-.269,2.952,2.952,0,0,0,1.689-1.689,4.261,4.261,0,0,0,.269-1.4c.029-.616.035-.812.035-2.381s-.007-1.765-.035-2.381a4.271,4.271,0,0,0-.269-1.4A2.835,2.835,0,0,0,10.582.969,2.814,2.814,0,0,0,9.559.3a4.249,4.249,0,0,0-1.4-.269C7.541.006,7.345,0,5.776,0Zm0,1.04c1.542,0,1.725.008,2.334.034a3.182,3.182,0,0,1,1.072.2,1.9,1.9,0,0,1,1.1,1.1,3.189,3.189,0,0,1,.2,1.072c.027.609.034.792.034,2.334S10.5,7.5,10.475,8.11a3.25,3.25,0,0,1-.2,1.072,1.834,1.834,0,0,1-.433.665,1.8,1.8,0,0,1-.664.431,3.212,3.212,0,0,1-1.076.2c-.613.027-.794.034-2.339.034s-1.726-.007-2.339-.036a3.275,3.275,0,0,1-1.076-.2,1.789,1.789,0,0,1-.664-.433,1.754,1.754,0,0,1-.433-.664,3.278,3.278,0,0,1-.2-1.076c-.022-.606-.029-.794-.029-2.331s.008-1.726.029-2.34a3.274,3.274,0,0,1,.2-1.075,1.712,1.712,0,0,1,.433-.665,1.708,1.708,0,0,1,.664-.432,3.2,3.2,0,0,1,1.069-.2c.614-.022.794-.029,2.339-.029l.022.014Zm0,1.77A2.966,2.966,0,1,0,8.741,5.776,2.966,2.966,0,0,0,5.776,2.81Zm0,4.891A1.925,1.925,0,1,1,7.7,5.776,1.925,1.925,0,0,1,5.776,7.7ZM9.552,2.693A.693.693,0,1,1,8.859,2,.694.694,0,0,1,9.552,2.693Z"
          transform="translate(7.724 7.725)"
          fill="#fff"
        />
      </g>
      <g
        id="icon-direction"
        width="16.043"
        height="16.043"
        viewBox="0 0 16.043 16.043"
      >
        <g id="noun-navigate-225681" transform="translate(-73.79 -4.58)">
          <path
            id="Path_2482"
            data-name="Path 2482"
            d="M81.812,20.623A8.022,8.022,0,1,1,89.833,12.6,8.031,8.031,0,0,1,81.812,20.623Zm0-14.942A6.921,6.921,0,1,0,88.733,12.6a6.929,6.929,0,0,0-6.921-6.921Z"
            fillRule="evenodd"
          />
          <path
            id="Path_2483"
            data-name="Path 2483"
            d="M240,206.189l2.319.8a.855.855,0,0,1,.53.53l.8,2.319,1.912-5.56Z"
            transform="translate(-161.403 -193.921)"
            fillRule="evenodd"
          />
        </g>
        <g id='map-icon' width="14.223" height="18.866" viewBox="0 0 14.223 18.866">
          <g id="Group_11250" data-name="Group 11250" transform="translate(-554 -1926)">
            <path id="map-marker" d="M11.542,0A7.112,7.112,0,0,0,4.43,7.112a6.106,6.106,0,0,0,.995,3.648l6.117,8.107,6.117-8.107a6.7,6.7,0,0,0,.995-3.648A7.112,7.112,0,0,0,11.542,0Zm0,4.119A2.993,2.993,0,1,1,8.549,7.112,2.993,2.993,0,0,1,11.542,4.119Z" transform="translate(549.57 1926)" />
            <circle id="Ellipse_58" data-name="Ellipse 58" cx="3.267" cy="3.267" r="3.267" transform="translate(557.844 1929.844)" fill="#fff" />
          </g>
        </g>

      </g>
      <g id='arrow-back' width="23.816" height="23.816" viewBox="0 0 23.816 23.816">
        <path id="Path_2487" data-name="Path 2487" d="M23.816,11.908A11.908,11.908,0,1,1,11.908,0,11.908,11.908,0,0,1,23.816,11.908ZM12.652,7.443a.744.744,0,0,0-1.489,0v7.134l-3.195-3.2a.745.745,0,1,0-1.054,1.054L11.381,16.9a.744.744,0,0,0,1.054,0L16.9,12.435a.745.745,0,1,0-1.054-1.054l-3.194,3.2Z" transform="translate(23.816) rotate(90)" fillRule="evenodd" />
      </g>

      <g id='search-icon' width="17.022" height="17.022" viewBox="0 0 17.022 17.022">
        <g id="Icon_feather-search" data-name="Icon feather-search" transform="translate(0.75 0.75)">
          <path id="Path_2452" data-name="Path 2452" d="M18.021,11.26A6.76,6.76,0,1,1,11.26,4.5,6.76,6.76,0,0,1,18.021,11.26Z" transform="translate(-4.5 -4.5)" fill="none" stroke="#bebebe" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
          <path id="Path_2453" data-name="Path 2453" d="M28.651,28.651l-3.676-3.676" transform="translate(-13.44 -13.44)" fill="none" stroke="#bebebe" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
        </g>
      </g>
      <g id='check-icon' width="15.652" height="11.742" viewBox="0 0 15.652 11.742">
        <path id="Path_2503" data-name="Path 2503" d="M15.385,19.462,11.47,15.549,9.514,17.506l5.871,5.871,9.782-9.783-1.954-1.958-7.827,7.827Z" transform="translate(-9.514 -11.635)" />
      </g>

      <g id='start-icon' width="9.402" height="8.999" viewBox="0 0 9.402 8.999">
        <path id="Icon_awesome-star" data-name="Icon awesome-star" d="M5.638.312,4.49,2.639l-2.568.374a.563.563,0,0,0-.311.96l1.858,1.81L3.03,8.34a.562.562,0,0,0,.815.592l2.3-1.207,2.3,1.207a.562.562,0,0,0,.815-.592L8.815,5.783l1.858-1.81a.563.563,0,0,0-.311-.96L7.794,2.639,6.647.312A.563.563,0,0,0,5.638.312Z" transform="translate(-1.441 0.001)" fill="#fff" />
      </g>

      <g id='map-icon1' width="12.451" height="15.904" viewBox="0 0 12.451 15.904">
        <g id="noun-map-pin-1037254" transform="translate(-142.11 -13.693)">
          <path id="Path_2502" data-name="Path 2502" d="M153.855,17.119a6.214,6.214,0,0,0-8.341-2.764,6.3,6.3,0,0,0-2.764,8.341,64.558,64.558,0,0,0,4.924,6.6.8.8,0,0,0,1.256,0,73.319,73.319,0,0,0,4.941-6.6,5.926,5.926,0,0,0-.017-5.577Zm-5.544,6.046a3.233,3.233,0,1,1,3.233-3.233A3.234,3.234,0,0,1,148.311,23.165Z" transform="translate(0 0)" fill="#EE1E3D" />
        </g>
      </g>

      <g id="filter-icon" width="16.322" height="10.199" viewBox="0 0 16.322 10.199">
        <g id="setting-lines" transform="translate(0 -4.503)">
          <path id="Path_1423" data-name="Path 1423" d="M3.488,53.547H.48a.48.48,0,0,1,0-.96H3.488a.48.48,0,0,1,0,.96Z" transform="translate(0 -46.365)" />
          <path id="Path_1424" data-name="Path 1424" d="M86.335,8.9a2.2,2.2,0,1,1,2.2-2.2A2.2,2.2,0,0,1,86.335,8.9Zm0-3.438A1.239,1.239,0,1,0,87.574,6.7,1.24,1.24,0,0,0,86.335,5.463Z" transform="translate(-81.128)" />
          <path id="Path_1425" data-name="Path 1425" d="M236.66,53.547h-7.175a.48.48,0,0,1,0-.96h7.175a.48.48,0,0,1,0,.96Z" transform="translate(-220.818 -46.365)" />
          <path id="Path_1426" data-name="Path 1426" d="M266.628,171.172a2.2,2.2,0,1,1,2.2-2.2A2.2,2.2,0,0,1,266.628,171.172Zm0-3.437a1.239,1.239,0,1,0,1.239,1.239A1.24,1.24,0,0,0,266.628,167.734Z" transform="translate(-254.976 -156.47)" />
          <path id="Path_1427" data-name="Path 1427" d="M8.192,215.81H.48a.48.48,0,1,1,0-.96H8.192a.48.48,0,1,1,0,.96Z" transform="translate(0 -202.827)" />
          <path id="Path_1428" data-name="Path 1428" d="M363.538,215.81h-2.471a.48.48,0,1,1,0-.96h2.471a.48.48,0,0,1,0,.96Z" transform="translate(-347.696 -202.827)" />
        </g>
      </g>

      <g id='next-arrow' width="24" height="24" viewBox="0 0 24 24">
        <g id="Group_11313" data-name="Group 11313" transform="translate(-1483 -217)">
          <g id="Ellipse_186" data-name="Ellipse 186" transform="translate(1483 217)" fill="#fff" stroke="#afafaf" strokeWidth="1">
            <circle cx="12" cy="12" r="12" stroke="none" />
            <circle cx="12" cy="12" r="11.5" fill="none" />
          </g>
          <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back" d="M1.652,4.793,5.28,1.168A.685.685,0,0,0,4.31.2L.2,4.308a.684.684,0,0,0-.02.945L4.307,9.388a.685.685,0,1,0,.97-.968Z" transform="translate(1497.74 234.292) rotate(180)" fill="#393939" />
        </g>
      </g>

      <g id='Plus-icon' width="8.5" height="8.5" viewBox="0 0 8.5 8.5">
        <g id="Group_9897" data-name="Group 9897" transform="translate(-325 -328.25)">
          <line id="Line_15" data-name="Line 15" x2="8.5" transform="translate(325 332.5)" fill="none" stroke="#fff" strokeWidth="2" />
          <line id="Line_168" data-name="Line 168" x2="8.5" transform="translate(329.25 328.25) rotate(90)" fill="none" stroke="#fff" strokeWidth="2" />
        </g>
      </g>

      <svg id='Minus-icon' width="8.5" height="2" viewBox="0 0 8.5 2">
        <line id="Line_167" data-name="Line 167" x1="8.5" transform="translate(0 1)" fill="none" stroke="#fff" strokeWidth="2" />
      </svg>


    </svg>
  </div>;

}

 
export const Loader=(props)=>{
  let h=props.height || '70px';
  let w=props.width || '70px';
  
  return <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{margin: 'auto', display: 'block'}} width={w} height={h} viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
  <path d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#137bdf" stroke="none">
    <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 51;360 50 51" />
  </path>
</svg>
<p>{props.text}</p>
</div>
}