import React from 'react';
const Loader = (props) => {
  const defaultArr = [];
  if (props.count) {
    for (let i = 0; i < Number(props.count); i++) {
      defaultArr.push(i);
    }
  } else {
    for (let i = 0; i < Number(3); i++) {
      defaultArr.push(i);
    }
  }
  if (props.type == 'skelaton') {
    return (
      <>
        <div className={props.parentClass + ' shimmer'}>
          {defaultArr.map((item, index) => {
            return (
              <React.Fragment key={index}>
                {props.children}
              </React.Fragment>
            );
          })}
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="loader-container">
                <span className="loader"></span>
              </div>
      </>
    );
  }



}
export default Loader;