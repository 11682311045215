import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { STORE_USER_DATA } from '../redux/constant';
import { ApiService } from '../services/ApiService';
import { ToastContainer, toast } from 'react-toastify';
const LogoutUser = ({closePopup}) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const Logout = () => {
        closePopup(false)
        ApiService.fetch({
            url: "logout-customer",
            options: { body: {} }
        }).then((data) => {
            if (data.status == true) {
                dispatch(
                    {
                        type: STORE_USER_DATA,
                        data: { user: {}, access_token: "" }
                    }
                );
                toast.success(data.message)
                history.push("/")
                // window.location.reload(false)
            }
        });
    }
    return(
        <>
        <div className='app-popup'>
        <div className='app-popup-container logout_modal text-center'>
    <div className="app-popup-content app-popup-430px">
        <div className="app-popup-header ">
            <h2 className='text-primary font-19px'>Are you sure you want to logout</h2>

            <button  className='app-popup-close'
            onClick={() => closePopup(false)}>
                <svg width="12.191" height="12.191" viewBox="0 0 12.191 12.191" >
                <path id="Icon_material-close" data-name="Icon material-close" d="M19.691,8.728,18.464,7.5,13.6,12.368,8.728,7.5,7.5,8.728,12.368,13.6,7.5,18.464l1.228,1.228L13.6,14.824l4.868,4.868,1.228-1.228L14.824,13.6Z" transform="translate(-7.5 -7.5)" fill="Currentcolor" />
            </svg>
            </button>
        </div>
        <div className="app-popup-footer pb-4">
            <div className="text-center">
               
                    <button type="button" className="btn btn-primary  font-18px  me-3"
                     onClick={(event) => {
                        Logout();
                        event.preventDefault();
                    }}
                >YES</button>
                 <button type="button" className="btn btn-danger  font-18px "
                 onClick={() => closePopup(false)}
                >NO</button>
             
            </div>
        </div>
    </div>
</div>

</div>

        </>
    )
}


export default LogoutUser;