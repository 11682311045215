import LocationSelector from "../../Components/LocationSelector";
import { LocationService } from "../../services/LocationService";
import { useEffect } from "react";

export const Location=()=>{
       
    
       useEffect(()=>{
        // let locationApi=new LocationService();
        // locationApi.getAddress(25.2138156,75.8647527).then((data)=>{
        //     console.log(data);
        // })

       },[])
    return  <LocationSelector />;
}