import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { STORE_USER_DATA } from '../redux/constant';
import { ApiService } from '../services/ApiService';
import LogoutUser from './LogoutUser';
const LogoutPopup = (props) => {
    let path = props.path
    // console.log("orderpath", path)
    const [openPopup, setOpenPopup] = useState(false)
    const user = useSelector(state => state.user);
    const dispatch = useDispatch()
    const history = useHistory()
    return (
        <>
            <div onClick={({ target }) => target.classList.toggle('active')} className='dropdown_app_menu Userdropdown me-4 cursor-pointer'>
                <span className='dropdown_toggle_btn'></span>
                <a href='#!'><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={40}
                    height={40}
                    viewBox="0 0 40 40"
                >
                    <g id="Group_12379" data-name="Group 12379" transform="translate(-1392 -29)">
                        <g
                            id="Ellipse_221"
                            data-name="Ellipse 221"
                            transform="translate(1392 29)"
                            fill="#fff"
                            stroke="#ef626c"
                            strokeWidth={1}
                        >
                            <circle cx={20} cy={20} r={20} stroke="none" />
                            <circle cx={20} cy={20} r="19.5" fill="none" />
                        </g>
                        <g id="user-line" transform="translate(1399.687 36.447)">
                            <path
                                id="Path_2657"
                                data-name="Path 2657"
                                d="M14.938,10.876A3.938,3.938,0,1,0,11,6.938a3.938,3.938,0,0,0,3.938,3.938Zm0-6.75a2.813,2.813,0,1,1-2.813,2.813A2.813,2.813,0,0,1,14.938,4.125Z"
                                transform="translate(-2.625)"
                                fill="#ef626c"
                            />
                            <path
                                id="Path_2658"
                                data-name="Path 2658"
                                d="M19.328,22.022a9.653,9.653,0,0,0-14.024,0,1.125,1.125,0,0,0-.3.771v2.959a1.125,1.125,0,0,0,1.125,1.125H18.5a1.125,1.125,0,0,0,1.125-1.125V22.793A1.125,1.125,0,0,0,19.328,22.022Zm-.827,3.73H6.125V22.787a8.534,8.534,0,0,1,12.376,0Z"
                                transform="translate(0 -7.001)"
                                fill="#ef626c"
                            />
                        </g>
                    </g>
                </svg>
                </a>
                <div className='dropdown_app pb-0'>

                    <span className='dropdown_app_arrow'><svg width="17" height="8.568" viewBox="0 0 17 8.568">
                        <g id="Polygon_5" data-name="Polygon 5" fill="#fff">
                            <path d="M 15.79971027374268 8.068483352661133 L 1.200289726257324 8.068483352661133 L 8.5 0.7099633812904358 L 15.79971027374268 8.068483352661133 Z" stroke="none" />
                            <path d="M 8.5 1.419923305511475 L 2.400579452514648 7.568483352661133 L 14.59942054748535 7.568483352661133 L 8.5 1.419923305511475 M 8.5 3.814697265625e-06 L 17 8.568483352661133 L 0 8.568483352661133 L 8.5 3.814697265625e-06 Z" stroke="none" fill="#e3e3e3" />
                        </g>
                    </svg>
                    </span>

                    <ul className="list-group list-group-flush">

                        <li className="list-group-item"
                            onClick={(e) => {
                                history.push("/myprofile")
                                e.preventDefault()
                            }}
                        >
                            <a href='#!'>
                                My Profile
                            </a>
                        </li>

                        <li className="list-group-item"
                            onClick={(e) => {
                                history.push("/my-order/current")
                                e.preventDefault()
                            }}
                        >
                            <a href='#!'>
                                Orders & Bookings
                            </a>
                        </li>
                        {/* <li className="list-group-item"><a href='javascript:void(0)'>Past Orders & Bookings</a></li>  */}

                        <li className="list-group-item"
                            onClick={(e) => {
                                history.push("/mymembership")
                                e.preventDefault()
                            }}
                        >
                            <a href='#!'>
                                My Membership (S)
                            </a>
                        </li>

                        <li className="list-group-item"
                            onClick={(e) => {
                                history.push("/myvoucher")
                                e.preventDefault()
                            }}
                        >
                            <a href='#!'>
                                My Voucher (S)
                            </a>
                        </li>

                        <li className="list-group-item"
                            onClick={() => {
                                setOpenPopup(true)
                            }}>
                            <a className='text-primary' href='#!'>
                                Logout
                            </a>
                        </li>
                    </ul>
                    <div className='LoggedInLI'>
                        <div className='font-12px pb-2'>Logged in with </div>
                        <div className='font-14px font-primary-semibold'> {user?.user?.email}</div>
                    </div>

                </div>
            </div>

            <div className="">
                {openPopup ? <LogoutUser closePopup={setOpenPopup} /> : ""}
            </div>
        </>
    )
}


export default LogoutPopup;