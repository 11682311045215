import React from 'react';
import Helper from '../../utils/helper';
import { ApiService } from '../../services/ApiService';
import { useEffect } from 'react';
import { useState } from 'react';
const OrderDetail = (props) => {
    console.log("order detail of delivery", props.selectedItem)
    let selectedItem = props.selectedItem
    let detail = props.selectedItem
    const [orderDetail, setOrderDetail] = useState(false)
    let currency = "KES"

    useEffect(() => {
        ApiService.fetch({
            url: "delivery-detail",
            options: {
                body: {
                    slug: selectedItem?.slug
                }
            }
        }).then((data) => {
            if (data.status == true) {
                setOrderDetail(data.data)
            }
        });
    }, [selectedItem?.slug])

    return(
        <>
        <div id='OrderDetails' className={`app-popup app-popup-offcanvas-end ${props?.showPopup}`}>
                <div className='app-popup-container'>
                    {orderDetail ?
                    <div className="app-popup-content app-popup-485px">
                        <div className="app-popup-header border-bottom">
                            <h2 className='text-primary font-22px'>Order Details</h2>

                            <button className='app-popup-close'
                                onClick={() => {
                                    props.setShowPopup('app-popup-hide');
                                }}
                            >
                                <svg width="12.191" height="12.191" viewBox="0 0 12.191 12.191">
                                    <path id="Icon_material-close" data-name="Icon material-close" d="M19.691,8.728,18.464,7.5,13.6,12.368,8.728,7.5,7.5,8.728,12.368,13.6,7.5,18.464l1.228,1.228L13.6,14.824l4.868,4.868,1.228-1.228L14.824,13.6Z" transform="translate(-7.5 -7.5)" />
                                </svg>
                            </button>
                        </div>
                        <div className="app-popup-body pb-0 font-14px app-popup-body-scroll">

                            <div className='d-flex align-items-end mb-3'>
                                <div className='flex-grow-1'>
                                    <div className='d-flex align-items-center'>
                                        <div className='me-2 brand_img'>
                                            <div className='img-outer rounded-2'>
                                                <img className="w-100 h-auto"
                                                    //   src={require("../../assets/img/slide-1.jpg")} 
                                                    src={orderDetail?.get_branch?.fullimage}
                                                /></div>
                                        </div>
                                        <div>
                                            <div className='font-primary-semibold pb-1'>{orderDetail?.get_branch?.branch_name}</div>
                                            <div className='text-grey-dark font-12px'>{orderDetail?.get_branch?.location}</div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {detail?.status == 'PENDING' && <span className='font-12px font-primary-semibold text-red text-uppercase'>Pending</span>}
                                    {detail?.status == 'IN_PREPARATION' && <span className='font-12px font-primary-semibold text-warn text-uppercase'>In preparation</span>}
                                    {detail?.status == 'COMPLETED' && <span className='font-12px font-primary-semibold text-green text-uppercase'>COMPLETED</span>}
                                    {detail?.status == 'CANCELLED' && <span className='font-12px font-primary-semibold text-red text-uppercase'>CANCELLED</span>}
                                </div>
                            </div>

                            <div className='border-bottom border-top pt-3 mb-3'>
                                <div className='row row-cols-sm-2 row-cols-lg-3 row-cols-xl-3 mb-2'>
                                    <div className='column'>
                                        <div className='font-primary-semibold mb-2 pb-1'>
                                            <div className='font-10px opacity-04 pb-1 text-uppercase'>Order No.</div>
                                            <div className='font-12px'>#{orderDetail?.get_order_detail?.id}</div>
                                        </div>
                                    </div>

                                    <div className='column px-0 mobilepads'>
                                        <div className='font-primary-semibold mb-2 pb-1'>
                                            <div className='font-10px opacity-04 pb-1 text-uppercase'>ORDERED ON</div>
                                            <div className='font-12px'>
                                                {/* {detail['created_at']} */}
                                                {Helper.dateFormat(orderDetail?.get_order_detail?.created_at)}
                                                </div>
                                        </div>
                                    </div>

                                    <div className='column'>
                                        <div className='font-primary-semibold mb-2 pb-1'>
                                            <div className='font-10px opacity-04 pb-1 text-uppercase'>ORDER AMOUNT</div>
                                            <div className='font-12px'>
                                               {currency} {orderDetail?.get_order_detail?.final_amount}
                                                {/* {orderDetail?.paid_status == 'unpaid' ?
                                                    <span className='Unpaid_status3 font-10px text-uppercase text-white px-1 font-primary-semibold'>UNPAID</span>
                                                    : <span className='paid_status3 font-10px text-uppercase text-white px-1 font-primary-semibold'>pAID</span>
                                                } */}
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                            <div>
                                <div className='font-14px font-primary-bold mb-4'>Total Item(s)/Service(s): {detail?.total_items}</div>


                                {/* List Start */}
                                <div className='list_item_ul'>
                                    {orderDetail?.get_delivery_menu_items?.map((item)=>{
                                return <div className='list_item_li pb-1'>
                                        <div className='d-flex align-items-end mb-3'>
                                            <div className='flex-grow-1'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='me-3 brand_img'>
                                                        <div className='img-outer rounded-2'> <img className="w-100 h-auto" src={item?.get_menu_item?.image} /></div>
                                                    </div>
                                                    <div>
                                                        <div className='font-primary-semibold pb-2'>{item?.get_menu_item?.title}</div>
                                                        <div className='text-grey-dark font-14px'>Qty: {item?.quantity}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <span className='font-14px font-primary-semibold'>{currency} {item?.get_menu_item?.price * item?.quantity}</span>
                                            </div>
                                        </div>
                                        {/* {item?.instruction != "" &&
                                        <div className='mb-2 position-relative top--2 pe-xl-5'>
                                            <div className='font-11px font-primary-semibold text-primary pb-1'>Special Instructions</div>
                                            <div className='font-12px line-height-150'>{item?.instruction}</div>
                                        </div>
                                    } */}
                                    </div>
                                    })}
                                </div>

                                {/* List End */}
                                <div className='d-flex justify-content-between border-top border-2 pt-3'>
                                    <div className='font-14px font-primary-semibold'>Grand Total</div>
                                    <div className='text-end'>
                                        <div className='font-14px font-primary-bold text-primary'>{currency} {orderDetail?.get_order_detail?.final_amount}</div>
                                        <div className='pt-1'>
                                        {detail?.paid_status != 'paid' ?
                                            <span className='Unpaid_status3 font-10px text-uppercase text-white px-1 font-primary-semibold'>UNPAID</span>
                                            :
                                            <span className='paid_status3 font-10px text-uppercase text-white px-1 font-primary-semibold'>PAID</span>
                                }
                                            </div>
                                    </div>
                                </div>

                                {/* Scane Code Start */}
                                {props.tab != "past" ?
                                <div className='ScaneCode_wrap d-flex py-4 justify-content-center text-center'>
                                    <div className='ScaneCode_box mx-auto'>
                                        </div>
                                </div>
                                :<div className='d-flex align-items-center justify-content-center mb-4 pt-2'>
                                <a className='link-default text-decoration-underline font-12px font-primary-semibold' href='#!'>Download Receipt</a>
                                <span className='ms-1'><img src={require("../../assets/img/icon/download.svg").default} /></span>
                            </div>
                            }
                                {/* Scane Code End */}


                            </div>
                        </div>

                        {props?.tab == "past" &&
                        <div className="app-popup-footer border-top h-57px py-3">
                        <a href='#!' className="link-primary w-100 h-100 d-flex align-items-center font-18px font-primary-semibold text-center justify-content-center mb-2">
                            <svg
                                className='me-1'
                                width="12.557"
                                height="13.606"
                                viewBox="0 0 12.557 13.606"
                            >
                                <path
                                    id="Icon_ionic-md-repeat"
                                    data-name="Icon ionic-md-repeat"
                                    d="M7.29,6.777h6.977V8.818L17.057,6.1,14.27,3.375V5.416H5.9V9.5h1.4V6.777Zm6.98,6.8H7.29V11.539L4.5,14.26l2.79,2.721V14.941h8.373V10.859h-1.4V13.58Z"
                                    transform="translate(-4.5 -3.375)"
                                />
                            </svg>

                            Reorder</a>
                    </div>
}

                        {/* <div className="app-popup-footer border-top py-3">
                            <div className="row row-cols-2 pt-1">
                                <div className=''><a className="btn btn-outline-primary w-100 h-44px font-18px text-center justify-content-center mb-2">Request Bill</a></div>
                                <div className=''><a className="btn btn-primary w-100 h-44px font-18px text-center justify-content-center mb-2">Pay {detail?.get_branch_detail?.currency} {detail?.final_amount}</a></div>

                            </div>
                        </div> */}
                    </div>
                    :""}
                </div>
            </div>
        </>
    )
}
export default OrderDetail;