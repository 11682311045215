import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Terms from './Terms';
import MemberShipProduct from './MemberShipProduct';
import MemberShipService from './MemberShipService';
import MemberShipOffer from './MemberShipOffer';
import BuyMemberShip from './BuyMemberShip';
import LoginPopup from '../../library/LoginPopup';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { ApiService } from '../../services/ApiService';
import { Loader } from '../../Components/SvgIcons';
import ItemSlider from '../../Components/ItemSlider';
import LoginUser from '../../library/LoginUser';
import SignUp from '../../library/SignUp';
import ForgotPassword from '../../library/ForgotPassword';
import ResetPassword from '../../library/ResetPassword';
import { toast } from 'react-toastify';
const MemberShipDetail = (props) => {
    const user = useSelector(state => state.user)
    let location = useLocation()
    const [showPopup, setShowPopup] = useState("app-popup-hide")
    const [openLogin, setOpenLogin] = useState("app-popup-hide");
    const [popupScreen, setPopupScreen] = useState('')
    const [memberShipId, setMemberShipId] = useState(location?.state?.data?.id)
    const [memberShipDetail, setMemberShipDetail] = useState(false)
    const [screen, setScreen] = useState("")
    const history = useHistory()
    let currency = "KES"

    useEffect(() => {
        ApiService.fetch({
            url: "get-membership-detail",
            options: {
                body: {
                    membership_id: memberShipId,
                }
            }
        }).then((data) => {
            if (data.status == true) {
                setMemberShipDetail(data)
            } else {
                toast.error(data?.message)
            }
        }).catch((err) => {

        })
    }, [memberShipId])

    if (!memberShipDetail) {
        return <Loader />
    }

    return (
        <div>
            <div className="container mb-2 mb-md-5">
                {/* Choose Your Subscription Start */}
                <div className='Choose_Subscription border-bottom mb-4 pb-3 mb-md-5 pb-md-4 pb-xl-5'>
                    <div className="mb-2 mb-sm-3 mb-xl-4 d-flex align-items-center">
                        <a className='me-2 cursor-pointer'
                            // href='javascript:void(0)'
                            onClick={() => {
                                history.push("/o/" + props.data.slug + "/membership")
                            }}
                        ><svg width="26" height="26" viewBox="0 0 26 26">
                                <path id="Path_3316" data-name="Path 3316" d="M26,13A13,13,0,1,1,13,0,13,13,0,0,1,26,13ZM13.576,17.3a.814.814,0,1,1-1.15,1.151L7.55,13.576a.812.812,0,0,1,0-1.15L12.425,7.55a.814.814,0,1,1,1.15,1.15l-3.489,3.487h7.788a.813.813,0,0,1,0,1.625H10.087L13.576,17.3Z" fill="#ef626c" fillRule="evenodd" />
                            </svg>
                        </a>
                        <div>
                            <h3 className="font-20px line-height-140">Membership Programs</h3>
                            <div className="font-28px text-primary font-primary-bold">{memberShipDetail?.data?.name}</div>
                        </div>
                    </div>
                    <div className='row gx-sm-2 gx-xl-4 mb-3'>
                        <div className='col-12 col-xl-5 col-md-5 mb-3 mb-xl-0'>
                            <img className="w-100 rounded-10px h-auto"
                                src={memberShipDetail?.data?.item?.image} />
                        </div>
                        <div className='col-12 col-xl-6 col-md-7'>
                            <div className='ps-xl-3 position-relative top--2 pad-tabss'>
                                <div className='line-height-170 member-details-dec font-18px-imp' dangerouslySetInnerHTML={{ __html: memberShipDetail?.data?.description }}>

                                </div>
                                <div className='row gx-sm-2 gx-xl-4 mt-3 mt-xl-4'>
                                    {memberShipDetail?.data?.get_membership_products?.length > 0 &&
                                        <div className='col-12 col-md-6 mb-3 mb-md-0'>
                                            <div className="card p-3 pb-2">
                                                <div className="card-body p-0">
                                                    <h6 className="card-subtitle mb-2 font-18px-imp font-primary-semibold line-height-150">Exclusive Discounts on a Wide Range of Products</h6>
                                                    <div>
                                                        <a type="button" className="btn btn-outline-primary h-26px px-2 font-12px me-2 mb-2"
                                                            onClick={() => {
                                                                setShowPopup("")
                                                                setScreen("product")
                                                            }}
                                                        >View Details</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {memberShipDetail?.data?.get_membership_services?.length > 0 &&
                                        <div className='col-12 col-md-6 mb-0 mb-md-0'>
                                            <div className="card p-3 pb-2">
                                                <div className="card-body p-0">
                                                    <h6 className="card-subtitle mb-2 font-18px-imp font-primary-semibold line-height-150">Exclusive Discounts on a Wide Range of Services</h6>
                                                    <div>
                                                        <a type="button" className="btn btn-outline-primary h-26px px-2 font-12px me-2 mb-2"
                                                            onClick={() => {
                                                                setShowPopup("")
                                                                setScreen("service")
                                                            }}
                                                        >View Details</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className='mt-xl-2 d-flex flex-wrap align-items-center'>
                                    <a onClick={() => {
                                        setShowPopup("")
                                        setScreen("buymembership")
                                        setOpenLogin("")
                                    }} className="btn btn-primary h-44px me-3 mt-3">Buy Membership</a>
                                    {memberShipDetail?.data?.brochure &&
                                        <a type="button" className="btn btn-outline-primary h-44px me-2 me-3 mt-3"
                                            onClick={() => {
                                                window.open(memberShipDetail?.data?.brochure, "_blank")
                                            }}>Download Brochure</a>
                                    }
                                    {memberShipDetail?.data?.terms_and_condition &&
                                        <a
                                            className="link-primary text-decoration-underline mt-3 cursor-pointer"
                                            onClick={() => {
                                                setShowPopup("")
                                                setScreen("terms")
                                            }}
                                        >Terms and Conditions</a>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Choose Your Subscription End */}

                {/* Discover Exclusive Offers Start */}
                {memberShipDetail?.data?.get_membership_benefits?.length > 0 &&
                    <MemberShipOffer data={memberShipDetail?.data} />
                }
                {/* Discover Exclusive Offers End */}

                {/* Conditions Start */}
                {(memberShipDetail?.data?.is_max_cust_visit_limit == "1" || memberShipDetail?.data?.is_max_purchase_limit == "1") &&
                    <div className='Conditions_row mb-4 mb-md-5 pb-xl-3'>
                        <div className="mb-2 mb-md-3">
                            <h3 className="font-26px">Conditions</h3>
                        </div>
                        <div className="card p-sm-3 pb-sm-2">
                            <div className="card-body pb-0 pb-sm-1">
                                <div className='d-flex flex-wrap'>
                                    {memberShipDetail?.data?.is_max_cust_visit_limit == "1" &&
                                        <div className='flex-0-0-auto me-5 mb-4 pe-xl-4'>
                                            <div className="font-13px opacity-05 mb-2 text-uppercase">Max Customer Visits</div>
                                            <div className="font-16px">{memberShipDetail?.data?.max_cust_visit} <span className='text-grey'>(Within the Membership Period)</span></div>
                                        </div>
                                    }

                                    {memberShipDetail?.data?.is_max_purchase_limit == "1" &&
                                        <div className='flex-0-0-auto me-5 mb-4 pe-xl-4'>
                                            <div className="font-13px opacity-05 mb-2 text-uppercase">MAX PURCHASE LIMIT</div>
                                            <div className="font-16px">{currency} {memberShipDetail?.data?.max_purchase_limit_value} {memberShipDetail?.data?.purchase_limit_type}</div>
                                        </div>
                                    }

                                    {/* <div className='flex-0-0-auto me-5 mb-4 pe-xl-4'>
                                    <div className="font-13px opacity-05 mb-2 text-uppercase">DAYS & HOURS TO REDEEM MEMBERSHIP BENEFITS</div>
                                    <div className="font-16px">20 <span className='text-grey'>Monday - 8:00 AM - 10:00 PM
                                        <a className="link-primary text-decoration-underline ms-2 cursor-pointer">View All</a></span></div>
                                </div> */}
                                </div>
                            </div></div>
                    </div>
                }
                {/* Conditions End */}
            </div>

            {/* Other Membership Programes start */}
            <div className='bg-light-grey py-2 py-md-5 border-top'>
                <div className='container py-xl-2'>
                    <div className="mb-2 mb-sm-3 mb-xl-4 pt-2 pt-md-0">
                        <h3 className="font-26px">Other Membership Programes</h3>
                    </div>
                    <div className='row gx-sm-2 gx-xl-4 d-flex'>
                        {memberShipDetail?.relatedData?.map((item) => {
                            return <div className='col-12 col-md-4 mb-3 mb-md-0' key={item?.id}>
                                <div className="card h-100 p-3 pb-2">
                                    <div className="card-body p-0">
                                        <div className='mb-1 membershipimg'> <img className="w-100 h-auto" src={item?.item?.image} /></div>
                                        <h5 className="font-26px mb-1">{item?.name}</h5>
                                        <h6 className="card-subtitle mb-2 font-18px-imp font-primary-semibold pb-1">Starts from {currency} {item?.price}</h6>
                                        <div>
                                            <a type="button" className="btn btn-outline-primary h-34px me-2 mb-2"
                                                onClick={() => {
                                                    setMemberShipDetail(false)
                                                    setMemberShipId(item?.id)
                                                }}>View Details</a>
                                            {item?.brochure &&
                                                <a className="btn btn-primary h-34px mb-2"
                                                    onClick={() => {
                                                        window.open(item?.brochure, "_blank")
                                                    }}>Download Brochure</a>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>
            {/* Other Membership Programes End */}

            {/* Platinum Membership Popup start */}
            {screen == "product" &&
                <MemberShipProduct showPopup={showPopup} setShowPopup={setShowPopup} id={memberShipDetail?.data?.id} />
            }
            {/* Platinum Membership Popup End */}


            {/* Platinum Membership - Discounted Servcies Popup start */}
            {screen == "service" &&
                <MemberShipService showPopup={showPopup} setShowPopup={setShowPopup} id={memberShipDetail?.data?.id} />
            }
            {/* Platinum Membership - Discounted Servcies Popup End */}


            {/* Buy Membership Popup start */}
            {(screen == "buymembership" && user.access_token) ?
                <BuyMemberShip showPopup={showPopup} setShowPopup={setShowPopup} data={memberShipDetail?.data} />
                : (screen == "buymembership") &&
                // <LoginPopup openClass={openLogin} onClassSet={setOpenLogin} />
                (popupScreen == 'signup' ?
                    <SignUp openLogin={openLogin} setOpenLogin={setOpenLogin} setScreen={setPopupScreen} />
                    : popupScreen == 'forgot-password' ?
                        <ForgotPassword openLogin={openLogin} setOpenLogin={setOpenLogin} setScreen={setPopupScreen} />
                        : popupScreen == 'reset-password' ?
                            <ResetPassword openLogin={openLogin} setOpenLogin={setOpenLogin} setScreen={setPopupScreen} />
                            : <LoginUser openLogin={openLogin} setOpenLogin={setOpenLogin} setScreen={setPopupScreen} />)
            }

            {/* Buy Membership Popup End */}


            {/* Terms and Conditions Popup start */}
            {screen == "terms" &&
                <Terms showPopup={showPopup} setShowPopup={setShowPopup} terms={memberShipDetail?.data} />
            }
            {/* Terms and Conditions Popup End */}

        </div>
    )
}
export default MemberShipDetail;