export const STORE_CUURENT_BRANCH_DATA = "STORE_CUURENT_BRANCH_DATA";
export const STORE_RESTRO_LIST = "STORE_RESTRO_LIST";
export const MARKET_PLACE_SETITINGS = "GET_MARKET_PLACE_SETITINGS";
export const STORE_MARKETPLACE_SETTINGS = "STORE_MARKETPLACE_SETTINGS";
export const STORE_USER_DATA = "STORE_USER_DATA";





