import { useEffect, useState } from "react";
import ReactGoogleAutocomplete,{ usePlacesWidget } from "react-google-autocomplete";
import {LocationService} from "../services/LocationService"; 
 


export  default  (props)=>{

    
    const [location,setLocation] = useState({
        pretty_name:"",
        lat:"",
        lng:"",
        location:"",
        isLoading:true,
        showPicker:false,
    });

    const LocationApi = new LocationService();

    function change(){
        let newLocation= { ...location}
        newLocation.isLoading=false;
        newLocation.showPicker=true;
        setLocation(newLocation) 
    }

    function setAddress(location,place){
        console.log(place);
        LocationApi.setStorageLocation(location);
        setLocation(location) 
    }

    useEffect(()=>{

        
        LocationApi.getCurrentLocation((location)=>{
            let newLocation = { ... location}
            newLocation.isLoading=false;
            newLocation.showPicker=false;
            setLocation(newLocation) 
            

        },err=>{
            console.log(err)
            location.isLoading=false;
            location.showPicker=true;
            setLocation({
                ...location
            });
        })

           
          
    },[])
  
    if(location.isLoading)
        return <div>Loading...</div>
    else
        return <div>
                    {!location.showPicker &&  location.location} 
                    {location.showPicker && <ReactGoogleAutocomplete
                    apiKey={'AIzaSyDKRW6UcDoITftfJ0hCkU3MNOCQozOub1k'}
                    onPlaceSelected={(place) => setAddress({
                        pretty_name:"",
                        location:place.formatted_address,
                        lat:place.geometry.location.lat(),
                        lng:place.geometry.location.lng(),
                        isLoading:false,
                        showPicker:false,

                    },place)}
                    />}
 
                   
                    <button onClick={e=>change()}>Change</button>
                  
                 
            </div>
}
