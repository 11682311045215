import React from 'react';
import ApprovalReqStatus from './ApprovalReqStatus';
import { useState } from 'react';
import Helper from '../../utils/helper';
const ApprovalReqPopup = (props) => {
    const [showPopup, setShowPopup] = useState("app-popup-hide")
    const [screen, setScreen] = useState("")
    const [action, setAction] = useState("")
    let selectedItem = props.selectedItem
    return (
        <>
            <div id='Voucher_Details' className={`app-popup app-popup-footer-no app-popup-header-1 app-popup-offcanvas-end ${props.showPopup}`}>
                <div className='app-popup-container'>
                    <div className="app-popup-content app-popup-485px">
                        <div className="app-popup-header border-bottom py-0 d-flex align-items-center">
                            <div>
                                <h2 className='text-primary font-24px mb-1'>Approval Requests</h2>
                            </div>
                            <button className='app-popup-close'
                                onClick={() => {
                                    props.setShowPopup("app-popup-hide")
                                }}><svg width="12.191" height="12.191" viewBox="0 0 12.191 12.191">
                                    <path id="Icon_material-close" data-name="Icon material-close" d="M19.691,8.728,18.464,7.5,13.6,12.368,8.728,7.5,7.5,8.728,12.368,13.6,7.5,18.464l1.228,1.228L13.6,14.824l4.868,4.868,1.228-1.228L14.824,13.6Z" transform="translate(-7.5 -7.5)" />
                                </svg>
                            </button>
                        </div>
                        <div className="app-popup-body pb-0 font-14px app-popup-body-scroll">

                            <div className='row mb-2'>
                                <div className='col-xl-12 col-lg-12 col-md-12'>
                                    <div className="mb-1 font-16px labelcolor">Subject</div>
                                    <h5 className="font-16px font-primary-regular fillcolor">{selectedItem?.subject}</h5>
                                </div>
                                <div className='col-xl-12 bordercolor'></div>
                            </div>


                            <div className='row mb-2'>
                                <div className='col-xl-12 col-lg-12 col-md-12'>
                                    <div className="mb-1 font-16px labelcolor">Reference No.</div>
                                    <h5 className="font-16px font-primary-regular fillcolor">{selectedItem?.reference_number}</h5>
                                </div>
                                <div className='col-xl-12 bordercolor'></div>
                            </div>

                            <div className='row mb-2'>
                                <div className='col-xl-12 col-lg-12 col-md-12'>
                                    <div className="mb-1 font-16px labelcolor">Request Submitted on</div>
                                    <h5 className="font-16px font-primary-regular fillcolor">{Helper.dateFormat(selectedItem?.updated_at)}</h5>
                                </div>
                                <div className='col-xl-12 bordercolor'></div>
                            </div>

                            <div className='row mb-2'>
                                <div className='col-xl-12 col-lg-12 col-md-12'>
                                    <div className="mb-1 font-16px labelcolor">Touch  Point</div>
                                    <h5 className="font-16px font-primary-regular fillcolor">{selectedItem?.get_touchpoint_detail?.title}</h5>
                                </div>
                                <div className='col-xl-12 bordercolor'></div>
                            </div>

                            {props.requestStatus != "pending" &&
                                <div className='row mb-2'>
                                    <div className='col-xl-12 col-lg-12 col-md-12'>
                                        <div className="mb-1 font-16px labelcolor">Status</div>
                                        <h5 className="font-16px font-primary-regular fillcolor">
                                            {selectedItem?.get_latest_activity?.action?.charAt(0).toUpperCase() + selectedItem?.get_latest_activity?.action?.slice(1)} on {Helper.dateFormat(selectedItem?.get_latest_activity?.created_at)}
                                        </h5>
                                        {selectedItem?.get_latest_activity?.comment &&
                                            <div className="mb-1 font-16px labelcolor">
                                                Comment : <h5 className="font-16px font-primary-regular fillcolor">
                                                    {/* {selectedItem?.get_latest_activity?.comment} */}
                                                    {selectedItem?.get_latest_activity?.comment?.length > 120 ?
                                                        <>
                                                            {(selectedItem?.isMore)
                                                                ? (selectedItem?.get_latest_activity?.comment)
                                                                : <>
                                                                    {selectedItem?.get_latest_activity?.comment.slice(0, 120) + '...'}
                                                                </>
                                                            }
                                                            <a
                                                                href="#!"
                                                                className="text-primary-dark text-decoration-underline ms-2 "
                                                                id={selectedItem?.id}
                                                                onClick={() => {
                                                                    selectedItem.isMore = !selectedItem?.isMore
                                                                }}
                                                            >
                                                                {selectedItem?.isMore ? "Read less" : "Read more"}
                                                            </a>
                                                        </>
                                                        : selectedItem?.get_latest_activity?.comment}
                                                </h5>
                                            </div>}

                                    </div>
                                    <div className='col-xl-12 bordercolor'></div>
                                </div>
                            }

                            <div className='row mb-2'>
                                <div className='col-xl-12 col-lg-12 col-md-12'>
                                    <div className="mb-1 font-16px labelcolor">Description</div>
                                    <h5 className="font-16px font-primary-regular fillcolor">
                                        {selectedItem?.description?.length > 120 ?
                                            <>
                                                {(selectedItem?.isReadMore)
                                                    ? (selectedItem?.description)
                                                    : <>
                                                        {selectedItem?.description.slice(0, 120) + '...'}
                                                    </>
                                                }
                                                <a
                                                    href="#!"
                                                    className="text-primary-dark text-decoration-underline ms-2 "
                                                    id={selectedItem?.id}
                                                    onClick={() => {
                                                        selectedItem.isReadMore = !selectedItem?.isReadMore
                                                    }}
                                                >
                                                    {selectedItem?.isReadMore ? "Read less" : "Read more"}
                                                </a>
                                            </>
                                            : selectedItem?.description}
                                        {/* <a href="#" className="moreless">Read More</a> */}
                                    </h5>
                                </div>
                                <div className='col-xl-12 bordercolor'></div>
                            </div>

                            {selectedItem?.get_approval_request_documents?.length > 0 &&
                                <div className='row mb-2'>
                                    <div className='col-xl-12 col-lg-12 col-md-12'>
                                        <div className="mb-2 font-16px labelcolor">Attachment</div>
                                        {selectedItem?.get_approval_request_documents?.map((attachment) => {
                                            return <h5 className="font-16px font-primary-regular req-row-mobile" key={attachment?.id}>
                                                <div className='ticketinfo'>
                                                    <img src={require("../../assets/img/pdffile.png")} className='pdfticketicon' /> {attachment?.attachment_name}
                                                </div>
                                                <div className="viewpreview">
                                                    <a href="#" className="previewlink"
                                                        onClick={() => {
                                                            window.open(attachment?.document, "_blank")
                                                        }}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="13.37" height="9.805" viewBox="0 0 13.37 9.805">
                                                            <g id="view-filled" transform="translate(-1.126 -5.625)">
                                                                <path id="Path_53" data-name="Path 53" d="M17.065,15.283A1.783,1.783,0,1,1,15.283,13.5,1.783,1.783,0,0,1,17.065,15.283Z" transform="translate(-7.472 -4.755)" fill="#005276"></path>
                                                                <path id="Path_54" data-name="Path 54" d="M14.469,10.376A7.438,7.438,0,0,0,7.811,5.625a7.438,7.438,0,0,0-6.658,4.751.446.446,0,0,0,0,.3A7.438,7.438,0,0,0,7.811,15.43a7.438,7.438,0,0,0,6.658-4.751.446.446,0,0,0,0-.3ZM7.811,13.424a2.9,2.9,0,1,1,2.9-2.9,2.9,2.9,0,0,1-2.9,2.9Z" fill="#005276"></path>
                                                            </g>
                                                        </svg>
                                                        Preview
                                                    </a>
                                                </div>

                                            </h5>
                                        })}
                                    </div>
                                    <div className='col-xl-12 bordercolor'></div>
                                </div>
                            }

                            <div className='row mb-2'>
                                <div className='col-xl-12 col-lg-12 col-md-12'>
                                    <div className="mb-2 font-16px labelcolor">Submitted By</div>
                                    <h5 className="font-16px font-primary-regular">
                                        <div className='ticketinfo'>
                                            <img src={selectedItem?.get_staff_detail?.profile} className='userimg' />
                                            <span className="usertxt"> {selectedItem?.get_staff_detail?.name}</span>
                                        </div>
                                    </h5>
                                </div>
                                <div className='col-xl-12 bordercolor'></div>
                            </div>
                        </div>

                        {(props.requestStatus == 'pending' || props.requestStatus == 'hold') &&
                            <div className='req_footer'>
                                <div className='footer_btn mobile-btns'>
                                    <ul>
                                        <li onClick={() => {
                                            setScreen("requeststatus")
                                            setShowPopup("")
                                            setAction("approved")
                                        }}>
                                            <a href="#" className="approvshowbtn1">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13.882" height="13.882" viewBox="0 0 13.882 13.882">
                                                    <path id="Icon_awesome-check-circle" data-name="Icon awesome-check-circle" d="M14.444,7.5A6.941,6.941,0,1,1,7.5.563,6.941,6.941,0,0,1,14.444,7.5ZM6.7,11.179l5.15-5.15a.448.448,0,0,0,0-.633l-.633-.633a.448.448,0,0,0-.633,0l-4.2,4.2L4.423,7A.448.448,0,0,0,3.79,7l-.633.633a.448.448,0,0,0,0,.633l2.911,2.911A.448.448,0,0,0,6.7,11.179Z" transform="translate(-0.563 -0.563)" fill="#fff" />
                                                </svg>
                                                Approve
                                            </a>
                                        </li>

                                        <li onClick={() => {
                                            setScreen("requeststatus")
                                            setShowPopup("")
                                            setAction("rejected")
                                        }}>
                                            <a href="#" className="rejectbtn2">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="15.137" height="15.137" viewBox="0 0 15.137 15.137">
                                                    <path id="Icon_ionic-ios-close-circle" data-name="Icon ionic-ios-close-circle" d="M10.943,3.375a7.568,7.568,0,1,0,7.568,7.568A7.567,7.567,0,0,0,10.943,3.375Zm1.918,10.308-1.918-1.918L9.026,13.683a.581.581,0,1,1-.822-.822l1.918-1.918L8.2,9.026A.581.581,0,0,1,9.026,8.2l1.918,1.918L12.861,8.2a.581.581,0,0,1,.822.822l-1.918,1.918,1.918,1.918a.584.584,0,0,1,0,.822A.578.578,0,0,1,12.861,13.683Z" transform="translate(-3.375 -3.375)" fill="#fff" />
                                                </svg>
                                                Reject
                                            </a>
                                        </li>

                                        {props.requestStatus != 'hold' &&
                                            <li onClick={() => {
                                                setScreen("requeststatus")
                                                setShowPopup("")
                                                setAction("hold")
                                            }}>
                                                <a href="#" className="holdbtns3">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.137" height="15.137" viewBox="0 0 15.137 15.137">
                                                        <path id="Icon_ionic-ios-close-circle" data-name="Icon ionic-ios-close-circle" d="M10.943,3.375a7.568,7.568,0,1,0,7.568,7.568A7.567,7.567,0,0,0,10.943,3.375Zm1.918,10.308-1.918-1.918L9.026,13.683a.581.581,0,1,1-.822-.822l1.918-1.918L8.2,9.026A.581.581,0,0,1,9.026,8.2l1.918,1.918L12.861,8.2a.581.581,0,0,1,.822.822l-1.918,1.918,1.918,1.918a.584.584,0,0,1,0,.822A.578.578,0,0,1,12.861,13.683Z" transform="translate(-3.375 -3.375)" fill="#fff" />
                                                    </svg>
                                                    Hold
                                                </a>
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>

            {/* Request status popup */}
            {screen == "requeststatus" &&
                <ApprovalReqStatus showPopup={showPopup} setShowPopup={setShowPopup} selectedItem={selectedItem} action={action} />
            }
        </>
    )
}
export default ApprovalReqPopup;