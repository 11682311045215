import { storeMarketPlaceSettings } from "../redux/action";
import { STORE_USER_DATA } from "../redux/constant";
import store from "../redux/store";
import { toast } from 'react-toastify';

export class ApiService {

    static config = {
        // url:'http://192.168.1.6/latest/mealinity_web/',
        // url: 'https://abnodemo.com/mealinity/api/',

        // url: 'https://abnocloud.com/mealinity/',
        // url: 'https://abnocloud.com/mealinity/',
         url: 'https://delytt.com/',
        // url: 'https://mealinity.com/api/',

    }
    static async fetch({
        url,
        options = {} }) {

        let token = await store.getState().user.access_token;
        if (!options.method) {
            options.method = 'POST';
        }
        if (!options.headers) {
            options.headers = {
                'Content-Type': 'application/json'
            };
        }
        if (token) {
            options.headers['Authorization'] = "Bearer " + token;
        }
        if (!options.body && options.method == 'POST') {
            options.body = JSON.stringify({})
        }
        else {
            console.log(typeof options.body, 'options.body')
            if (options.body instanceof FormData) {
                delete options.headers['Content-Type'];
            }
            else
                options.body = JSON.stringify(options.body)
        }

        if (!options.doNotPrependUrl) {
            url = ApiService.config.url + "business/api/" + url
        }

        return fetch(url, options)
            .then(response => {
                // console.log("AIPII", response);
                if (response.status === 401) {
                    // store.dispatch({
                    //     type: STORE_USER_DATA,
                    //     data: { user: {}, access_token: "" }
                    // });
                    // await helpers.clearLoggedInUser();
                    // window.location.href = 'index';
                }
                if (response.ok) return response.json();
                else {
                    throw response;
                }
            })
            .catch(err => {
                console.log(err);
                toast.error(err.status + " " + err.statusText)
            });
    }


    static getHomePageList(body = {}) {
        return ApiService.fetch({
            // url: "get-homepage-list", options: { method: 'GET' } 
            url: "get-homepage-list",
            options: {
                body: body
            }
        });
    }


    static getAppSettings(dispatch, auth_token) {
        return ApiService.fetch({
            // url: "get-homepage-list", options: { method: 'GET' } 
            url: "get-settings",
            options: {
                method: 'GET',

            }
        }).then(data => {
            dispatch(storeMarketPlaceSettings(
                { ...data.data, is_mobile_view: auth_token ? true : false }));
        });
    }

    static login(body = {}) {
        return ApiService.fetch({
            url: "verify-login-otp",
            options: {
                body: body
            }
        });
    }

    static register(body = {}) {
        return ApiService.fetch({
            url: "register",
            options: {
                body: body
            }
        });
    }

    static forgotPassword(body = {}) {
        return ApiService.fetch({
            url: "forgot-password",
            options: {
                body: body
            }
        });
    }

    static resetPassword(body = {}) {
        return ApiService.fetch({
            url: "verify-forgot-otp-password",
            options: {
                body: body
            }
        });
    }

    static myProfile(dispatch, auth_token) {
        return ApiService.fetch({ 
            url: "my-profile",
            options: {
                method: 'GET',
            }
        })
    }

    static favourite(body = {}) {
        return ApiService.fetch({
            url: "add-favourite",
            options: {
                body: body
            }
        });
    }

}