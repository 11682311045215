import React from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateTimePicker from 'react-datepicker';
import { forwardRef } from 'react';
import { addDays } from "date-fns";
import { addMonths } from 'date-fns';
const DateTime = (props) => {
    // console.log("selected item props", props.selectedItem)

    const DateCustomPicker = forwardRef(({ value, onClick }, ref) => (
        <>
            <input onClick={onClick}
                type="text"
                value={value}
                readOnly={true}
                style={{ backgroundColor: "white" }}
                className={`form-control font-16 text-primary-dark pe-0 h-44px input_DateRange input_DateRange-max-inherit mb-2 ${value ? '' : 'date-range-custom-input'}`}
                placeholder="Select date"
            />
        </>
    ));

    return (
        <>
            {props.type == "activedate" &&
                <DatePicker selected={props.activeDate}
                    className="form-control h-50px"
                    placeholderText="Select Date"
                    minDate={new Date()}
                    onChange={(date) => {
                        props.setActivedate(date)
                    }}
                    dateFormat={"dd/MM/yyyy"}
                    maxDate={props.selectedItem.max_activation_unit == "Day" ? addDays(new Date(), props.selectedItem.max_activation - 1)
                        : props.selectedItem.max_activation_unit == "Month" ? addMonths(new Date(), props.selectedItem.max_activation)
                            : ""}
                    customInput={<DateCustomPicker />} />
            }

            {props.type == "notificationdate" &&
                <DateTimePicker
                    className="form-control h-50px"
                    selected={props.notificationTime}
                    onChange={(date) => props.setNotificationTime(date)}
                    showTimeSelect
                    timeFormat="p"
                    timeIntervals={5}
                    minDate={new Date()}
                    // dateFormat="Pp"
                    dateFormat="d MMMM, yyyy h:mm aa"
                    maxDate={props.selectedItem.max_activation_unit == "Day" ? addDays(new Date(), props.selectedItem.max_activation - 1)
                        : props.selectedItem.max_activation_unit == "Month" ? addMonths(new Date(), props.selectedItem.max_activation)
                            : ""}
                    customInput={<DateCustomPicker />}
                />
            }

            {props.type == "membershipActivedate" &&
                <DatePicker selected={new Date(props.activeDate)}
                    className="form-control h-50px"
                    placeholderText="Select Date"
                    dateFormat={"dd/MM/yyyy"}
                    minDate={new Date()}
                    onChange={(date) => {
                        props.setActivedate(date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate())
                    }}
                    maxDate={props.selectedItem.max_activation_type == "Day" ? addDays(new Date(), props.selectedItem.max_activation_value - 1)
                        : props.selectedItem.max_activation_type == "Month" ? addMonths(new Date(), props.selectedItem.max_activation_value)
                            : ""}
                    customInput={<DateCustomPicker />} />
            }

            {props.type == "membershipNotificationdate" &&
                <DateTimePicker
                    className="form-control h-50px"
                    selected={props.notificationTime}
                    onChange={(date) => props.setNotificationTime(date)}
                    showTimeSelect
                    timeFormat="p"
                    timeIntervals={5}
                    minDate={new Date()}
                    // dateFormat="Pp"
                    dateFormat="d MMMM, yyyy h:mm aa"
                    maxDate={props.selectedItem.max_activation_type == "Day" ? addDays(new Date(), props.selectedItem.max_activation_value - 1)
                        : props.selectedItem.max_activation_type == "Month" ? addMonths(new Date(), props.selectedItem.max_activation_value)
                            : ""}
                    customInput={<DateCustomPicker />}
                />
            }

        </>
    )
}
export default DateTime;