import { STORE_CUURENT_BRANCH_DATA, STORE_RESTRO_LIST,STORE_MARKETPLACE_SETTINGS,STORE_USER_DATA } from  "./constant";

export const storeCurrentBranchData = (data) =>{
    return {
        type: STORE_CUURENT_BRANCH_DATA,
        data
    }
}

export const storeMarketPlaceSettings = (data) =>{
    return {
        type: STORE_MARKETPLACE_SETTINGS,
        data
    }
}

// export const storeUser = (data) =>{
//     return {
//         type: STORE_USER_DATA,
//         data
//     }
// }

// export const storeBusinessRestroList = (data) => {
//     return {
//         type: STORE_RESTRO_LIST,
//         data
//     }
// }



//export default storeCurrentBranchData;