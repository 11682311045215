import React from 'react';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Footer } from '../../Components/Footer';
import Webheader from '../../library/WebHeader';
import Tabs from './Tabs';
import { useEffect } from 'react';
import { ApiService } from '../../services/ApiService';
import InvestmentChart from './InvestmentChart';
import Loader from '../../library/loader';
import { toast } from 'react-toastify';
const MyInvestment_Dashboard = () => {
    const [showPopup, setShowPopup] = useState("app-popup-hide")
    const [screen, setScreen] = useState("")
    const history = useHistory()
    const params = useParams()
    // console.log("dashboard params", params)

    const [customerBusiness, setCustomerBusiness] = useState(false)
    const [touchPoints, setTouchPoints] = useState(false)
    const [searchKey, setSearchKey] = useState("today")
    const [businessId, setBussinessID] = useState("")
    const [chartData, setChartData] = useState(false)

    const [showBusiness, setShowBusiness] = useState("")
    let currency = "KES"

    useEffect(() => {
        ApiService.fetch({
            url: "get-customer-business-list",
            options: { method: "GET" }
        }).then((data) => {
            if (data.status == true) {
                setCustomerBusiness(data)
                setBussinessID(data.data[0]?.id)                                  
            }
        });
    }, [])

    const touchPointInvestor = () => {
        ApiService.fetch({
            url: "get-customer-touch-point-investor",
            options: {
                body: {
                    business_id: params?.businessid ? params?.businessid : businessId,
                    search: searchKey
                }
            }
        }).then((data) => {
            if (data.status == true) {
                setTouchPoints(data)
            }
            else {
                toast.error(data.message)
            }
        });
    }

    const customerChart = () => {
        ApiService.fetch({
            url: "get-customer-investment-chart",
            options: {
                body: {
                    business_id: params?.businessid ? params?.businessid : businessId,
                    search: searchKey
                }
            }
        }).then((data) => {
            if (data.status == true) {
                setChartData(data)
                // console.log("customer chart data", data)
            }
        });
    }

    useEffect(() => {
        if (businessId) {
            touchPointInvestor()
            customerChart()
        }
    }, [searchKey, businessId])

    if ((!touchPoints && businessId) || !customerBusiness) {
        return <div className="page-wrapper page-wrapper-footer1 page-wrapper-headerSearch overflow-hidden">
            <Webheader />
            <Tabs active={"investment"} />
            <Loader />
            <div className='clearfix'></div>
            <div className='Footer_border'><Footer /></div>
        </div>
    }

    return (
        <>
            <div className="page-wrapper page-wrapper-footer1 page-wrapper-headerSearch overflow-hidden">
                <Webheader />
                <div className='MobileSearch hide-element'>
                    {/* Search section start */}
                    <div className='topbar-1 topbar-n1 border bg-white d-flex mx-auto flex-grow-1 ms-xl-4'>
                        <div className='flex-0-0-auto ps-2 d-flex align-items-center rounded-0 pe-2 area-filter area-filter-n1 border-end'>
                            <div className=''> <svg className='position-relative top--2 ms-2' width="13.451" height="15.904" viewBox="0 0 12.451 15.904"><use xlinkHref="#map-icon1"></use></svg></div>
                            <div className='flex-grow-1'>

                                <select className="form-select h-52px border-0">
                                    <option defaultValue={"Nairobi"}>Nairobi</option>
                                    <option value="1">Nairobi</option>
                                    <option value="2">Nairobi</option>
                                    <option value="3">Nairobi</option>
                                </select>
                            </div>
                        </div>
                        <div className="input-group input-group-search1">
                            <input
                                type="text"
                                placeholder='Search business by name or type'
                                className="form-control rounded-0 form-control-lg border-0 font-16px h-54px"
                            />
                            <button className="input-group-text bg-transparent border-0"><svg width="17.022" height="17.022" viewBox="0 0 17.022 17.022"><use xlinkHref="#search-icon"></use></svg></button>
                        </div>
                    </div>
                    {/* Search section end */}
                </div>

                <Tabs active={"investment"} />

                {touchPoints?.data?.length > 0 ?
                    <div className="container mb-2 mb-md-5">
                        <div className='myinvesthead pb-0'>
                            <div className="row d-flex justify-content-between align-items-center mb-3">
                                <div className='col-xl-3 col-lg-3 col-md-6 col-sm-6 listarrow'>
                                    <h5 className="font-14px font-primary-semibold mb-2">Select Business</h5>
                                    <select className='businesslist' onChange={(e) => {
                                        setBussinessID(e.target.value)
                                        history?.push("/myinvestment_dashboard/" + e.target.value)
                                    }}
                                        value={params?.businessid}>
                                        {customerBusiness?.data?.map((business) => {
                                            return <option key={business?.id} value={business?.id}>{business?.name}</option>
                                        })}
                                    </select>
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6'>
                                    <div className='daylist tabrights mt-3'>
                                        <ul>
                                            <li>
                                                <select className='dropdays' onChange={(e) => {
                                                    setSearchKey(e.target.value)
                                                    setChartData(false)
                                                }}
                                                    value={searchKey}>
                                                    <option value="">Select your option</option>
                                                    <option value={30}>Last 30 Days</option>
                                                    <option value={15}>Last 15 Days</option>
                                                    <option value={7}>Last 7 Days</option>
                                                    <option value={2}>Last 2 Days</option>
                                                    <option value={"today"}>Today</option>
                                                </select>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div className='dashboardrow m-bottom0'>
                            <div className='row mb-3'>
                                <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
                                    {/* <a className='me-2 cursor-pointer backs'>
                                    <svg width="26" height="26" viewBox="0 0 26 26">
                                        <path id="Path_3316" data-name="Path 3316" d="M26,13A13,13,0,1,1,13,0,13,13,0,0,1,26,13ZM13.576,17.3a.814.814,0,1,1-1.15,1.151L7.55,13.576a.812.812,0,0,1,0-1.15L12.425,7.55a.814.814,0,1,1,1.15,1.15l-3.489,3.487h7.788a.813.813,0,0,1,0,1.625H10.087L13.576,17.3Z" fill="#ef626c" fillRule="evenodd" />
                                    </svg>
                                </a> */}
                                    {/* <div className='headinfo'>
                                    <div className='investadv'>
                                        <img className="me-2 inveimg" src={require("../../assets/img/investimgs.png")} />
                                        <div className='investtxt'>
                                            <h3 className='font-20px'>Ambience Restro </h3>
                                            <p className='mb-2 font-14px'>Westland, Nairobi</p>
                                        </div>
                                    </div>
                                </div> */}
                                    <div className='d-flex align-items-center'>
                                        <label>Touch Points</label>
                                        <b className='pointshow mt-0 ms-2'>{touchPoints?.data?.length}</b>
                                    </div>
                                </div>
                            </div>
                            <div className='overviewstrip'>
                                <h3 className='font-20px'>Overview</h3>
                                <div className='dashsection'>
                                    <div className='row d-flex align-items-center'>
                                        <div className='col-xl-8 col-lg-8'>
                                            <div className='chartview'>
                                                {/* <img className="me-2 inveimg" src={require("../../assets/img/dash-graph.png")} /> */}
                                                <InvestmentChart data={chartData} />
                                            </div>
                                        </div>
                                        <div className='col-xl-4 col-lg-4'>
                                            <div className='chartlist'>
                                                <ul>
                                                    <li>
                                                        <div className='totalbg'>
                                                            <div className='totaltop'>
                                                                <h5 className='font-16px'>Total Sale</h5>
                                                                <h2 className='font-26px'>{currency} {chartData?.totalSale}</h2>
                                                            </div>
                                                            {/* <a className='detailsbtn font-12px'
                                                                onClick={() => {
                                                                    history.push("/myinvestment/" + (params?.businessid ? params?.businessid : businessId), {search : searchKey})
                                                                }}>
                                                                View Details
                                                            </a> */}
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div className='totalbg'>
                                                            <div className='totaltop'>
                                                                <h5 className='font-16px'>Total Expenses</h5>
                                                                <h2 className='font-26px'>{currency} {chartData?.totalExpense}</h2>
                                                            </div>
                                                            {/* <a className='detailsbtn font-12px'
                                                                onClick={() => {
                                                                    history.push("/myinvestment/" + (params?.businessid ? params?.businessid : businessId), {search : searchKey})
                                                                }}>
                                                                View Details
                                                            </a> */}
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div className='totalbg'>
                                                            <div className='totaltop'>
                                                                <h5 className='font-16px'>Total Profit</h5>
                                                                <h2 className='font-26px'>{currency} {chartData?.totalProfit}</h2>
                                                            </div>
                                                            {/* <a className='detailsbtn font-12px'
                                                                onClick={() => {
                                                                    history.push("/myinvestment/" + (params?.businessid ? params?.businessid : businessId), {search : searchKey})
                                                                }}>
                                                                View Details
                                                            </a> */}
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='dashviewpoints mt-4'>
                                <div className='row'>
                                    {touchPoints?.data?.map((item) => {
                                        return <div className='col-xl-6 col-lg-6 mb-4' key={item?.id}>
                                            <div className='pointsviewbox'>
                                                <h2><span>{item?.title}</span>
                                                    <a className="detailsbtn1 font-12px"
                                                        onClick={() => {
                                                            history.push("/myinvestment/" + (params?.businessid ? params?.businessid : businessId) + "/" + item?.id, { search: searchKey })
                                                        }}>
                                                        View Details
                                                    </a>
                                                </h2>
                                                <div className='totalviewinfo'>
                                                    <div className='row'>
                                                        <div className='col-xl-4 col-lg-4 col-md-4 col-sm-5 col-12'>
                                                            <div className="mb-2"><span>Total Sale</span></div>
                                                            <h5 className="font-20px font-primary-bold">{currency} {item?.total_sale}</h5>
                                                        </div>
                                                        <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12'>
                                                            <div className="mb-2"><span>Total Expenses</span></div>
                                                            <h5 className="font-20px font-primary-bold">{currency} {item?.total_expenses}</h5>
                                                        </div>
                                                        <div className='col-xl-4 col-lg-4 col-md-4 col-sm-3 col-12'>
                                                            <div className="mb-2"><span>Total Profit</span></div>
                                                            <h5 className="font-20px font-primary-bold">{currency} {item?.total_profit}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>


                                {/* <div className='row'>
                                <div className='col-xl-6 col-lg-6 mb-4'>
                                    <div className='pointsviewbox'>
                                        <h2><span>Touch Point Name 5</span>
                                            <a href="#" className="detailsbtn1 font-12px">View Details</a>
                                        </h2>
                                        <div className='totalviewinfo'>
                                            <div className='row'>
                                                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-6'>
                                                    <div className="mb-2"><span>Total Sale</span></div>
                                                    <h5 className="font-20px font-primary-bold">Ksh 10,000</h5>
                                                </div>
                                                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-6'>
                                                    <div className="mb-2"><span>Total Expenses</span></div>
                                                    <h5 className="font-20px font-primary-bold">Ksh 1000</h5>
                                                </div>
                                                <div className='col-xl-4 col-lg-4 col-md-4'>
                                                    <div className="mb-2"><span>Total Profit</span></div>
                                                    <h5 className="font-20px font-primary-bold">Ksh 2000</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-6 col-lg-6 mb-4'>
                                    <div className='pointsviewbox'>
                                        <h2><span>Touch Point Name </span>
                                            <a href="#" className="detailsbtn1 font-12px">View Details</a>
                                        </h2>
                                        <div className='totalviewinfo'>
                                            <div className='row'>
                                                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-6'>
                                                    <div className="mb-2"><span>Total Sale</span></div>
                                                    <h5 className="font-20px font-primary-bold">Ksh 10,000</h5>
                                                </div>
                                                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-6'>
                                                    <div className="mb-2"><span>Total Expenses</span></div>
                                                    <h5 className="font-20px font-primary-bold">Ksh 1000</h5>
                                                </div>
                                                <div className='col-xl-4 col-lg-4 col-md-4'>
                                                    <div className="mb-2"><span>Total Profit</span></div>
                                                    <h5 className="font-20px font-primary-bold">Ksh 2000</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            </div>
                        </div>
                    </div>
                    : <div className='infoshow'>
                        You are not connected with any touchpoint.
                    </div>}
                <div className='clearfix'></div>
                <div className='Footer_border'><Footer /></div>
            </div>


        </>
    )
}




export default MyInvestment_Dashboard;