import React from 'react';
import { useState } from 'react';
import { ApiService } from '../services/ApiService';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
const ResetPassword = (props) => {
    // console.log('check location', location)
    const [form, setForm] = useState({
        otp: "",
        password: "",
        confirmPassword: "",
        error: false,
        loading: false,
        showPassword: false,
        showConfirmPassword: false
    })

    const resetPasswordApi = () => {
        setForm({ ...form, error: true })
        if (form?.otp != "" && form?.password?.length < 16 && form?.password?.length > 6 && form?.password == form?.confirmPassword) {
            setForm({ ...form, loading: true })
            ApiService.resetPassword({
                otp: form?.otp,
                password: form?.password,
                token: props?.token
            }).then((data) => {
                if (data.status) {
                    toast.success(data?.message)
                    props?.setScreen('')
                    props?.setOpenLogin('')
                    setForm({ ...form, loading: false })
                    // props.updateParentState({ ...props.state, showLogin: 'Sign in' })
                    // history.push('/login')
                } else {
                    toast.error(data?.message)
                    setForm({ ...form, loading: false })
                }
            }).catch(err => {
                setForm({ ...form, loading: true })
            })
        }
    }

    const handleKeypress = e => {
        if (e.keyCode === 13) {
            resetPasswordApi();
        }
    }
    return (
        <>
            <div id='LoginPopup'
                className={`app-popup`}
            // className={props.className}
            >
                <div className='app-popup-container popup-scroll-view'>
                    <div className="app-popup-content app-popup-430px login-popups">
                        <div className="app-popup-header border-bottom">
                            <h2 className='text-primary font-22px'>Reset Password</h2>

                            <button
                                onClick={() => {
                                    props.setScreen("")
                                }}
                                className='app-popup-close'><svg width="12.191" height="12.191" viewBox="0 0 12.191 12.191" >
                                    <path id="Icon_material-close" data-name="Icon material-close" d="M19.691,8.728,18.464,7.5,13.6,12.368,8.728,7.5,7.5,8.728,12.368,13.6,7.5,18.464l1.228,1.228L13.6,14.824l4.868,4.868,1.228-1.228L14.824,13.6Z" transform="translate(-7.5 -7.5)" />
                                </svg>
                            </button>
                        </div>

                        <div className="app-popup-body pb-0 pt-3 font-14px app-popup-body-scroll">
                            {/* <h6 className='font-18px mb-2 font-primary-semibold'>Continue with Email</h6> */}
                            <p className='mb-2'>Please enter an OTP sent on your email abno@mail.com and Reset Password.</p>
                            <div onKeyDown={handleKeypress}>

                            <div className="form-floating form-floating-56px mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="Enter otp"
                                    value={form?.otp}
                                    onChange={(e) => {
                                        setForm({
                                            ...form, otp: e.target.value
                                        })
                                    }}
                                />
                                <label htmlFor="floatingInput">Enter Otp</label>
                                {(form.otp == '' && form.error) ? <p className='error'>This field required.</p> : ''}
                            </div>

                            <div className="form-floating form-floating-56px mb-3">
                                <input
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="Enter Password"
                                    type={form?.showPassword ? 'text' : 'password'}
                                    value={form?.password}
                                    onChange={(e) => {
                                        setForm({
                                            ...form, password: e.target.value
                                        })
                                    }}
                                />
                                <div className='icon-password' onClick={() => {
                                        setForm({
                                            ...form, showPassword: form?.showPassword == false ? true : false
                                        })
                                    }}>

                                        {form?.showPassword ? 'Hide' : 'Show'}
                                    </div>
                                <label htmlFor="floatingInput">Enter New Password</label>
                                {(form.password == '' && form.error) ? <p className='error'>This field required.</p> :
                                    (form.password.length < 8 && form.error) ? <p className='error'>Password must be minimum length of 8.</p> :
                                        (form.password.length > 20 && form.error) ? <p className='error'>Password must be maximum length of 20.</p> : ''}
                            </div>

                            <div className="form-floating form-floating-56px mb-3">
                                <input
                                    className="form-control"
                                    id="floatingInput"
                                    placeholder="Enter Confirm password"
                                    type={form?.showConfirmPassword ? 'text' : 'password'}
                                    value={form?.confirmPassword}
                                    onChange={(e) => {
                                        setForm({
                                            ...form, confirmPassword: e.target.value
                                        })
                                    }}
                                />
                                <div className='icon-password' onClick={() => {
                                       setForm({
                                        ...form, showConfirmPassword: form?.showConfirmPassword == false ? true : false
                                    })
                                }}>

                                        {form?.showConfirmPassword ? 'Hide' : 'Show'}
                                    </div>
                                <label htmlFor="floatingInput">Enter Confirm Password</label>
                                {(form.confirmPassword == '' && form.error) ? <p className='error'>This field is required</p>
                                    : (form?.error && form?.password != form?.confirmPassword) ? <p className='error'>Password and confirm password must be same</p> : ''}
                            </div>

                            </div>


                        </div>
                        <div className="app-popup-footer pb-4">
                            <div className="">
                                {!form?.loading ?
                                    <div>
                                        <button type="button" id="myBtn" className="btn btn-primary w-100 h-44px font-18px text-center justify-content-center mb-2"
                                            onClick={(e) => {
                                                resetPasswordApi()
                                            }}
                                        >Rest Password &amp; Sign in</button></div>
                                    :
                                    <div className="d-flex justify-content-center">
                                        <svg className="loader d-flex justify-content-center" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
                                        </svg></div>
                                }
                                {/* <div className='font-12px text-grey-dark'>An OTP will be sent to your mobile number for verification</div> */}
                            </div>
                            <div className='end-section text-center font-16px line-height-140 pt-2'>
                                Back to <a href='#' className='link-primary font-primary-semibold'
                                    onClick={() => {
                                        props?.setScreen('')
                                        props?.setOpenLogin('')
                                    }}>Sign in</a>
                            </div>
                        </div>


                    </div>
                </div>

            </div>
        </>
    )
}
export default ResetPassword;