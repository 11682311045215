import React, { useEffect, Suspense } from 'react';
import "./assets/scss/style.scss";
import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import { Location } from './Pages/home/Location';
import Contacts from './Pages/Contacts/Contacts';
import { ApiService } from './services/ApiService';
import { useDispatch, useSelector } from 'react-redux';
import MemberShipDetail from './Pages/Membership/MemberShipDetail';
import Services from './Pages/Products/Services';
import Products from './Pages/Products/Products';
import MyInvestment from './Pages/LoginMembership/MyInvestment';
import MyDelivery from './Pages/LoginMembership/MyDelivery';
import MyInvestment_Dashboard from './Pages/LoginMembership/MyInvestment_Dashboard';
import MobileViewDelivery from './Pages/MobileView/MobileViewDelivery';
import ApprovalRequest from './Pages/LoginMembership/ApprovalRequest';
import { RouteGroup } from './Components/RouteGroup';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { STORE_MARKETPLACE_SETTINGS, STORE_USER_DATA } from './redux/constant';
import Loader from './library/loader';

const RestroDetail = React.lazy(() => import('./Pages/restro-detail/Home'));
const Deals = React.lazy(() => import('./Pages/Deals/Deals'));
const Photos = React.lazy(() => import('./Pages/Photos/Photos'));
const orderOnline = React.lazy(() => import('./Pages/MyOrders/order-online'));
const Home = React.lazy(() => import('./Pages/home/Home'));
const Checkout = React.lazy(() => import('./Pages/Checkout/Checkout'));
const my_order = React.lazy(() => import('./Pages/MyOrders/my_order'));
const CategoryDetail = React.lazy(() => import('./Pages/DetailsPage/CategoryDetail'));
const LatestDeals = React.lazy(() => import('./Pages/Deals/LatestDeals'));
const MemberShip = React.lazy(() => import('./Pages/Membership/MemberShip'));
const Voucher = React.lazy(() => import('./Pages/Membership/Voucher'));
const MyMemberShip = React.lazy(() => import('./Pages/LoginMembership/MyMemberShip'))
const MyVoucher = React.lazy(() => import('./Pages/LoginMembership/MyVoucher'))
const MyMemberShipDetail = React.lazy(() => import('./Pages/LoginMembership/MemberShipDetail'))
const VoucherRedemption = React.lazy(() => import('./Pages/LoginMembership/VoucherRedemption'))
const MembershipRedemption = React.lazy(() => import('./Pages/LoginMembership/MembershipRedemption'))
const MyProfile = React.lazy(() => import('./Pages/LoginMembership/MyProfile'))
const MyInvoices = React.lazy(() => import('./Pages/LoginMembership/MyInvoices'))
const Vouchers_list = React.lazy(() => import('./Pages/Vouchers/vouchers'))
// const MyOrders = React.lazy(() => import('./Orders/MyOrders'));


function App() {
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search)
  const auth_token = queryParams.get("auth_token")
  // const social_auth_token = queryParams.get("social_auth_token")
  const marketPlaceSetting = useSelector(state => state?.settings)
  const user = useSelector(state => state.user)

  const authLogin = () => {
    dispatch({
      type: STORE_USER_DATA,
      data: { user: {}, access_token: auth_token }
    });

    ApiService.myProfile({}).then((data) => {
      if (data?.status == true) {
        dispatch(
          {
            type: STORE_USER_DATA,
            data: { user: data.data, access_token: auth_token }
          }
        )
      }
      else {
        toast.error(data.message)
      }
    });

  }

  const user_token = useSelector(state => state.user.access_token)
  useEffect(() => {
    ApiService.getAppSettings(dispatch, auth_token);
  }, [])

  function checkAuth() {
    return !user_token ? false : true;
  }

  useEffect(() => {
    if (auth_token) {
      authLogin()
    }
  }, [])
  // console.log('marketPlaceSetting', marketPlaceSetting)


  return (

    <div className={(marketPlaceSetting?.is_mobile_view || auth_token) ? 'is-mobile' : ''}>
      <Router basename={'/'}  >
        <ToastContainer />
        <Switch>
          <Suspense fallback={<Loader />} >
            <Route exact component={Home} path="/" />
            <Route exact component={RestroDetail} path="/o/:branch/:tab" />
            <Route exact component={RestroDetail} path="/o/:branch" />
            {/* <Route  component={Deals} path="/deals" /> */}

            <Route component={Photos} path="/photos" />
            <Route component={orderOnline} path="/order-online" />
            <Route component={Location} path="/location" />

            <Route component={Checkout} path="/checkout" />

            <Route component={CategoryDetail} path="/category/:category_type/:type?" />
            <Route component={LatestDeals} path="/deals/:category_type?" />



            <Route component={MemberShip} path="/membership" />
            <Route component={Voucher} path="/voucher" />
            <Route component={MemberShipDetail} path="/membershipdetail" />

            {/* <Route component={MyMemberShip} path="/mymembership" /> */}
            {/* <Route component={MyVoucher} path="/myvoucher" /> */}
            <Route component={MyMemberShipDetail} path="/mymembershipdetail" />



            <Route component={VoucherRedemption} path="/voucherredemption" />
            <Route component={MembershipRedemption} path="/membershipredemption" />

            <Route component={Vouchers_list} path="/vouchers" />
            <Route component={Services} path="/services" />
            <Route component={Products} path="/products" />

            <Route component={MyDelivery} path="/mydelivery/detail/:slug" />

            <RouteGroup middlewares={[checkAuth]} >

              <Route component={MyInvestment} path="/myinvestment/:businessid?/:touchpointid?" />
              <Route component={MyProfile} path="/myprofile" />
              <Route component={MyInvoices} path="/myinvoices" />
              <Route component={MyInvestment_Dashboard} path="/myinvestment_dashboard/:businessid?" />
              <Route component={MyMemberShip} path="/mymembership" />
              <Route component={MyVoucher} path="/myvoucher" />
              <Route component={my_order} path="/my-order/:type?" />
              <Route exact component={MyDelivery} path="/mydelivery" />
              <Route component={ApprovalRequest} path="/approvalrequest/:status?" />
            </RouteGroup>

            <Route component={MobileViewDelivery} path="/mobiledelivery" />

            {/* <Route component={ApprovalRequest} path="/approvalrequest/:status" /> */}
          </Suspense>
          {/* <Redirect to="/" /> */}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
