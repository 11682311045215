import React from 'react';
import { useState } from 'react';
import { ApiService } from '../services/ApiService';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { STORE_USER_DATA } from '../redux/constant';
import { toast } from 'react-toastify';
import { SocialLogin } from '../services/SocialLogin';
const SignUp = (props) => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.user);
    const history = useHistory()
    const [form, setForm] = useState({
        name: "",
        phone_no: "",
        email: "",
        password: "",
        error: false,
        loading: false,
        showPassword: false
    })
    let emailPattern = "[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
    const phonePattern = /^\d(?:[- ]*\d){6,14}$/

    const RegisterUser = () => {
        setForm({ ...form, error: true })
        if (form?.name != '' && form?.phone_no?.match(phonePattern) &&
            form?.email?.match(emailPattern) && form.password.length > 7 && form.password.length < 21) {
            setForm({ ...form, loading: true })
            ApiService.register({
                email: form?.email,
                name: form?.name,
                phone_number: form?.phone_no,
                country_code: '254',
                password: form?.password
            }).then((data) => {
                if (data.status) {
                    setForm({ ...form, loading: false })
                    toast.success(data.message)
                    props.setScreen('')
                    dispatch(
                        {
                            type: STORE_USER_DATA,
                            data: { user: data.user, access_token: data.access_token }
                        }
                    )
                    // history.go(-1);
                } else {
                    setForm({ ...form, loading: false })
                    toast.error(data.message)
                }
            })
                .catch(err => {
                    setForm({ ...form, loading: false })
                })
        }
    }

    const socialLogin = (type) => {
        SocialLogin.open(type, (auth_token) => {
            dispatch({
                type: STORE_USER_DATA,
                data: { user: {}, access_token: auth_token }
            });
            ApiService.myProfile({})
                .then((data) => {
                    if (data.status) {
                        toast.success('Login sucessful')
                        dispatch(
                            {
                                type: STORE_USER_DATA,
                                data: { user: data.data, access_token: auth_token }
                            }
                        )
                    }
                    else {
                        toast.error(data?.message)
                    }
                })
                .catch(err => {

                })

        })
    }

    const handleKeypress = e => {
        if (e.keyCode === 13) {
            RegisterUser();
        }
    }

    if (user?.access_token) {
        // props.setOpenLogin("app-popup-hide")
        props.setScreen("")
    }
    return (
        <>
            <div id='LoginPopup'
                className={`app-popup`}
            // className={props.className}
            >

                <div className='app-popup-container popup-scroll-view'>
                    <div className="app-popup-content app-popup-430px login-popups">
                        <div className="app-popup-header border-bottom">
                            <h2 className='text-primary font-22px'>Sign up</h2>

                            <button
                                onClick={() => {
                                    props.setScreen("")
                                    // setphoneNumber("")
                                    // setPhoneError(false)
                                }}
                                className='app-popup-close'><svg width="12.191" height="12.191" viewBox="0 0 12.191 12.191" >
                                    <path id="Icon_material-close" data-name="Icon material-close" d="M19.691,8.728,18.464,7.5,13.6,12.368,8.728,7.5,7.5,8.728,12.368,13.6,7.5,18.464l1.228,1.228L13.6,14.824l4.868,4.868,1.228-1.228L14.824,13.6Z" transform="translate(-7.5 -7.5)" />
                                </svg>
                            </button>
                        </div>

                        <div className="app-popup-footer pb-0 pt-3">
                            <h6 className='font-18px mb-2 font-primary-semibold'>Continue with Soical</h6>
                            <div className='row'>
                                <div className='col-6 cols-m-6 mb-2'>
                                    <button type="button" className="btn continue-social-btns w-100 h-44px font-16px mb-2"
                                        onClick={() => {
                                            socialLogin('google')
                                            // window.open(`https://abnocloud.com/mealinity/business/auth/social/google?redirect_app=${ApiService.config.url}?auth_token=`, '_self')
                                        }}>Google
                                        <span className='icon-btns'>
                                            <img src={require("../assets/img/google-icon.png")} />
                                        </span>
                                    </button>
                                </div>
                                <div className='col-6 cols-m-6 mb-2'>
                                    <button type="button" className="btn continue-social-btns w-100 h-44px font-16px mb-2"
                                        onClick={() => {
                                            socialLogin('facebook')
                                            // window.open(`https://abnocloud.com/mealinity/business/auth/social/facebook?redirect_app=${ApiService.config.url}?auth_token=`, '_self')
                                        }}>Facebook
                                        <span className='icon-btns'>
                                            <img src={require("../assets/img/fb-icon.png")} />
                                        </span>
                                    </button>
                                </div>
                                <div className='col-6 cols-m-6 mb-2'>
                                    <button type="button" className="btn continue-social-btns w-100 h-44px font-16px mb-2"
                                        onClick={() => {
                                            socialLogin('twitter')
                                            // window.open(`https://abnocloud.com/mealinity/business/auth/social/twitter?redirect_app=${ApiService.config.url}?auth_token=`, '_self')
                                        }}>Twitter
                                        <span className='icon-btns'>
                                            <img src={require("../assets/img/twitter-icon.png")} />
                                        </span>
                                    </button>
                                </div>
                                <div className='col-6 cols-m-6 mb-2'>
                                    <button type="button" className="btn continue-social-btns w-100 h-44px font-16px mb-2"
                                        onClick={() => {
                                            socialLogin('linkedin-openid')
                                            // window.open(`https://abnocloud.com/mealinity/business/auth/social/linkedin-openid?redirect_app=${ApiService.config.url}?auth_token=`, '_self')
                                        }}>LinkedIn
                                        <span className='icon-btns'>
                                            <img src={require("../assets/img/linkdin-icon.png")} />
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className='divider-row'>
                                <span className='font-14px'>Or</span>
                            </div>
                        </div>

                        <div className="app-popup-body pb-0 pt-3 font-14px app-popup-body-scroll">
                            <h6 className='font-18px mb-2 font-primary-semibold'>Continue with Email</h6>
                            <div onKeyDown={handleKeypress}>
                                {/* <div className="form-col w-100 mb-3 position-relative">
                                    <span className='form-floating-select-label font-12px text-grey-dark'>Country Code</span>
                                    <select
                                        defaultValue={'Kenya'}
                                        id="cmbIdioma"
                                        className="form-select select2-app-flag h-56px form-control"
                                        aria-label="Default select example"
                                    >
                                        <option value="Kenya" >
                                            Kenya (+254)
                                        </option>
                                    </select>
                                </div> */}
                                <div className="form-floating form-floating-56px mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInput"
                                        placeholder="Enter Name"
                                        value={form?.name}
                                        onChange={(e) => {
                                            setForm({
                                                ...form, name: e.target.value
                                            })
                                        }}
                                    />
                                    <label htmlFor="floatingInput">Enter Name</label>
                                    {(form?.name == '' && form?.error) ? <p className='text-red'>This field is required</p> : ''}
                                </div>
                                <div className="form-floating form-floating-56px mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="floatingInput"
                                        placeholder="Enter Email"
                                        value={form?.email}
                                        onChange={(e) => {
                                            setForm({
                                                ...form, email: e.target.value
                                            })
                                        }}
                                    />
                                    <label htmlFor="floatingInput">Enter Email</label>
                                    {(form.email == '' && form.error) ? <p className='error'>This field is required</p>
                                        : (!form.email.match(emailPattern) && form.error) ? <p className='error'>Enter a valid email address</p> : ''}
                                </div>
                                <div className="form-floating form-floating-56px mb-3">
                                    <input
                                        type="number"
                                        className="form-control"
                                        id="floatingInput"
                                        placeholder="Enter Mobile Number"
                                        value={form?.phone_no}
                                        onChange={(e) => {
                                            setForm({
                                                ...form, phone_no: e.target.value
                                            })
                                        }}
                                    />
                                    <label htmlFor="floatingInput">Enter Mobile Number</label>
                                    {(!form?.phone_no?.match(phonePattern) && form.error) ? <p className='error'>Phone no. must be length of 7 to 15</p> : ''}
                                </div>
                                <div className="form-floating form-floating-56px mb-3">
                                    <input
                                        type={form?.showPassword ? 'text' : 'password'}
                                        className="form-control"
                                        id="floatingInput"
                                        placeholder="Enter Password"
                                        value={form?.password}
                                        onChange={(e) => {
                                            setForm({
                                                ...form, password: e.target.value
                                            })
                                        }}
                                    />
                                    <div className='icon-password' onClick={() => {
                                        setForm({
                                            ...form, showPassword: form?.showPassword == false ? true : false
                                        })
                                    }}>
                                        {form?.showPassword ? 'Hide' : 'Show'}
                                    </div>
                                    <label htmlFor="floatingInput">Enter Password</label>
                                    {(form.password == '' && form.error) ? <p className='error'>This field required</p> :
                                        (form.password.length < 8 && form.error) ? <p className='error'>Password must be minimum length of 8</p> :
                                            (form.password.length > 20 && form.error) ? <p className='error'>Password must be maximum length of 20</p> : ''}
                                </div>
                            </div>
                            {/* {phoneError ? <div className="text-red">Please enter a valid phone number</div> : ""} */}

                            <div className='text-grey-dark font-16px line-height-150'>
                                By clicking continue, you indicate that you have read and accepted
                                the
                                <a className='link-primary' onClick={() => {
                                    window.open(ApiService.config.url + "business/term-conditions", '_blank', 'noreferrer')
                                }}>Terms & Conditions</a> and
                                <a className='link-primary' onClick={() => {
                                    window.open(ApiService.config.url + "business/privacy-policy", '_blank', 'noreferrer')
                                }}> Privacy Notice</a>
                            </div>

                        </div>
                        <div className="app-popup-footer pb-4">
                            <div className="">
                                {!form?.loading ?
                                    <div>
                                        <button type="button" id="myBtn" className="btn btn-primary w-100 h-44px font-18px text-center justify-content-center mb-2"
                                            onClick={(e) => {
                                                RegisterUser()
                                            }}
                                        >Sign up</button></div>
                                    :
                                    <div className="d-flex justify-content-center">
                                        <svg className="loader d-flex justify-content-center" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
                                        </svg></div>
                                }
                                {/* <div className='font-12px text-grey-dark'>An OTP will be sent to your mobile number for verification</div> */}
                            </div>
                            <div className='end-section text-center font-16px line-height-140 pt-2'>
                                Already have an account? <a href='#' className='link-primary font-primary-semibold'
                                    onClick={() => {
                                        props?.setScreen('')
                                        props?.setOpenLogin('')
                                    }}>Sign in</a>
                            </div>
                        </div>


                    </div>
                </div>

            </div>
        </>
    )
}
export default SignUp;